import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { Card, Collapse, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const GeneralTab = ({
  label,
  setLabel,
  color,
  setColor,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  required,
  setRequired,
  customize,
  setCustomize,
  orderNo,
  setOrderNo,
  defaultValue,
  setDefaultValue,
  help,
  setHelp,
  formate,
  setFormate,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    setFormate(selectedFormat);
    if (selectedDate) {
      setDefaultValue(formatDate(selectedDate, selectedFormat));
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDefaultValue(formatDate(date, formate));
  };

  const formatDate = (date, formate) => {
    let formatPattern = "MM/dd/yyyy";
    switch (formate) {
      case "MM/dd/yyyy":
        formatPattern = "MM/dd/yyyy";
        break;
      case "MM.dd.yyyy":
        formatPattern = "MM.dd.yyyy";
        break;
      case "dd-MM-yyyy":
        formatPattern = "dd-MM-yyyy";
        break;
      case "yyyy-MM-dd":
        formatPattern = "yyyy-MM-dd";
        break;
      case "dd/MM/yy":
        formatPattern = "dd/MM/yy";
        break;
      case "yy/MM/dd":
        formatPattern = "yy/MM/dd";
        break;
      case "ddMMyy":
        formatPattern = "ddMMyy";
        break;
      case "yyyyMMdd":
        formatPattern = "yyyyMMdd";
        break;

      default:
        formatPattern = "MM/dd/yyyy";
    }

    return format(date, formatPattern);
  };

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };

  return (
    <>
      <div className="p-2 pb-0">
        <Form.Group>
          <div className="hstack justify-content-between">
            <Form.Label>
              <b> Label</b>
            </Form.Label>
            {label && (
              <Form.Check
                type="switch"
                label="Customize Label"
                onChange={setCustomization}
                checked={customize}
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Date label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Group>
      </div>
      <Collapse in={customize}>
        <div className="p-2">
          <Form.Label className="mb-1">
            <b>Customization</b>
          </Form.Label>
          <Card className="toogle-card shadow-sm border">
            <div className="hstack border-bottom">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
              >
                <div className="date-icon">
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bold"> Text Bold</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsBold(!isBold)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-bold ${isBold ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsItalic(!isItalic)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-italic ${isItalic ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
            </div>
            <div className="p-2">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  <b>Output</b>
                </Form.Label>
                <Card className="border h-1">
                  <Card.Body>
                    <Card.Text
                      className="text-center"
                      style={{
                        color,
                        fontWeight: isBold ? "bold" : "normal",
                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {label || "Output Result"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Check
                className="requird_form_check mb-2"
                type="switch"
                aria-label="option 1"
                checked={required}
                onClick={(e) => setRequired(e.target.checked)}
                label="Required"
              />
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Order no</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                  placeholder="Enter Order no"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Format</b>
                </Form.Label>
                <Form.Select value={formate} onChange={handleFormatChange}>
                  <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                  <option value="MM.dd.yyyy">MM.DD.YYYY</option>
                  <option value="dd-MM-yyyy">DD-MM-YYYY</option>
                  <option value="yyyy-MM-dd">YYYY-MM-DD</option>
                  <option value="dd/MM/yy">DD/MM/YY</option>
                  <option value="yy/MM/dd">YY/MM/DD</option>
                  <option value="ddMMyy">DDMMYY</option>
                  <option value="yyyyMMdd">YYMMDD</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Default Value </b>
                </Form.Label>
                <div className="w-100">
                  <DatePicker
                    selected={selectedDate}
                    disabled
                    onChange={handleDateChange}
                    dateFormat={formate}
                    className="Date-picker form-control w-100"
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Help </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="help Description"
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
        </div>
      </Collapse>
    </>
  );
};

export default GeneralTab;
