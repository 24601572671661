import React, { useState } from "react";
import { Card, Collapse, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const GeneralTab = ({
  label,
  setLabel,
  color,
  setColor,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  required,
  setRequired,
  customize,
  setCustomize,
  orderNo,
  setOrderNo,
  help,
  setHelp,
  rating,
  setRating
}) => {
  const [open, setOpen] = useState(false);

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };

  const addstar = () => {
    if (rating.length < 6) {
      const starCount = rating.length;
      const starColor = getStarColor(starCount);
      setRating([...rating, { id: uuidv4(), value: `Stars: ${starCount}, Color: ${starColor}` }]);
    }
  };

  const getStarColor = (count) => {
    if (count <= 2) return "red";
    if (count <= 4) return "yellow";
    if (count <= 6) return "green";
    return "";
  };

  const removeStar = () => {
    if (rating.length > 0) {
      setRating(rating.slice(0, -1));
    }
  }

  console.log("rating", rating);


  return (
    <>
      <div className="p-2 pb-0">
        <Form.Group>
          <div className="hstack justify-content-between">
            <Form.Label>
              <b> Label</b>
            </Form.Label>
            {label && (
              <Form.Check
                type="switch"
                label="Customize Label"
                onChange={setCustomization}
                checked={customize}
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Rating label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Group>
      </div>
      <Collapse in={customize}>
        <div className="p-2">
          <Form.Label className="mb-1">
            <b>Customization</b>
          </Form.Label>
          <Card className="toogle-card shadow-sm border">
            <div className="hstack border-bottom">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
              >
                <div className="date-icon">
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bold"> Text Bold</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsBold(!isBold)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-bold ${isBold ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsItalic(!isItalic)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-italic ${isItalic ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
            </div>
            <div className="p-2">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  <b>Output</b>
                </Form.Label>
                <Card className="border h-1">
                  <Card.Body>
                    <Card.Text
                      className="text-center"
                      style={{
                        color,
                        fontWeight: isBold ? "bold" : "normal",
                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {label || "Output Result"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Check
                className="requird_form_check mb-2"
                type="switch"
                aria-label="option 1"
                checked={required}
                onClick={(e) => setRequired(e.target.value)}
                label="Required"
              />
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Order no</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                  placeholder="Enter Order no"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Help </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="help Description"
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
        </div>
      </Collapse>
      <div className="p-2">
        <div className="button_type d-flex mt-2 gap-3">
          <button onClick={addstar} style={{ cursor: "pointer" }}>Add Star</button>
          <button onClick={removeStar} className="bg-danger">Remove Star</button>

        </div>
        <div className="d-flex gap-3 pt-3">
          {rating?.length > 0 && rating?.map((star) => (
            <div className="mb-2" key={star.id}>
              <i class="fa-solid fa-star"
                style={{
                  color: getStarColor(rating.length),
                  cursor: "pointer"
                }}
              ></i>
            </div>
          ))}
        </div>

      </div>
    </>
  );
};

export default GeneralTab;



