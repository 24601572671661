import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Placeholder,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import SplitPane from "react-split-pane";
import { workflowServices } from "../../APIServices/workflowServices";
import { quotationServices } from "../../APIServices/quotationServices";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Component/RightClick/RightClick";

const QutationsList = ({ studyId }) => {
  const [workflowData, setWorkflowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dataloader, setDataloader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [workflowID, setWorkflowID] = useState("");
  const [quatationWorkflowID, setQuatationWorkflowID] = useState("");
  const [qutationData, setQutationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [visibleItems, setVisibleItems] = useState(16);
  const [phaseID, setPhaseID] = useState("");
  const [quatation, setQuatation] = useState("");
  const [quatationId, setQuatationId] = useState("");
  const [effortsInEfficacyEndPointName, setEffortsInEfficacyEndPointName] =
    useState("");
  const [effortsInEfficacyEndPointValue, setEffortsInEfficacyEndPointValue] =
    useState(null);
  const [efficacyCostName, setEfficacyCostName] = useState("");
  const [efficacyCostValue, setEfficacyCostValue] = useState(null);
  const [effortsInsafetyEndPointName, setEffortsInsafetyEndPointName] =
    useState("");
  const [effortsInsafetyEndPointValue, setEffortsInsafetyEndPointValue] =
    useState(null);
  const [numberOfEfficacyName, setNumberOfEfficacyName] = useState("");
  const [numberOfEfficacyValue, setNumberOfEfficacyValue] = useState("");
  const [numberOfSafetyName, setNumberOfSafetyName] = useState("");
  const [numberOfSafetyValue, setNumberOfSafetyValue] = useState("");
  const [reviewEffortInEfficacyName, setReviewEffortInEfficacyName] =
    useState("");
  const [reviewEffortInEfficacyValue, setReviewEffortInEfficacyValue] =
    useState(null);
  const [reviewEfficacyCostName, setReviewEfficacyCostName] = useState("");
  const [reviewEfficacyCostValue, setReviewEfficacyCostValue] = useState(null);
  const [reviewEffortInSafetyName, setReviewEffortInSafetyName] = useState("");
  const [reviewEffortInSafetyValue, setReviewEffortInSafetyValue] =
    useState(null);
  const [reviewSafetyCostName, setReviewSafetyCostName] = useState("");
  const [reviewSafetyCostValue, setReviewSafetyCostValue] = useState(null);
  const [safetyCostName, setSafetyCostName] = useState("");
  const [safetyCostValue, setSafetyCostValue] = useState(null);
  const [numberOfEfficacy, setNumberOfEfficacy] = useState("");
  const [numberOfSafety, setNumberOfSafety] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [showCost, setShowCost] = useState(false);
  const [statusCode, setStatusCode] = useState(false);

  const getWorkflowByStudy = async () => {
    let data = await workflowServices.getWorkflowByStudy(studyId);
    setWorkflowData(data?.data);
  };

  const getQuatationByStudy = async () => {
    setListLoader(true);
    let data = await workflowServices.getQuatationByStudy(studyId);
    setQutationData(data?.data);

    setListLoader(false);
  };

  const updatequotation = async () => {
    let _id = quatationId;
    let userData = {};
    userData.workflowID = workflowID;
    userData.numberOfEfficacy = numberOfEfficacy;
    userData.numberOfSafety = numberOfSafety;
    let data = await quotationServices.updateQuatation(_id, userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      getQuatationByStudy();
      setStatusCode(true);
      clearFormData();
    }
  };

  const handleUpdateClick = (item) => {
    setQuatationId(item?._id?.$oid);
    setWorkflowID(item?.workflowID);
    setNumberOfEfficacy(item?.numberOfEfficacy);
    setNumberOfSafety(item?.numberOfSafety);
  };

  const getDataForQuatationCost = async (_id) => {
    setDataloader(true);
    setQuatation(_id);
    setTotalCost("");
    let data = await quotationServices.getDataForQuatationCost(_id);
    setEfficacyCostName(data?.data?.efficacyCost?.name);
    setEfficacyCostValue(data?.data?.efficacyCost?.value);
    setEffortsInEfficacyEndPointName(
      data?.data?.effortsInEfficacyEndPoint?.name
    );
    setEffortsInEfficacyEndPointValue(
      data?.data?.effortsInEfficacyEndPoint?.value
    );
    setEffortsInsafetyEndPointName(data?.data?.effortsInsafetyEndPoint?.name);
    setEffortsInsafetyEndPointValue(data?.data?.effortsInsafetyEndPoint?.value);
    setNumberOfEfficacyName(data?.data?.numberOfEfficacy?.name);
    setNumberOfEfficacyValue(data?.data?.numberOfEfficacy?.value);
    setNumberOfSafetyName(data?.data?.numberOfSafety?.name);
    setNumberOfSafetyValue(data?.data?.numberOfSafety?.value);
    setReviewEfficacyCostName(data?.data?.reviewEfficacyCost?.name);
    setReviewEfficacyCostValue(data?.data?.reviewEfficacyCost?.value);
    setReviewEffortInEfficacyName(data?.data?.reviewEffortInEfficacy?.name);
    setReviewEffortInEfficacyValue(data?.data?.reviewEffortInEfficacy?.value);
    setReviewEffortInSafetyName(data?.data?.reviewEffortInSafety?.name);
    setReviewEffortInSafetyValue(data?.data?.reviewEffortInSafety?.value);
    setReviewSafetyCostName(data?.data?.reviewSafetyCost?.name);
    setReviewSafetyCostValue(data?.data?.reviewSafetyCost?.value);
    setSafetyCostName(data?.data?.safetyCost?.name);
    setSafetyCostValue(data?.data?.safetyCost?.value);

    setDataloader(false);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let id = studyId;
    let userData = {};
    userData.workflowID = workflowID;
    userData.numberOfEfficacy = numberOfEfficacy;
    userData.numberOfSafety = numberOfSafety;
    let data = await quotationServices.addQuatation(userData, id);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      setLoader(false);
      getQuatationByStudy();
      clearFormData();
    }
  };

  const getQuatationCost = async (e) => {
    setLoading(true);
    let id = studyId;
    let userData = {};
    userData.phaseID = phaseID;
    userData.workflowID = quatationWorkflowID;
    userData.effortsInEfficacyEndPoint = effortsInEfficacyEndPointValue;
    userData.efficacyCost = efficacyCostValue;
    userData.reviewEffortInEfficacy = reviewEffortInEfficacyValue;
    userData.reviewEfficacyCost = reviewEfficacyCostValue;
    userData.effortsInsafetyEndPoint = effortsInsafetyEndPointValue;
    userData.safetyCost = safetyCostValue;
    userData.reviewEffortInSafety = reviewEffortInSafetyValue;
    userData.reviewSafetyCost = reviewSafetyCostValue;
    let data = await quotationServices.getQuatationCost(userData, id);
    setTotalCost(data?.data?.total_cost);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      setLoading(false);
    }
  };

  const deleteQuatation = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Quotations!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await quotationServices.deleteQuatation(_id);
        if (data?.status_code === 200) {
          getQuatationByStudy();
        }
      }
    });
  };

  const loadMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const clearFormData = () => {
    setWorkflowID("");
    setNumberOfEfficacy("");
    setNumberOfSafety("");
    setLoader("");
  };

  useEffect(() => {
    getQuatationByStudy();
    getWorkflowByStudy();
  }, [studyId]);

  const isDisabled = !workflowID || !numberOfEfficacy || !numberOfSafety;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please select all mandatory fields
    </Tooltip>
  );

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={"25%"}
        style={{ height: "calc(100vh - 320px)" }}
      >
        <div className="p-2">
          <Form.Group className="mb-2">
            <Form.Label>
              <b>Workflow:</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              className="rounded-1"
              value={workflowID}
              onChange={(e) => setWorkflowID(e.target.value)}
            >
              <option value="">Select</option>
              {workflowData?.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>
                Number of Efficacy :<span style={{ color: "red" }}>*</span>
              </b>
            </Form.Label>
            <Form.Select
              onChange={(e) => setNumberOfEfficacy(e.target.value)}
              className="rounded-1"
              aria-label="Default select example"
              value={numberOfEfficacy}
            >
              <option>Select</option>
              {[...Array(20).keys()].map((i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>
                Number of Safety :<span style={{ color: "red" }}>*</span>
              </b>
            </Form.Label>
            <Form.Select
              onChange={(e) => setNumberOfSafety(e.target.value)}
              className="rounded-1"
              aria-label="Default select example"
              value={numberOfSafety}
            >
              <option>Select</option>
              {[...Array(50).keys()].map((i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className="text-end">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <OverlayTrigger
                  placement="top"
                  overlay={isDisabled ? renderTooltip : <span></span>}
                >
                  <button
                    onClick={!quatationId ? handleSubmit : updatequotation}
                    disabled={!workflowID}
                  >
                    {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 15, height: 15 }}
                        className="me-2"
                      />
                    ) : null}
                    {!quatationId ? "Add" : "Update"} Qutations
                  </button>
                </OverlayTrigger>
              </div>
            </Form.Group>
          </div>
        </div>
        <SplitPane
          split="vertical"
          minSize={20}
          defaultSize={"25%"}
          style={{ height: "calc(100vh - 320px)" }}
        >
          <div className="sidebar_section border-end">
            <div className="workflow_text w-100 ">
              <div className="sid_li">
                <NavLink to={"#"}>
                  <div
                    className="overflow-auto"
                    style={{ height: "calc(100vh - 128px)" }}
                  >
                    {qutationData?.length > 0 ? (
                      qutationData.slice(0, visibleItems).map((item, index) => (
                        <>
                          <div
                            key={index}
                            className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${
                              item?._id?.$oid === quatation
                                ? "sidebarMenuActive"
                                : ""
                            }`}
                            onClick={() => {
                              getDataForQuatationCost(item?._id?.$oid);
                              setQuatationWorkflowID(item?.workflowID);
                              setPhaseID(item?.phaseID);
                            }}
                          >
                            <ContextMenuTrigger
                              id={`workflowMenu_${index}`}
                              collect={() => ({
                                id: `workflowMenu_${index}` + 1,
                              })}
                            >
                              <div
                                className={`${
                                  item?.status === "inactive"
                                    ? "text-muted"
                                    : ""
                                }`}
                              >
                                {listLoader ? (
                                  <Placeholder animation="glow">
                                    <Placeholder xs={1} className="" />
                                    <Placeholder xs={11} />
                                  </Placeholder>
                                ) : (
                                  <div>
                                    <i class="fa-brands fa-wordpress me-2"></i>
                                    <span style={{ fontSize: "13px" }}>
                                      {item?.workflowName}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </ContextMenuTrigger>
                            <RightClick
                              ContextId={`workflowMenu_${index}`}
                              Menu2
                              MenuName2={"Update"}
                              icons2={
                                <i class="fa-solid fa-pen-to-square text-white"></i>
                              }
                              className2={"text-white"}
                              handleClick2={() => handleUpdateClick(item)}
                              Menu4
                              MenuName4={"Delete"}
                              icons4={
                                <i class="fa-solid fa-trash-can text-danger"></i>
                              }
                              className4={"text-white "}
                              handleClick4={() =>
                                deleteQuatation(item?._id?.$oid)
                              }
                            />
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="p-2 workflowMenuList">
                        <i class="fa-regular fa-face-frown ms-2 me-2"></i>
                        <span style={{ fontSize: "13px" }}>
                          Quotations not found!
                        </span>
                      </div>
                    )}
                    {visibleItems < qutationData?.length && (
                      <div
                        onClick={loadMore}
                        className="p-2 workflowMenuList sidebarMenuActive position-sticky bottom-0"
                      >
                        <i class="fa-solid fa-hourglass-end ms-2 me-2"></i>
                        <span style={{ fontSize: "13px" }}>Load More...</span>
                      </div>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>
          </div>

          {dataloader ? (
            <div
              className="hstack w-100 justify-content-center"
              style={{ height: "calc(100vh - 337px)" }}
            >
              <Spinner />
            </div>
          ) : (
            efficacyCostName && (
              <Container fluid>
                <Row>
                  <Form.Group as={Col} md={6} className="pt-2">
                    <div className="mb-3">
                      <Card className="shadow-sm border-0 h-100">
                        <Card.Body className="qutationCard">
                          <div className="">
                            <Card.Title className="text-muted mb-1">
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {numberOfEfficacyName}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  style={{ width: 70, height: 24 }}
                                  value={numberOfEfficacyValue}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {effortsInEfficacyEndPointName}
                                </Form.Label>
                                <div className="inputforSecelct justify-content-end">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      style={{ width: 49, height: 24 }}
                                      value={effortsInEfficacyEndPointValue}
                                      onChange={(e) =>
                                        setEffortsInEfficacyEndPointValue(
                                          e.target.value
                                        )
                                      }
                                    />

                                    <InputGroup.Text className="qutationsInputBox px-1">
                                      hr
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {efficacyCostName}
                                </Form.Label>

                                <div className="inputforSecelct">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      value={efficacyCostValue}
                                      style={{ width: 49, height: 24 }}
                                      onChange={(e) =>
                                        setEfficacyCostValue(e.target.value)
                                      }
                                    />

                                    <InputGroup.Text
                                      className="qutationsInputBox"
                                      style={{ padding: 6 }}
                                    >
                                      $
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {reviewEffortInEfficacyName}
                                </Form.Label>
                                <div className="inputforSecelct">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      value={reviewEffortInEfficacyValue}
                                      style={{ width: 49, height: 24 }}
                                      onChange={(e) =>
                                        setReviewEffortInEfficacyValue(
                                          e.target.value
                                        )
                                      }
                                    />

                                    <InputGroup.Text className="qutationsInputBox px-1">
                                      hr
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {reviewEfficacyCostName}
                                </Form.Label>
                                <div className="inputforSecelct">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      value={reviewEfficacyCostValue}
                                      style={{ width: 49, height: 24 }}
                                      onChange={(e) =>
                                        setReviewEfficacyCostValue(
                                          e.target.value
                                        )
                                      }
                                    />

                                    <InputGroup.Text
                                      className="qutationsInputBox"
                                      style={{ padding: 6 }}
                                    >
                                      $
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                            </Card.Title>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="pt-2">
                    <div className="mb-3">
                      <Card className="shadow-sm border-0 h-100">
                        <Card.Body className="qutationCard">
                          <div className="">
                            <Card.Title className="text-muted mb-1">
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {numberOfSafetyName}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  value={numberOfSafetyValue}
                                  style={{ width: 70, height: 24 }}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {effortsInsafetyEndPointName}
                                </Form.Label>
                                <div className="inputforSecelct justify-content-end">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      style={{ width: 49, height: 24 }}
                                      value={effortsInsafetyEndPointValue}
                                      onChange={(e) =>
                                        setEffortsInsafetyEndPointValue(
                                          e.target.value
                                        )
                                      }
                                    />

                                    <InputGroup.Text className="qutationsInputBox px-1">
                                      hr
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {safetyCostName}
                                </Form.Label>
                                <div className="inputforSecelct">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      value={safetyCostValue}
                                      style={{ width: 49, height: 24 }}
                                      onChange={(e) =>
                                        setSafetyCostValue(e.target.value)
                                      }
                                    />

                                    <InputGroup.Text
                                      className="qutationsInputBox"
                                      style={{ padding: 6 }}
                                    >
                                      $
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>

                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {reviewEffortInSafetyName}
                                </Form.Label>

                                <div className="inputforSecelct">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      value={reviewEffortInSafetyValue}
                                      style={{ width: 49, height: 24 }}
                                      onChange={(e) =>
                                        setReviewEffortInSafetyValue(
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroup.Text className="qutationsInputBox px-1">
                                      hr
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              <FormGroup className="d-flex justify-content-between gap-2">
                                <Form.Label className="p-1">
                                  {reviewSafetyCostName}
                                </Form.Label>
                                <div className="inputforSecelct">
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      style={{ width: 49, height: 24 }}
                                      value={reviewSafetyCostValue}
                                      onChange={(e) =>
                                        setReviewSafetyCostValue(e.target.value)
                                      }
                                    />

                                    <InputGroup.Text
                                      className="qutationsInputBox"
                                      style={{ padding: 6 }}
                                    >
                                      $
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                            </Card.Title>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Form.Group>

                  <Form.Group as={Col} md={6} className="pt-2">
                    {totalCost && (
                      <div className="mb-3">
                        <Card className="shadow-sm border-0 h-100">
                          <Card.Body style={{ padding: 4 }}>
                            <div className="text-end ">
                              <Card.Title
                                style={{
                                  paddingRight: 40,
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                <div className="text-end">
                                  <Form.Label>
                                    Total Cost : {totalCost} $
                                  </Form.Label>
                                </div>
                              </Card.Title>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <div className="text-end pt-3">
                      <div className="button_type">
                        <button
                          onClick={() => {
                            setShowCost(true);
                            getQuatationCost();
                          }}
                        >
                          {loading ? (
                            <Spinner
                              animation="border"
                              style={{ width: 15, height: 15 }}
                              className="me-2"
                            />
                          ) : null}
                          Calculate
                        </button>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Container>
            )
          )}
        </SplitPane>
      </SplitPane>
    </>
  );
};

export default QutationsList;
