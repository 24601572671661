import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  FormGroup,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";
import { clientServices } from "../../APIServices/clientServices";

const AddSponsorClientModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  clientData,
  getAllSponsor,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [officephoneNumber, setOfficephoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [address, setAddress] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let userData = {};
    userData.companyName = companyName;
    userData.shortName = shortName;
    userData.website = website;
    userData.Address = address;
    userData.officePhoneNumber = officephoneNumber;
    userData.City = city_id;
    userData.State = state_id;
    userData.Country = country_id;
    userData.zipCode = postalCode;
    let data = await clientServices.addSponsorClient(userData);
    setStatusMessage(data);
    setLoader(false);
    if (data?.status_code === 200) {
      getAllSponsor();
      clearFormData();
    }
  };

  const isdisabled =
    !companyName ||
    !address ||
    !officephoneNumber ||
    !city_id ||
    !state_id ||
    !country_id ||
    !postalCode ||
    phoneError;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill all mandatory fields
    </Tooltip>
  );

  const clearFormData = () => {
    Hide();
    setLoader(false);
    setCompanyName("");
    setShortName("");
    setWebsite("");
    setOfficephoneNumber("");
    setAddress("");
    setPostalCode("");
    setCityID("");
    setStateID("");
    setCountryID("");
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits");
    }
  };

  useEffect(() => {
    getCountries();
  }, []);
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title> Add Sponsor</Modal.Title>
            <div
              onClick={() => clearFormData()}
              className="close_modal"
              title="Close"
            >
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Row>
              <FormGroup as={Col} md={6} className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  <b>Company Name</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="rounded-1"
                  placeholder="Enter Your Company  Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                />
              </FormGroup>
              <FormGroup as={Col} md={6} className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  <b>Short Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="rounded-1"
                  placeholder="Enter Your Short Name"
                  onChange={(e) => setShortName(e.target.value)}
                  value={shortName}
                />
              </FormGroup>
              <FormGroup as={Col} md={6} className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  <b>Website Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="rounded-1"
                  placeholder="Enter Your Website Name"
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                />
              </FormGroup>
              <Form.Group as={Col} md={6} className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  <b> Office Phone Number</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  className="rounded-1"
                  placeholder="Enter Phone Number"
                  onChange={(e) => {
                    setOfficephoneNumber(e.target.value);
                    validatePhoneNumber(e.target.value);
                  }}
                  value={officephoneNumber}
                />
                {phoneError && (
                  <p className="text-danger" style={{ marginTop: "5px" }}>
                    {phoneError}
                  </p>
                )}
              </Form.Group>
              <FormGroup as={Col} md={12} className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  <b>Address</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="rounded-1"
                  placeholder="Enter your address"
                  style={{ height: 100 }}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </FormGroup>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  Country <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  className="rounded-1"
                  onChange={(e) => {
                    setCountryID(e.target.value);
                    getStates(e.target.value);
                  }}
                  value={country_id}
                >
                  <option>-Select-</option>
                  {countryLists?.length > 0 &&
                    countryLists.map((item, key) => (
                      <option value={item.id} key={key} title={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  State <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  className="rounded-1"
                  onChange={(e) => {
                    setStateID(e.target.value);
                    getCities(e.target.value);
                  }}
                  value={state_id}
                >
                  <option>-select-</option>
                  {stateLists.length > 0 &&
                    stateLists.map((item, key) => (
                      <option value={item.id} key={key} title={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  City <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  className="rounded-1"
                  onChange={(e) => setCityID(e.target.value)}
                  value={city_id}
                >
                  <option>-select-</option>
                  {cityLists.length > 0 &&
                    cityLists.map((item, key) => (
                      <option value={item.id} key={key}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  Zip Code <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  required
                  className="rounded-1"
                  type="number"
                  placeholder="Enter Postal Code"
                  onChange={(e) => setPostalCode(e.target.value)}
                  value={postalCode}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <OverlayTrigger
                    placement="top"
                    overlay={isdisabled ? renderTooltip : <span></span>}
                  >
                    <button
                      type="submit"
                      disabled={
                        !companyName ||
                        !address ||
                        !officephoneNumber ||
                        !city_id ||
                        !state_id ||
                        !country_id ||
                        !postalCode ||
                        phoneError
                      }
                      onClick={handleSubmit}
                    >
                      {loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 15, height: 15 }}
                          className="me-2"
                        />
                      ) : null}
                      Submit
                    </button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddSponsorClientModal;
