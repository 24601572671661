import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import LoginImage from "../../Images/loginimage.png";
import { authServices } from "../../APIServices/authServices";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const toggleNewPasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };
  const resetPassword = async () => {
    setLoader(true);
    let userData = {};
    userData.newPassword = newPassword;
    userData.changePassword = changePassword;
    let data = await authServices.changePassword(userData);
    setLoader(false);
    if (data?.status_code === 200) {
      resetInputField();
      window.location = "/login";
    }
  };

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;
    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const resetInputField = () => {
    setNewPassword("");
    setChangePassword("");
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={7} className="p-0">
            <div className="loginimg">
              <img src={LoginImage} alt="LoginPic" />
            </div>
          </Col>
          <Col md={5} className="p-4 signsection_two">
            <div className="loginside p-5">
              <div className="shadow p-5 rounded">
                <div className="loginContent gap-3">
                  <h5 className="text-center">
                    <b>Change Password</b>
                  </h5>
                </div>
                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    New Password
                    <span style={{ color: "red", marginleft: "5px" }}>*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      required
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your New Password"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        validatePassword(e.target.value);
                      }}
                    />
                    <div
                      className="passwordvisiability"
                      onClick={toggleNewPasswordVisibility}
                    >
                      {showPassword ? (
                        <i
                          className="fa-regular fa-eye-slash"
                          title="Hide Password"
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye"
                          title="Revel Password"
                        ></i>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    Confirm Password
                    <span style={{ color: "red", marginleft: "5px" }}>*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      value={changePassword}
                      onChange={(e) => {
                        setChangePassword(e.target.value);
                      }}
                      placeholder="Enter your Confirm Password"
                    />
                    <div
                      className="passwordvisiability"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? (
                        <i
                          className="fa-regular fa-eye-slash"
                          title="Hide Password"
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye"
                          title="Revel Password"
                        ></i>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="12" className="mb-3 text-center">
                  <div className="button_type">
                    <button
                      type="submit"
                      variant="outline-dark"
                      className="loginButton mt-2 w-100"
                      disabled={!newPassword || !changePassword}
                      onClick={resetPassword}
                    >
                      {loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 15, height: 15 }}
                          className="me-2"
                        />
                      ) : null}
                      Change Password
                    </button>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePassword;
