import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { elearningServices } from "../../APIServices/elearningServices";
import { useParams } from "react-router-dom";

const AddUpdateLearning = ({
  getAllElearningMeta,
  setTrainingName,
  trainingName,
  description,
  setDescription,
  completionDays,
  setCompletionDays,
  updateDataId,
  setUpdateDataId,
  questions,
  setQuestions,
  multiselectAnswer,
  setMultiselectAnswer
}) => {
  const [loader, setLoader] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const { studyId } = useParams();




  const addElearningMeta = async (e) => {
    e.preventDefault();
    setLoader(true);
    let userData = {};
    userData.trainingName = trainingName;
    userData.description = description;
    userData.completionDays = completionDays;
    userData.studyID = studyId ? studyId : "undefined";
    let data = await elearningServices.addElearningMeta(userData);
    setLoader(false);
    getAllElearningMeta();
    if (data?.status_code === 200) {
    } else {
      setLoader(false);
    }
  };

  const updateElearningMeta = async () => {
    let _id = updateDataId;
    let userData = {};
    userData.trainingName = trainingName;
    userData.description = description;
    userData.completionDays = completionDays;
    setLoader(true);
    let data = await elearningServices.updateElearningMeta(_id, userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      getAllElearningMeta();
      setStatusCode(true);
      clearFormData();
      setUpdateDataId("");
    }
  };

  const clearFormData = () => {
    setTrainingName("");
    setDescription("");
    setCompletionDays("");
  };

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Training Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={trainingName}
          placeholder="Enter Traning Name"
          className="rounded-1 fontSize12"
          onChange={(e) => setTrainingName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Description <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={description}
          className="rounded-1 fontSize12"
          placeholder="Enter Training Description"
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          No. Of Completion Days <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          value={completionDays}
          className="rounded-1 fontSize12"
          placeholder="Enter No. Of Completion Days"
          onChange={(e) => setCompletionDays(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="text-end mb-2 button_type">
        <button
          className="rounded-1"
          disabled={!trainingName || !description}
          onClick={!updateDataId ? addElearningMeta : updateElearningMeta}
        >
          {loader ? (
            <Spinner className="me-2" style={{ width: 15, height: 15 }} />
          ) : (
            <i className="fa-solid fa-square-plus me-2"></i>
          )}
          {!updateDataId ? "Add" : "Update"} Training
        </button>
      </Form.Group>
    </div>
  );
};

export default AddUpdateLearning;
