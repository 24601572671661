import React, { useEffect, useState } from "react";
import OrganizationalChart from "./Component/OrganizationalChart";
import employees from "./data.js";
import { orgServices } from "../../APIServices/orgServices.jsx";

const OrgChart = () => {
  const [organizationChartData, setOrganizationChartDat] = useState([]);

  const getOrganizationChart = async () => {
    let data = await orgServices.getOrganizationChart();
    setOrganizationChartDat(data?.data);

  }
  console.log(">>>>organizationChartData", organizationChartData);

  useEffect(() => {
    getOrganizationChart();
  }, [])

  return (
    <>
      <OrganizationalChart data={organizationChartData} />
    </>
  )
};

export default OrgChart;
