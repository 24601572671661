import React, { useEffect, useState } from "react";
import AddTeamModal from "./AddTeamModal";
import { Button, Form, InputGroup } from "react-bootstrap";
import UseToggle from "../../Hooks/UseToggle";
import PullUserModal from "./PullUserModal";
import UserInvitationModal from "./UserInvitationModal";
import TeamList from "./TeamList";
import ExportTeamModal from "./ExportTeamModal";

const Team = ({ tableRef, onMouseDown, teamsData, loading, getUsers }) => {
  const [filter, setFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
  } = UseToggle();

  return (
    <>
      <div className="hstack inputWorkFlow gap-2 px-2  border-bottom" style={{ padding: 6 }}>
        {/* <div className="filter_section hstack p-0">
          {filter ? (
            <>
              <div className="h-stack justify-content-between">
                <InputGroup>
                  <InputGroup.Text
                    onClick={() => {
                      setFilter(false);
                    }}
                    title="Cancel"
                    className="search_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-xmark text-muted"></i>
                  </InputGroup.Text>
                  <Form.Control type="search" className="search-bar" />
                  <InputGroup.Text
                    title="Search"
                    className="teams_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </>
          ) : (
            <button
              onClick={() => setFilter(true)}
              title="Filter"
              className="teams_button border rounded-1 fs-12"
            >
              <i className="fa-solid fa-filter text-muted mt-1"></i>
            </button>
          )}
        </div> */}

        <button
          title="Add Team"
          className="teams_button border rounded-1 fs-12"
          onClick={setToggle}
        >
          <i className="fa-solid fa-plus text-muted mt-1"></i>
        </button>

        <button
          title="User Invitation"
          onClick={setToggle2}
          className="teams_button border rounded-1 fs-12"
        >
          <i className="fa-regular fa-paper-plane text-muted mt-1"></i>
        </button>

        {/* <button
          onClick={setToggle1}
          title="Pull EDC User"
          className="teams_button border rounded-1 fs-12"
        >
          <i class="fa-solid fa-code-pull-request text-muted mt-1"></i>
        </button>
        <div className="ms-auto px-2">
          <button
            onClick={setToggle3}
            title="Export Team  User"
            className="teams_button border rounded-1 fs-12"
          >
            <i class="fa-solid fa-file-arrow-down text-muted mt-1"></i>
          </button>
        </div> */}
      </div>

      <TeamList
        getUsers={getUsers}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
        loading={loading}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        teamsData={teamsData}
      />
      <AddTeamModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title="Add User"
        Submit={"Add User"}
        getUsers={getUsers}
      />
      <PullUserModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title="Pull EDC User"
        Submit={"Login"}
      />
      <UserInvitationModal
        Show={!toggle2}
        Hide={setToggle2}
        Size={"md"}
        Title="User Invitation"
        Submit={"Send Invite"}
      />

      <ExportTeamModal
        Show={!toggle3}
        Hide={setToggle3}
        Size={"md"}
        Title="Faild login Data"
        Submit={"Faild Login data"}
      />
    </>
  );
};

export default Team;
