import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
} from "../APIHelpers/Responses";
import axios from "axios";

export const profileServices = {
  uploadImage,
  updateProfile,
};

// uploadImage
// async function uploadImage(formData, userData) {
//   const response = await fetch(
//     `${config.API_URL}uploadImage`,
//     headersWithAuth("POST", userData, formData)
//   );
//   const data = await response.json();
//   handleResponse(data);
//   return data;
// }

async function uploadImage(formData) {
  const response = await axios.post(
    `${config.API_URL}uploadImage`,
    formData,
    {
      headers: {
        "Authorization": headers.authorization,
      },
    }
  );
  const data = await response.data;
  let customMessage = data ? {
    message: "Profile Image has been successfully uplodeded",
    status_code: 200
  } : {
    message: "Opps! Profile Image not upload",
    status_code: 400
  }
  handleResponse(customMessage);
  return data;
}

//updateProfile
async function updateProfile(userData) {
  const response = await fetch(
    `${config.API_URL}updateProfile`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
