import React from "react";
import { Form } from "react-bootstrap";

const AccessTab = () => {
  return (
    <>
      <div className="p-2">
        <Form.Group>
          <Form.Label>
            <b>Select Access</b>
          </Form.Label>
          <div>
            <Form.Check type={"checkbox"} label="Admin" />
            <Form.Check type={"checkbox"} label="User" />
            <Form.Check type={"checkbox"} label="Client" />
          </div>
        </Form.Group>
      </div>
    </>
  );
};

export default AccessTab;
