import React, { useEffect, useState } from "react";
import { Card, Table, Col } from "react-bootstrap";
import Moment from "react-moment";
import { loginActivitiesServices } from "../../APIServices/loginActivitiesServices";
import { NavLink } from "react-router-dom";

const RecentLoginTableCard = () => {
  const [logData, setLogData] = useState([]);
  const [displayCount, setDisplayCount] = useState(7); // State to control the number of items displayed

  const loginActivities = async () => {
    let data = await loginActivitiesServices.loginActivities();
    setLogData(data.data);
  };

  const getActivityColor = (activity) => {
    switch (activity.trim().toLowerCase()) {
      case "login successfully":
        return "green";
      case "login failed":
        return "red";
      case "login pending":
        return "blue";
      default:
        return "inherit";
    }
  };

  useEffect(() => {
    loginActivities();
  }, []);

  return (
    <>
      <div>
        <h6 style={{ marginBottom: "12px" }}>Recent Logs</h6>
      </div>
      <div className="table_content" style={{ maxHeight: "100%" }}>
        <Table
          className="table-bordered table-hover mb-3 bg-light"
          striped
          bordered
          hover
        >
          <thead className="cardTable">
            <tr>
              <th>Status</th>
              <th>In/Out Time</th>
            </tr>
          </thead>
          <tbody>
            {logData?.slice(0, displayCount).map((log, index) => (
              <tr key={index}>
                <td className="text-capitalize">
                  <div style={{ color: getActivityColor(log.activity) }}>
                    {log.activity}
                  </div>
                </td>
                <td className="text-capitalize">
                  <Moment format="DD MMM YYYY">{log.timestamp}</Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="button_type text-end">
          <NavLink to={"/logdata"}>
            <button>View All</button>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default RecentLoginTableCard;
