import React, { useEffect, useState } from "react";
import { Offcanvas, Table } from "react-bootstrap";
import { teamServices } from "../../APIServices/teamServices";

const TeamViewDetailsOffcanvas = ({
    show,
    onHide,
    size,
    userID
}) => {
    const [viewUserDetails, setViewUserDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUserById = async () => {
        let data = await teamServices.getUserById(userID);
        setViewUserDetails(data?.data);
        setLoading(false);
    };

    console.log("viewUserDetails", viewUserDetails);


    useEffect(() => {
        getUserById();
    }, [userID]);

    const getStatusColor = (status) => {
        if (!status) return "inherit";
        switch (status.toLowerCase()) {
            case "active":
                return "green";
            case "inactive":
                return "red";
            case "pending":
                return "orange";
            default:
                return "inherit";
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return (
        <Offcanvas
            show={show}
            size={size}
            onHide={onHide}
            placement="end"
            style={{ width: "400px" }}
        >
            <Offcanvas.Header className="py-2">
                <div className="hstack justify-content-between w-100 gap-3">
                    <Offcanvas.Title>
                        <b>View User Details</b>
                    </Offcanvas.Title>
                    <div
                        onClick={onHide}
                        className="offcanvas_Close"
                        title="Close"
                        style={{ cursor: "pointer" }}
                    >
                        X
                    </div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column p-2">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className="fw-bold">Attributes</th>
                            <th className="fw-bold">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <b>Name</b>
                            </td>
                            <td>
                                <span className="p-1">{viewUserDetails?.fullName}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Gender</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {capitalizeFirstLetter(viewUserDetails?.gender || '')}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Phone</b>
                            </td>
                            <td>
                                <span className="p-1">{viewUserDetails?.phone}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Status</b>
                            </td>
                            <td style={{ color: getStatusColor(viewUserDetails?.status) }}>
                                <span className="p-1">
                                    {capitalizeFirstLetter(viewUserDetails?.status || '')}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Email ID</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails?.email}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Role</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {capitalizeFirstLetter(viewUserDetails?.role || '')}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Admin</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails.adminID}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Address</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails?.Address}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>City</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails?.City}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>State</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails?.State}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Country</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails?.Country}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Zip Code</b>
                            </td>
                            <td>
                                <span className="p-1">
                                    {viewUserDetails?.zipCode}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default TeamViewDetailsOffcanvas;
