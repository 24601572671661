import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
const DownloadFaildDataModal = ({ Show, Submit, Hide, Size }) => {
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>Export Faild Data</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Form.Label>
              <b>
                Formate <span style={{ color: "red" }}>*</span>
              </b>
            </Form.Label>
            <Form.Select
              name="authType"
              placeholder="Select your Authentication Type"
              style={{ boxShadow: "none" }}
            >
              <option value="">Select</option>
              <option value="Email">CSV</option>
              <option value="Apps">Excel</option>
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <button type="submit">Export Faild Login Data</button>
                </div>
              </Form.Group>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default DownloadFaildDataModal;
