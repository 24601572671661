import React, { useEffect, useState } from "react";
import { Button, Form, Placeholder } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import UseToggle from "../../Hooks/UseToggle";
import TemplatesAddModal from "./TemplatesAddModal";
import { tempelatesServices } from "../../APIServices/tempelatesServices";
import Swal from "sweetalert2";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Component/RightClick/RightClick";
import TempelatesPropertiesModal from "./tempelatesPropertiesModal";

function TemplateSidebar({ setTemplateRowId, templateRowId }) {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [name, setName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateId, setTemplateId] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [templateData, setTemplateData] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [visibleItems, setVisibleItems] = useState(16);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const updateTemplate = (id, name) => {
    setToggle();
    setTemplateId(id);
    setName(name);
  };

  const getAllTemplates = async () => {
    setListLoader(true);
    let data = await tempelatesServices.getAllTemplates();
    setTemplateList(data.data);
    setListLoader(false);
  };



  const handleRightClick = (event, template) => {
    event.preventDefault();
    setSelectedTemplate(template);
  };

  const handleViewProperty = (item) => {
    setToggle1();
    setTemplateData(item);
  };

  const deleteTemplate = async (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete WorkFlow!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await tempelatesServices.deleteTemplate(Id);
        if (data?.status_code === 200) {
          getAllTemplates();
        }
      }
    });
  };

  const updateTemplatStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Template Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await tempelatesServices.updateTemplate(id, data);
        if (results?.status_code === 200) {
          getAllTemplates();
        }
      }
    });
  };

  const searchTemplate = async (name) => {
    setListLoader(true);
    let data = await tempelatesServices.searchTemplate(name);
    if (!name) {
      getAllTemplates();
    } else {
      setTemplateList(data.data);
      setListLoader(false);
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);
  return (
    <>
      <div className="sidebar_section border-end">
        <div className="hstack inputWorkFlowsection border-bottom">
          <Form.Control
            type="search"
            onChange={(e) => searchTemplate(e.target.value)}
            placeholder="Search here..."
            className="addworkflowcrontol rounded-1 border-0"
          />
          <button
            title="Add New Tempelates"
            className="teams_button fs-12 border-0 border-top"
            style={{ height: 34, width: 31, padding: 10 }}
            onClick={setToggle}
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </div>
        <div className="workflow_text w-100 ">
          <div className="sid_li">
            <NavLink
              to={"#"}
              onContextMenu={(e) => handleRightClick(e, "template 1")}
            >
              <div
                className=" overflow-auto"
                style={{ height: "calc(100vh - 128px)" }}
              >
                {templateList?.length > 0 ? (
                  templateList.slice(0, visibleItems).map((item, index) => (
                    <div
                      key={index}
                      className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${item?._id?.$oid === templateRowId
                        ? "sidebarMenuActive"
                        : ""
                        }`}
                      onClick={() => setTemplateRowId(item?._id?.$oid)}
                    >
                      <ContextMenuTrigger
                        id={`workflowMenu_${index}`}
                        collect={() => ({
                          id: `workflowMenu_${index}` + 1,
                        })}
                      >
                        <div
                          className={`${item?.status === "inactive" ? "text-danger" : ""
                            }`}
                        >
                          {listLoader ? (
                            <Placeholder animation="glow">
                              <Placeholder xs={1} className="" />
                              <Placeholder xs={11} />
                            </Placeholder>
                          ) : (
                            <div>
                              <i class="fa-brands fa-wordpress me-2"></i>
                              <span style={{ fontSize: "13px" }}>
                                {item.name}
                              </span>
                            </div>
                          )}
                        </div>
                      </ContextMenuTrigger>
                      <RightClick
                        ContextId={`workflowMenu_${index}`}
                        Menu1
                        MenuName1={
                          item.status === "active" ? "Inctive" : "Active"
                        }
                        icons1={
                          <i
                            class={`fa-solid fa-circle ${item.status === "active"
                              ? "text-danger"
                              : "text-success"
                              }`}
                          ></i>
                        }
                        className1={"text-white"}
                        handleClick1={() =>
                          updateTemplatStatus(
                            item?._id.$oid,
                            item?.status === "active" ? "inactive" : "active"
                          )
                        }
                        Menu2
                        MenuName2={"Update"}
                        icons2={
                          <i class="fa-solid fa-pen-to-square text-white"></i>
                        }
                        className2={"text-white"}
                        handleClick2={() =>
                          updateTemplate(item?._id.$oid, item?.name)
                        }
                        Menu3
                        MenuName3={"Properties"}
                        icons3={
                          <i class="fa-solid fa-circle-info text-white"></i>
                        }
                        className3={"text-white"}
                        handleClick3={() => handleViewProperty(item)}
                        Menu4
                        MenuName4={"Delete"}
                        icons4={
                          <i class="fa-solid fa-trash-can text-danger"></i>
                        }
                        className4={"text-white "}
                        handleClick4={() => deleteTemplate(item._id.$oid)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="p-2 workflowMenuList">
                    <i class="fa-regular fa-face-frown ms-2 me-2"></i>
                    <span style={{ fontSize: "13px" }}>
                      Tempelate not found!
                    </span>
                  </div>
                )}
                {visibleItems < templateList?.length && (
                  <div
                    onClick={loadMore}
                    className="p-2 workflowMenuList sidebarMenuActive position-sticky bottom-0"
                  >
                    <i class="fa-solid fa-hourglass-end ms-2 me-2"></i>
                    <span style={{ fontSize: "13px" }}>Load More...</span>
                  </div>
                )}
              </div>
            </NavLink>
          </div>
        </div>
      </div>

      <TempelatesPropertiesModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        templateData={templateData}
        Title="Add Templats"
      />

      <TemplatesAddModal
        Show={!toggle}
        Hide={setToggle}
        getAllTemplates={getAllTemplates}
        templateId={templateId}
        setTemplateId={setTemplateId}
        Size={"md"}
        name={name}
        setName={setName}
      />
    </>
  );
}

export default TemplateSidebar;
