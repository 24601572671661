import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import UseToggle from "../../Hooks/UseToggle";
import PhaseOffcanvas from "./QuotatioOffcanvas/PhaseOffcanvas";
import { quotationServices } from "../../APIServices/quotationServices";
import { ContextMenuTrigger } from "react-contextmenu";
import Moment from "react-moment";
import RightClick from "../../Component/RightClick/RightClick";
import Paginations from "../../Hooks/Paginations";
import { NoDataFoundTable } from "../../Hooks/NoDataFoundTable";
import Swal from "sweetalert2";

const QuotationPhase = ({ workFlowId }) => {
  const { toggle1, setToggle1 } = UseToggle();
  const [phaseData, setPhaseData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activePhaseData, setActivePhaseData] = useState([]);
  const [updatePhaseId, setUpdatePhaseId] = useState("");
  const [updatePhaseDetails, setUpdatePhaseDetails] = useState("");

  const getAllPhaseService = async () => {
    let data = await quotationServices.getAllPhaseService(workFlowId);
    setPhaseData(data?.data);
    setLoading(false);
  };

  const getPhaseIdByWorkflowId = async () => {
    let data = await quotationServices.getPhaseIdByWorkflowId(workFlowId);
    setActivePhaseData(data?.data);
  };

  const formatStatus = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  const deletePhase = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Phase!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await quotationServices.deletePhaseService(_id);
        if (results?.status_code === 200) {
          getAllPhaseService();
          getPhaseIdByWorkflowId();
        }
      }
    });
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  const updatePhaseService = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await quotationServices.updatePhaseService(_id, data);
        if (results?.status_code === 200) {
          getAllPhaseService();
        }
      }
    });
  };

  const updateInvoice = (item) => {
    setToggle1();
    setUpdatePhaseDetails(item);
    setUpdatePhaseId(item?._id?.$oid);
  };

  const handleRowClick = (_id, status) => {
    updatePhaseService(_id, status);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = phaseData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(phaseData?.length / itemsPerPage);

  useEffect(() => {
    getAllPhaseService();
  }, [workFlowId]);

  useEffect(() => {
    getPhaseIdByWorkflowId();
  }, []);

  return (
    <>
      <div className="hstack p-2 inputWorkFlow gap-2 px-1 border-bottom">
        <button
          onClick={setToggle1}
          title="Add Quotations"
          className="teams_button border rounded-1 fs-12"
        >
          <i className="fa-solid fa-plus text-muted mt-1"></i>
        </button>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <Spinner animation="border" />
        </div>
      ) : result?.length > 0 ? (
        <>
          <div
            className="team_table overflow-auto"
            style={{ height: "calc(100vh - 130px" }}
          >
            <Table
              id="resizable-table"
              bordered
              className="custom-table"
              striped
              border
              hover
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr style={{ cursor: "pointer" }}>
                  <th style={{ border: "1px solid #896e6e5e", width: 41 }}>
                    Sr.
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 140 }}>
                    Phase Name
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 140 }}>
                    Status
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Efficacy Endpoint
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Cost
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Safety Endpoint
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Cost
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Efficacy Endpoint
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Cost
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Safety Endpoint
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Cost
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Created Date | Time
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 180 }}>
                    Updated Date | Time
                  </th>

                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={item.id}
                    className={selectedRow === item._id.$oid ? "selected" : ""}
                    onClick={() => setSelectedRow(item._id.$oid)}
                  >
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {startIndex + index + 1}
                      </ContextMenuTrigger>
                      <RightClick
                        ContextId={`clientData${index}`}
                        Menu1
                        MenuName1={
                          item.status === "active" ? "Inctive" : "Active"
                        }
                        icons1={
                          <i
                            class={`fa-solid fa-circle ${item.status === "active"
                              ? "text-danger"
                              : "text-success"
                              }`}
                          ></i>
                        }
                        className1={"text-white"}
                        handleClick1={() =>
                          handleRowClick(
                            item._id.$oid,
                            item.status === "active" ? "inactive" : "active"
                          )
                        }
                        Menu2
                        MenuName2={"Update"}
                        icons2={
                          <i class="fa-solid fa-pen-to-square text-white"></i>
                        }
                        className2={"text-white"}
                        handleClick2={() => updateInvoice(item)}
                        Menu3
                        MenuName3={"Delete"}
                        icons3={
                          <i class="fa-solid fa-trash-can text-danger"></i>
                        }
                        className3={"text-white "}
                        handleClick3={() => deletePhase(item?._id?.$oid)}
                      />
                    </td>
                    <td>
                      {" "}
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.phaseName}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <div style={{ color: getStatusColor(item.status) }}>
                          {formatStatus(item.status)}
                        </div>
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.effortsInEfficacyEndPoint} hours
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        $ {item.efficacyCost}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.effortsInsafetyEndPoint} hours
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        $ {item.safetyCost}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.reviewEffortInEfficacy} hours
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        $ {item.reviewEfficacyCost}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.reviewEffortInSafety} hours
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        $ {item.reviewSafetyCost}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <Moment format="DD MMM YYYY | HH:MM">
                          {item.created_at}
                        </Moment>
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      {" "}
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <Moment format="DD MMM YYYY | HH:MM">
                          {item.updated_at}
                        </Moment>
                      </ContextMenuTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={phaseData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoDataFoundTable
          MSG={"Phase Data is Not Found"}
          style={{ height: "84%" }}
        />
      )}
      <PhaseOffcanvas
        show={!toggle1}
        onHide={setToggle1}
        workFlowId={workFlowId}
        activePhaseData={activePhaseData}
        setActivePhaseData={setActivePhaseData}
        getPhaseIdByWorkflowId={getPhaseIdByWorkflowId}
        getAllPhaseService={getAllPhaseService}
        updatePhaseDetails={updatePhaseDetails}
        updatePhaseId={updatePhaseId}
        setUpdatePhaseId={setUpdatePhaseId}
      />
    </>
  );
};

export default QuotationPhase;
