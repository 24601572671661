import React from 'react'
import unique from "../../Images/weAreUnique.png";
import fun from "../../Images/just Fun.jpg";
import game from "../../Images/game boye.jpg";
import profileImage from "../../Images/FriendForecer.jpg";

const GroupChat = () => {
    return (
        <div className="p-2">
            <div className="p-3 border shadow-sm rounded-3 mb-2">
                <div className="hstack mb-2">
                    <b>Group</b>
                </div>
                <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                    <div className="hstack mb-2">
                        <img src={profileImage} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                        <div className="message-content ms-2">
                            <h6 className="mb-0">Friendship Forever</h6>
                            <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                        </div>
                    </div>
                    <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                </div>
                <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                    <div className="hstack mb-2">
                        <img src={fun} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                        <div className="message-content ms-2">
                            <h6 className="mb-0">Just Fun</h6>
                            <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                        </div>
                    </div>
                    <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                </div>
                <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                    <div className="hstack mb-2">
                        <img src={unique} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                        <div className="message-content ms-2">
                            <h6 className="mb-0">We Are Unique</h6>
                            <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                        </div>
                    </div>
                    <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                </div>
                <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                    <div className="hstack mb-2">
                        <img src={game} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                        <div className="message-content ms-2">
                            <h6 className="mb-0">Game Boy</h6>
                            <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                        </div>
                    </div>
                    <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                </div>

            </div>
        </div>
    )
}

export default GroupChat