import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const AccessNumInputTemplate = () => {
  return (
    <>
      <div className="p-2">
        <Form.Group>
          <div className="hstack gap-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-require">Mandatary Columns</Tooltip>
              }
            >
              <Form.Label className="m-0">
                <b>Required</b>
              </Form.Label>
            </OverlayTrigger>
            <Form.Check
              className="requird_form_check"
              type="switch"
              aria-label="option 1"
            />
          </div>
          <Form.Label>
            <b>Select Access</b>
          </Form.Label>
          <div className="ms-3">
            <Form.Check inline type={"checkbox"} label="Admin" />
          </div>
          <div className="ms-3">
            <Form.Check inline type={"checkbox"} label="User" />
          </div>
          <div className="ms-3">
            <Form.Check inline type={"checkbox"} label="Client" />
          </div>
        </Form.Group>
      </div>
    </>
  );
};

export default AccessNumInputTemplate;
