import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReatingTemplateCustomization from "./RatingTemplateCustomization";
import UseToggle from "../../../Hooks/UseToggle";
import { v4 as uuidv4 } from "uuid";

const RatingSection = ({
  deleteSection,
  section,
  sections,
  addUpdateFormById,
}) => {
  const { toggle, setToggle } = UseToggle();
  const [formData, setFormData] = useState(section);
  const [rating, setRating] = useState([{ id: uuidv4(), value: "" }]);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section, sections]);

  console.log("formData", formData);

  const getStarColor = (ratingValue) => {
    if (ratingValue >= 1 && ratingValue <= 2) {
      return "red";
    } else if (ratingValue >= 3 && ratingValue <= 4) {
      return "yellow";
    } else if (ratingValue >= 5 && ratingValue <= 6) {
      return "green";
    }
    return "#000";
  };

  return (
    <>
      <div className="position-relative" style={{ marginBottom: 25 }}>
        <div className="templatesHeading mt-2"><span style={{ color: "#fff", fontWeight: "bold", fontSize: "11px" }}>RATING</span></div>
      </div>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between mb-2">
          <Form.Label className="mt-2 mb-0 d-flex gap-1">
            <b>
              <span
                style={{
                  fontWeight: formData?.labelStyle?.bold
                    ? formData.customize
                      ? "bold"
                      : "normal"
                    : "normal",
                  fontStyle: formData?.labelStyle?.italic
                    ? formData.customize
                      ? "italic"
                      : "normal"
                    : "normal",
                  color: formData.customize
                    ? formData?.labelStyle?.color
                    : "#000",
                }}
              >
                {formData?.label ? formData?.label : "Label"}
              </span>
              {formData?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </b>
            {formData?.help && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-header">{formData?.help}</Tooltip>
                }
              >
                <i className="fa-solid fa-circle-question text-dark"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header">Your Rating Order No.</Tooltip>}
            >
              <Form.Control
                type="text"
                disabled
                value={formData?.orderNo}
                className="templateorderform_Control"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Rating Customize</Tooltip>}
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={setToggle}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-pen-to-square px-1 py-1 text-success"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Delete Rating</Tooltip>}
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={() => deleteSection()}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-trash text-danger px-1 py-1 fs-12"></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>

        <div className="rating-container h-stack justify-content-between mt-3 ms-2">
          {formData?.options?.map((item, index) => {
            return (
              <i
                key={index}
                className="fa-solid fa-star me-2"
                style={{ color: getStarColor(formData.options.length) }}
              ></i>
            );
          })}
        </div>

      </div>
      <ReatingTemplateCustomization
        show={!toggle}
        onHide={setToggle}
        Title="Reating Section"
        id={formData?.id}
        type={formData?.type}
        addUpdateFormById={addUpdateFormById}
        sections={sections}
        rating={rating}
        setRating={setRating}
      />
    </>
  );
};

export default RatingSection;
