import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
const ExportTeamModal = ({ Show, Submit, Hide, Size }) => {
  const [formate, setFormate] = useState("");

  const isDisabled = !formate;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please Select Formate
    </Tooltip>
  );
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>Export Team Data</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Form.Label className="mb-1 fw-bold">
              <b>
                Formate <span style={{ color: "red" }}>*</span>
              </b>
            </Form.Label>
            <Form.Select
              name="authType"
              className="rounded-1"
              placeholder="Select your Authentication Type"
              style={{ boxShadow: "none" }}
            >
              <option value="">Select</option>
              <option value="Email">CSV</option>
              <option value="Apps">Excel</option>
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <OverlayTrigger
                    placement="top"
                    overlay={isDisabled ? renderTooltip : <span></span>}
                  >
                    <button disabled={!formate} type="submit">
                      Export Team Data
                    </button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ExportTeamModal;
