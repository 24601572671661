import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Card,
  Collapse,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const GeneralTab = ({
  label,
  setLabel,
  color,
  setColor,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  required,
  setRequired,
  customize,
  setCustomize,
  help,
  setHelp,
  dropdowns,
  setDropdowns,
  orderNo,
  setOrderNo,
}) => {
  const [open, setOpen] = useState(false);

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };

  const addDropdown = () => {
    setDropdowns([...dropdowns, { id: uuidv4(), value: "" }]);
  };
  const handleInputChange = (index, event) => {
    const newDropdowns = dropdowns.map((dropdown, i) => {
      if (i === index) {
        return { ...dropdown, value: event.target.value };
      }
      return dropdown;
    });
    setDropdowns(newDropdowns);
  };
  const removeDropdown = (index) => {
    setDropdowns(dropdowns.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="p-2 pb-0">
        <Form.Group>
          <div className="hstack justify-content-between">
            <Form.Label>
              <b>Label</b>
            </Form.Label>
            {label && (
              <Form.Check
                type="switch"
                label="Customize Label"
                onChange={setCustomization}
                checked={customize}
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Select label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Group>
      </div>
      <Collapse in={customize}>
        <div className="p-2">
          <Form.Label className="mb-1">
            <b>Customization</b>
          </Form.Label>
          <Card className="toogle-card shadow-sm border">
            <div className="hstack border-bottom">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
              >
                <div className="date-icon">
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bold"> Text Bold</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsBold(!isBold)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-bold ${
                      isBold ? "bg-body-secondary rounded-1" : ""
                    }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsItalic(!isItalic)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-italic ${
                      isItalic ? "bg-body-secondary rounded-1" : ""
                    }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
            </div>
            <div className="p-2">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  <b>Output</b>
                </Form.Label>
                <Card className="border h-1">
                  <Card.Body>
                    <Card.Text
                      className="text-center"
                      style={{
                        color,
                        fontWeight: isBold ? "bold" : "normal",
                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {label || "Output Result"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Check
                className="requird_form_check mb-2"
                type="switch"
                aria-label="option 1"
                checked={required}
                onClick={(e) => setRequired(e.target.value)}
                label="Required"
              />
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Order No. </b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Order No."
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Help </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="help Description"
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
        </div>
      </Collapse>
      <div className="p-2">
        <div className="d-flex mt-2">
          <Form.Label className="m-0">
            <b>Add Option:</b>
          </Form.Label>
          <Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add">Add Option</Tooltip>}
            >
              <i
                className="fa-solid fa-circle-plus ms-1"
                onClick={addDropdown}
                style={{ cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
          </Form.Label>
        </div>
        <div>
          {dropdowns?.map((dropdown, index) => (
            <div className=" mb-2 " key={index + 1}>
              <InputGroup>
                <Button
                  variant="light"
                  style={{
                    fontSize: "13px",
                    border: "1px solid #dee2e6",
                  }}
                >
                  Option {index + 1}
                </Button>
                <Form.Control
                  type="text"
                  className="checkbox-control"
                  placeholder={`Enter Option ${index + 1}`}
                  value={dropdown.value}
                  onChange={(event) => handleInputChange(index, event)}
                />
                <Button
                  variant="light"
                  style={{
                    fontSize: "13px",
                    border: "1px solid #dee2e6",
                  }}
                  onClick={() => removeDropdown(index)}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add">Delete</Tooltip>}
                  >
                    <i
                      className="fa-solid fa-trash p-1"
                      style={{ color: "red", cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                </Button>
                <Button
                  variant="light"
                  style={{
                    fontSize: "13px",
                    border: "1px solid #dee2e6",
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add">Edit</Tooltip>}
                  >
                    <i
                      class="fa-regular fa-pen-to-square p-1"
                      style={{ color: "green", cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                </Button>
              </InputGroup>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GeneralTab;
