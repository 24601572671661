import React from "react";
import { Modal, Table } from "react-bootstrap";
import Moment from "react-moment";

const AddPropertiesModal = ({ Show, Hide, Size, taskPropertiesData }) => {
    const getStatusColor = (status) => {
        if (!status) return "inherit";
        switch (status.toLowerCase()) {
            case "active":
                return "green";
            case "inactive":
                return "red";
            case "pending":
                return "orange";
            default:
                return "inherit";
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    console.log("taskPropertiesData", taskPropertiesData);

    return (
        <>
            <div>
                <Modal
                    show={Show}
                    onHide={Hide}
                    size={Size}
                    backdrop="static"
                    keyboard="false"
                >
                    <Modal.Header className="modal_closeButton">
                        <Modal.Title>Properties</Modal.Title>
                        <div onClick={Hide} className="close_modal" title="Close">
                            X
                        </div>
                    </Modal.Header>
                    <Modal.Body className="modal_bodyText">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th className="fw-bold">Attributes</th>
                                    <th className="fw-bold">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Task Name</b>
                                    </td>
                                    <td>
                                        <span className="p-1">{taskPropertiesData?.taskName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Created By </b>
                                    </td>
                                    <td>
                                        <span className="p-1">{taskPropertiesData?.createdBy}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Start Date </b>
                                    </td>
                                    <td>
                                        <span className="p-1"> {taskPropertiesData?.startDate}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>End Date </b>
                                    </td>
                                    <td>
                                        <span className="p-1"> {taskPropertiesData?.endDate}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Status :</b>
                                    </td>
                                    <td style={{ color: getStatusColor(taskPropertiesData?.status) }}>
                                        <span className="p-1">
                                            {capitalizeFirstLetter(taskPropertiesData?.status || '')}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Description :</b>
                                    </td>
                                    <td>
                                        <span className="p-1">{taskPropertiesData?.description}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default AddPropertiesModal;


