import React, { useEffect, useState } from 'react'
import { userServices } from '../APIServices/userServices';
import { NoDataFoundTable, SpinerDataLoader } from '../Hooks/NoDataFoundTable';
import Paginations from '../Hooks/Paginations';
import { Table } from 'react-bootstrap';

const UserProjectList = ({ selectedRow, projectId, setProjectId }) => {
    const [projectStudyData, setProjectStudyData] = useState([]);
    const [projectID, setProjectID] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleRowClick = (id) => {
        setProjectId(id);
    };

    const getAllProjectByStudyId = async () => {
        let data = await userServices.getAllProjectByStudyId(selectedRow); // project listing user side
        setProjectStudyData(data?.data);
        setLoading(false)
    };

    useEffect(() => {
        getAllProjectByStudyId();
    }, [selectedRow]);

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = projectStudyData?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(projectStudyData?.length / itemsPerPage);

    return (
        <>
            {loading ? (
                <SpinerDataLoader />
            ) : result?.length > 0 ? (
                <>
                    <div className="team_table overflow-auto"
                        style={{ height: "calc(100vh - 130px)" }}
                    >
                        <Table id="resizable-table"
                            className="custom-table"
                            striped
                            border
                            hover>
                            <thead>
                                <tr style={{ cursor: "pointer" }}>
                                    <th style={{ width: 68 }} className="text-center">
                                        Sr.
                                    </th>
                                    <th style={{ width: 420 }} >Project Name</th>
                                    <th style={{ width: 420 }} >Project ID</th>
                                    <th className="text-center" style={{ width: 220 }} >View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectStudyData.map((item, index) => (
                                    <tr key={index} className={projectId === item.projectID ? "selected" : ""}
                                        style={{ cursor: "pointer" }}>
                                        <td className="text-center">{startIndex + index + 1}</td>
                                        <td>{item.projectName}</td>
                                        <td>{item.projectID}</td>
                                        <td className="button_type text-center">
                                            <button
                                                className="p-1"
                                                style={{
                                                    fontSize: 12,
                                                    borderRadius: 3,
                                                    padding: "2px 8px",
                                                }}
                                                onClick={() => handleRowClick(item.projectID)}
                                            >
                                                View Assign Study
                                            </button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div>
                </>
            ) : (
                <NoDataFoundTable MSG={"User ProjectList Is Not Found"} />
            )}
            <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={projectStudyData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}

            />
        </>
    )
}

export default UserProjectList