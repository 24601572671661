import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithBody,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const quotationServices = {
  addPhase,
  getAllQServices,
  addPhaseService,
  getAllPhase,
  deletePhase,
  updatePhase,
  addQuatation,
  updateQuatation,
  getAllQuatation,
  deleteQuatation,
  getActivePhase,
  getAllPhaseService,
  updatePhaseService,
  addQuatation,
  getDataForQuatationCost,
  getQuatationCost,
  getPhaseIdByWorkflowId,
};

//addPhase
async function addPhase(userData) {
  const response = await fetch(
    `${config.API_URL}addPhase`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// getActiveQServices
async function getActivePhase() {
  const response = await fetch(
    `${config.API_URL}getActivePhase`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getAllQServices
async function getAllQServices() {
  const response = await fetch(
    `${config.API_URL}getAllQServices`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//addPhaseService
async function addPhaseService(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addPhaseService/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getAllPhase
async function getAllPhase() {
  const response = await fetch(
    `${config.API_URL}getAllPhase`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getAllPhaseService
async function getAllPhaseService(workFlowName) {
  const response = await fetch(
    `${config.API_URL}getAllPhaseService/${workFlowName}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//addQuatation
async function addQuatation(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addQuatation/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//updateQuatation
async function updateQuatation(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateQuatation/${_id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//updatePhaseService
async function updatePhaseService(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updatePhaseService/${_id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getAllQuatation
async function getAllQuatation() {
  const response = await fetch(
    `${config.API_URL}getAllQuatation`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//deleteQuatation
async function deleteQuatation(_id) {
  const response = await fetch(
    `${config.API_URL}deleteQuatation/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//deletePhase
async function deletePhase(_id) {
  const response = await fetch(
    `${config.API_URL}deletePhase/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//updatePhase
async function updatePhase(id, userData) {
  const response = await fetch(
    `${config.API_URL}updatePhase/${id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//delete deletePhase
// async function deletePhase(_id) {
//   const response = await fetch(
//     `${config.API_URL}deletePhase/${_id}`,
//     headersWithAuthWithoutBody("DELETE", headers)
//   );
//   const data = await response.json();
//   await handleResponse(data);
//   return data;
// }

//get data for quatations Cost
async function getDataForQuatationCost(_id) {
  const response = await fetch(
    `${config.API_URL}getDataForQuatationCost/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get quotations cost
async function getQuatationCost(userData) {
  const response = await fetch(
    `${config.API_URL}getQuatationCost`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// getPhaseIdByWorkflowId
async function getPhaseIdByWorkflowId(workFlowId) {
  const response = await fetch(
    `${config.API_URL}getPhaseIdByWorkflowId/${workFlowId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
