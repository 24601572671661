import React, { useState } from "react";
import { Card, Collapse, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const GeneralTab = ({
  label,
  setLabel,
  color,
  setColor,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  required,
  setRequired,
  customize,
  setCustomize,
  orderNo,
  setOrderNo,
  help,
  setHelp,
  formate,
  setFormate,
  fileUpload,
  setFileUpload,
}) => {
  const [open, setOpen] = useState(false);

  const formatToMimeType = {
    ".txt": "text/plain",
    ".word":
      "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".pdf": "application/pdf",
    ".excel":
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".csv": "text/csv",
    ".xpt": "application/x-sas-data",
    ".json": "application/json",
    ".ppt":
      "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      setFileUpload(fileName);
      localStorage.setItem("uploadedFileName", fileName);
    }
  };

  const handleFormatChange = (e) => {
    setFormate(e.target.value);
  };

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };

  return (
    <>
      <div className="p-2 pb-0">
        <Form.Group>
          <div className="hstack justify-content-between">
            <Form.Label>
              <b>Label</b>
            </Form.Label>
            {label && (
              <Form.Check
                type="switch"
                label="Customize Label"
                onChange={setCustomization}
                checked={customize}
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Group>
      </div>
      <Collapse in={customize}>
        <div className="p-2">
          <Card className="toogle-card shadow-sm border">
            <div className="hstack border-bottom">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
              >
                <div className="date-icon">
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bold"> Text Bold</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsBold(!isBold)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-bold ${isBold ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsItalic(!isItalic)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-italic ${isItalic ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
            </div>
            <div className="p-2">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  <b>Output</b>
                </Form.Label>
                <Card className="border h-1">
                  <Card.Body>
                    <Card.Text
                      className="text-center"
                      style={{
                        color,
                        fontWeight: isBold ? "bold" : "normal",
                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {label || "Output Result"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Check
                className="requird_form_check mb-2"
                type="switch"
                aria-label="option 1"
                checked={required}
                onChange={(e) => setRequired(e.target.checked)}
                label="Required"
              />
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Order no</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                  placeholder="Enter Order no"
                />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Format</b>
                </Form.Label>
                <Form.Select value={formate} onChange={handleFormatChange}>
                  <option value="">Select</option>
                  <option value=".txt">.txt</option>
                  <option value=".word">.word</option>
                  <option value=".pdf">.pdf</option>
                  <option value=".excel">.excel</option>
                  <option value=".csv">.csv</option>
                  <option value=".xpt">.xpt</option>
                  <option value=".json">.json</option>
                  <option value=".ppt">.ppt</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Upload File </b>
                </Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Upload File"
                  accept={formatToMimeType[formate]}
                  onChange={handleFileChange}
                  disabled={!formate}
                />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Help </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Help Description"
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
        </div>
      </Collapse>
    </>
  );
};

export default GeneralTab;
