import React, {useState } from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ReactSelect from "react-select";

const TableWorkflowOffcanvas = ({ Show, Hide}) => {
  const [activeTab, setActiveTab] = useState("comments");
  const [headerTitle, setHeaderTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [options, setOptions] = useState([]);
  const [description, setDescription] = useState("");
  const [comments, setComments] = useState("");
  const [loader, setLoader] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [dropdowns, setDropdowns] = useState([]);
  const [status, setStatus] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const addDropdown = () => {
    setDropdowns([...dropdowns, { id: uuidv4(), value: "" }]);
  };

  const handleInputChange = (index, event) => {
    const newDropdowns = dropdowns.map((dropdown, i) => {
      if (i === index) {
        return { ...dropdown, value: event.target.value };
      }
      return dropdown;
    });
    setDropdowns(newDropdowns);
  };

  const removeDropdown = (index) => {
    setDropdowns(dropdowns.filter((_, i) => i !== index));
  };
  const adminOptions = [
    { value: "admin1", label: "Admin 1" },
    { value: "admin2", label: "Admin 2" },
    { value: "admin3", label: "Admin 3" },
    { value: "admin4", label: "Admin 4" },
    { value: "admin5", label: "Admin 5" },
  ];

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black";
    }
  };

  const selectStyle = {
    color: getStatusColor(status),
  };

  const handleCheck = (templateId) => {
    setCheckedState((prevStates) =>
      prevStates?.map((item) =>
        item.templateId === templateId
          ? { ...item, required: !item.required }
          : item
      )
    );
  };

  const handleChangeM = (selected) => {
    setSelectedOptions(selected);
  };

  const clearFormData = () => {
    Hide();
    setHeaderTitle("");
    setStartDate("");
    setEndDate("");
    setStatus("");
    setDescription("");
    setComments("");
    setLoader("");
  };

  const commentsHere = () => (
    <>
      <div className="pt-3 ms-2 hstack">
        <div className="offcanvas-messageName-icon">
          <span>S.G</span>
        </div>
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
          Siddhant Gautam
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
          }}
        >
          created this task <b>.</b>
        </div>
        <div
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
          }}
        >
          3 hours ago
        </div>
      </div>
      <div className="offcanvas-commentCheck hstack">
        <div className="offcanvas-commentCheck-icon">
          <i className="fa-solid fa-check"></i>
        </div>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#58a182",
          }}
        >
          Siddhant Gautam
        </span>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
            color: "#58a182",
          }}
        >
          created this task <b>.</b>
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
          }}
        >
          3 hours ago
        </div>
      </div>
    </>
  );

  const activityHere = () => (
    <>
      <div className="pt-3 ms-2 hstack">
        <div className="offcanvas-messageName-icon">
          <span>S.G</span>
        </div>
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
          Siddhant Gautam
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
          }}
        >
          created this task <b>.</b>
        </div>
        <div
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
          }}
        >
          3 hours ago
        </div>
      </div>
      <div className="d-flex">
        <span
          style={{
            fontSize: "13px",
            fontWeight: "bolder",
            marginLeft: "45px",
            wordSpacing: "2px",
            paddingRight: "3px",
          }}
        >
          Siddhant Gautam added to
        </span>
      </div>
      <div className="offcanvas-commentCheck hstack">
        <div className="offcanvas-commentCheck-icon">
          <i className="fa-solid fa-check"></i>
        </div>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#58a182",
          }}
        >
          Siddhant Gautam
        </span>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
            color: "#58a182",
          }}
        >
          created this task <b>.</b>
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
            letterSpacing: "1px",
          }}
        >
          3 hours ago
        </div>
      </div>
      <div className="d-flex">
        <span
          style={{
            fontSize: "13px",
            fontWeight: "bolder",
            marginLeft: "45px",
            wordSpacing: "2px",
            paddingRight: "3px",
          }}
        >
          Siddhant Gautam assign to you.
        </span>
      </div>
    </>
  );

  return (
    <>
      <div className="milestonemodal_Font">
        <Offcanvas
          show={Show}
          onHide={Hide}
          placement="end"
          style={{ width: "660px" }}
        >
          <Offcanvas.Header className="offcanvas_header">
            <Offcanvas.Title>Task 1</Offcanvas.Title>
            <div className="hstack gap-3">
              <div
                onClick={() => clearFormData()}
                className="offcanvas_Close"
                title="Close"
              >
                X
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <div className="px-3 pt-2">
              <Container fluid>
                <Row>
                  <Col md={6} className="p-1 mb-2">
                    <Form.Group>
                      <Form.Label>
                        <b>Start Date :</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Select Date"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="p-1 mb-2">
                    <Form.Group>
                      <Form.Label>
                        <b>End Date :</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Select Date"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="p-1 mb-2">
                    <Form.Group>
                      <Form.Label>
                        <b>Status :</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        value={status}
                        style={selectStyle}
                        onChange={handleChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="pending">Pending</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="p-1 mb-2">
                    <Form.Group>
                      <Form.Label>
                        <b>Assign To :</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      {/* <Form.Select
                        onChange={(e) => setAssign(e.target.value)}
                        value={assign}
                      >
                        <option value="">Select</option>
                        <option value=".admin1">Admin 1</option>
                        <option value=".admin2">Admin 2</option>
                        <option value=".admin3">Admin 3</option>
                      </Form.Select> */}
                      <Select
                        className="templates-customize"
                        classNamePrefix="select"
                        defaultValue={adminOptions[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="admin"
                        options={adminOptions}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>

              <Form.Group>
                <Form.Label>
                  <b>Templates</b>
                </Form.Label>
                <ReactSelect
                  isMulti
                  value={selectedOptions}
                  onChange={handleChangeM}
                  className="templates-customize"
                  classNamePrefix="custom-select"
                  options={options} // Pass the fetched options here
                />
              </Form.Group>
              {selectedOptions?.length > 0 && (
                <Form.Group className="mt-2">
                  <Form.Label>
                    <b>Check Required Templates</b>
                  </Form.Label>
                  {selectedOptions?.map((item) => (
                    <Form.Check
                      key={item.value}
                      type="checkbox"
                      value={item.value}
                      label={item.label}
                      checked={
                        checkedState?.find(
                          (state) => state.templateId === item.value
                        )?.required || false
                      }
                      onChange={() => handleCheck(item.value)}
                    />
                  ))}
                </Form.Group>
              )}
            </div>

            <div className="px-3 pt-2">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Description:</b>
                </Form.Label>
                <ReactQuill onChange={setDescription} value={description} />
              </Form.Group>
            </div>
            <FormGroup>
              <div className="d-flex mb-1 px-3">
                <Form.Label className="m-0">
                  <b>Add Sub Task:</b>
                </Form.Label>
                <Form.Label>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add">Add SubTask</Tooltip>}
                  >
                    <i
                      className="fa-solid fa-circle-plus ms-1"
                      onClick={addDropdown}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
              </div>

              <div className="px-3 mb-1">
                {dropdowns?.map((dropdown, index) => (
                  <div className=" mb-2 " key={index + 1}>
                    <InputGroup>
                      <InputGroup.Text> {index + 1}</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Option ${index + 1}`}
                        style={{ width: "70%" }}
                        value={headerTitle}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      <Form.Select>
                        <option value="Pending">Pending</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                      <InputGroup.Text
                        variant="light"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                        onClick={() => removeDropdown(index)}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-add">Delete</Tooltip>}
                        >
                          <i
                            className="fa-solid fa-circle-xmark p-1"
                            style={{ color: "red", cursor: "pointer" }}
                          ></i>
                        </OverlayTrigger>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                ))}
              </div>
            </FormGroup>

            <div className="p-0">
              <hr style={{ margin: "0px" }} />
              <div className="offcanvas-message mb-3">
                <div className="offcanvas-message-box hstack gap-2">
                  <div className="date-buttonchange d-flex">
                    <Button
                      className={`button-milestone-top border-end border-0 rounded-0 ${
                        activeTab === "comments" ? "active" : ""
                      }`}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        backgroundColor:
                          activeTab === "comments" ? "#6c757d" : "",
                      }}
                      variant="outline-secondary"
                      onClick={() => handleTabClick("comments")}
                    >
                      Comments
                    </Button>
                    <Button
                      className={`button-milestone-top border-end border-0 rounded-0 ${
                        activeTab === "activity" ? "active" : ""
                      }`}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        backgroundColor:
                          activeTab === "activity" ? "#6c757d" : "",
                      }}
                      variant="outline-secondary"
                      onClick={() => handleTabClick("activity")}
                    >
                      All Activity
                    </Button>
                  </div>
                </div>
                <hr style={{ margin: "0px" }} />
                {activeTab === "comments" && commentsHere()}
                {activeTab === "activity" && activityHere()}
              </div>
              <div className="offcanbodytwo">
                <div>
                  <div className="comments-parentClass">
                    <div className="d-flex" style={{ paddingLeft: "8px" }}>
                      <div className="offcanvas-messageName-icon pt-3">
                        <span>S.G</span>
                      </div>
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "130px", marginRight: "13px" }}
                        className="offcanvas-description mt-2"
                        onChange={(e) => setComments(e.target.value)}
                        value={comments}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Form.Group className="text-end p-2 mt-3">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-save">Apply Changes</Tooltip>}
                >
                  <Button
                    variant="outline-dark"
                    className="apply-button-templates p-1 px-3"
                  >
                    Apply
                  </Button>
                </OverlayTrigger>
              </Form.Group>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default TableWorkflowOffcanvas;
