import React from "react";
import UseToggle from "../../../Hooks/UseToggle";
import { Table } from "react-bootstrap";
import SSCOffcanvas from "../QuotatioOffcanvas/SSCOffcanvas";

const Ssc = () => {
  const { toggle1, setToggle1 } = UseToggle();

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  return (
    <div>
      <>
        <div className="hstack p-2 inputWorkFlow gap-2 px-1 border-bottom">
          <button
            onClick={setToggle1}
            title="Add Quotations"
            className="teams_button border rounded-1 fs-12"
          >
            <i className="fa-solid fa-plus text-muted mt-1"></i>
          </button>
        </div>
        <div
          className="overflow-auto m-1"
          style={{ height: "calc(100vh - 130px" }}
        >
          <Table
            id="resizable-table"
            bordered
            className="custom-table"
            striped
            border
            hover
          >
            <thead className="position-sticky" style={{ top: -1 }}>
              <tr style={{ cursor: "pointer" }}>
                <th style={{ border: "1px solid #896e6e5e", width: 66 }}>
                  Sr.
                </th>
                <th style={{ border: "1px solid #896e6e5e" }}>
                  Sample Size Calcualtion Name
                </th>
                <th style={{ border: "1px solid #896e6e5e" }}>Status</th>
                <th style={{ border: "1px solid #896e6e5e" }}>
                  Create Date | Time
                </th>
                <th style={{ border: "1px solid #896e6e5e" }}>
                  Update Date | Time
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>SSC 001</td>
                <td className="text-success"> Active</td>
                <td>20 Aug || 23-08-2024</td>
                <td>20 Aug || 23-08-2024</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>SSC 002</td>
                <td className="text-success"> Active</td>
                <td>20 Aug || 23-08-2024</td>
                <td>20 Aug || 23-08-2024</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>SSC 003</td>
                <td className="text-success"> Active</td>
                <td>20 Aug || 23-08-2024</td>
                <td>20 Aug || 23-08-2024</td>
              </tr>
              <tr>
                <td>4.</td>
                <td>SSC 004</td>
                <td className="text-success"> Active</td>
                <td>20 Aug || 23-08-2024</td>
                <td>20 Aug || 23-08-2024</td>
              </tr>
              <tr>
                <td>5.</td>
                <td>SSC 005</td>
                <td className="text-success"> Active</td>
                <td>20 Aug || 23-08-2024</td>
                <td>20 Aug || 23-08-2024</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>

      <SSCOffcanvas show={!toggle1} onHide={setToggle1} />
    </div>
  );
};

export default Ssc;
