import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
  headersWithoutAuthWithoutBody,
} from "../APIHelpers/Responses";
import axios from "axios";

export const invitedDataServices = {
  // Invited Data
  registeredUserByInvitation,
  getInvitationData,
  updateProfileImage,

  //Register
  registration,
};

// Registered Company By invitation
async function registeredUserByInvitation(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}registeredUserByInvitation`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Inivitation Details
async function getInvitationData(token) {
  const response = await fetch(
    `${config.API_URL}getInvitationData/${token}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Pm Register User
async function registration(clientData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}registration`,
    headersWithAuth("POST", clientData, headerData)
  );
  const data = await response.json();
  return data;
}

//uploadImage

async function updateProfileImage(formData) {
  const response = await axios.post(
    `${config.API_URL}updateProfileImage`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}
