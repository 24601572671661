import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import DownloadLogDataModal from "./DownloadLogDataModal";
import LogTableData from "./LogTableData";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
const LogData = ({ tableRef, onMouseDown }) => {
  const { toggle4, setToggle4 } = UseToggle();
  const [logData, setLogData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const loginActivities = async () => {
    let data = await loginActivitiesServices.loginActivities();
    setLogData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    loginActivities();
  }, []);

  return (
    <>
      <div className="hstack inputWorkFlow gap-2 px-1  border-bottom" style={{ padding: 17 }}>
        {/* <div className="filter_section hstack p-0">
          {filter ? (
            <>
              <div className="h-stack justify-content-between">
                <InputGroup>
                  <InputGroup.Text
                    onClick={() => {
                      setFilter(false);
                    }}
                    title="Cancel"
                    className="search_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-xmark text-muted"></i>
                  </InputGroup.Text>
                  <Form.Control type="search" className="search-bar" />
                  <InputGroup.Text
                    title="Search"
                    className="teams_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </>
          ) : (
            <button
              onClick={() => setFilter(true)}
              title="Filter"
              className="teams_button border rounded-1 fs-12"
            >
              <i className="fa-solid fa-filter text-muted"></i>
            </button>
          )}
        </div> */}

        {/* <button
          title="Download Log Data"
          className="teams_button border rounded-1 fs-12"
          onClick={setToggle4}
        >
          <i className="fa-solid fa-download text-muted"></i>
        </button> */}
      </div>
      <LogTableData
        logData={logData}
        loading={loading}
        onMouseDown={onMouseDown}
        tableRef={tableRef}
      />
      <DownloadLogDataModal
        Show={!toggle4}
        Hide={setToggle4}
        Size={"md"}
        Title="User Download Log Data"
        Submit={"Download Log Data"}
      />
    </>
  );
};

export default LogData;
