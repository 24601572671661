import React, { useEffect, useState } from "react";
import { Form, Offcanvas, FormGroup, Col, Spinner } from "react-bootstrap";

const SSCOffcanvas = ({ show, onHide, size }) => {
  const [randomizationName, setRandomizationName] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const clearFormData = () => {
    onHide();
    setRandomizationName("");
  };

  return (
    <Offcanvas
      show={show}
      size={size}
      onHide={onHide}
      placement="end"
      style={{ width: "540px" }}
    >
      <Offcanvas.Header className="py-2">
        <div className="hstack justify-content-between w-100 gap-3">
          <Offcanvas.Title>
            <b> Sample Size Calcualtion</b>
          </Offcanvas.Title>
          <div
            onClick={clearFormData}
            className="offcanvas_Close"
            title="Close"
            style={{ cursor: "pointer" }}
          >
            X
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column p-2">
        <div className="flex-grow-1 overflow-auto">
          <FormGroup as={Col} md={12} className="mb-2">
            <Form.Label>
              <b>SSC Name :</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter your phase name"
              onChange={(e) => setRandomizationName(e.target.value)}
              value={randomizationName}
            />
          </FormGroup>
          <div className="text-end py-3">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <button type="submit">
                  {/* {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null} */}
                  Submit
                </button>
              </div>
            </Form.Group>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SSCOffcanvas;
