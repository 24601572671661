import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const userServices = {
  getWorkflows,
  getAllWorkflowForUser,
  getAllProjectByStudyId,
  getStudyByProjectId,
  getAllTaskByWorkflowId,
};

// getWorkflow
async function getWorkflows() {
  const response = await fetch(
    `${config.API_URL}getWorkflows`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getAllWorkflowForUser
async function getAllWorkflowForUser(id) {
  const response = await fetch(
    `${config.API_URL}getAllWorkflowForUser/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getAllProjectByStudyId
async function getAllProjectByStudyId(id) {
  const response = await fetch(
    `${config.API_URL}getAllProjectByStudyId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//getStudyByProjectId
async function getStudyByProjectId(id) {
  const response = await fetch(
    `${config.API_URL}getStudyByProjectId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//getAllTaskByWorkflowId
async function getAllTaskByWorkflowId(id) {
  const response = await fetch(
    `${config.API_URL}getAllTaskByWorkflowId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
