import React from "react";

const PageRectriction403 = () => {
  return (
    <>
      <div className="container">
        <div className="lock"></div>
        <div className="message">
          <h1 className="forbidden-code">403</h1>
          <h1>Access to this page is restricted</h1>
          <p>
            Please check with the site admin if you believe this is a mistake.
          </p>
        </div>
      </div>
    </>
  );
};

export default PageRectriction403;
