
import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import QRCODE from "../../../Images/QR.png";
import { authServices } from "../../../APIServices/authServices";

const TwoFAmodal = ({ Show, Hide, Title, Submit, Size }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [OTPSent, setOTPSent] = useState(false);
  const [loader, setLoader] = useState("false")
  const [base64, setBase64] = useState("");
  const [secret, setSecret] = useState("");
  const [showQR, setShowQR] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [authType, setAuthType] = useState("");  // Add authType state
  const otpInputs = useRef([]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);
      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }
      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  console.log("otpValues", otpValues);


  const verificationForEnableTwoFAByEmail = async () => {
    setLoader(true);
    let userData = {}
    userData.otp = otpValues;
    let data = await authServices.verificationForEnableTwoFAByEmail(userData);
    if (data?.status_code === 200) {
      setLoader(false);
      setStatusMessage(data);
    }
  }

  const generateOTPforTwoFAAuth = async () => {
    setLoader(true);
    let data = await authServices.generateOTPforTwoFAAuth();
    console.log("data", data)
    setLoader(false);
    setOTPSent(true);
    setCurrentStep(4);
  };

  const generateQRforTwoFAAuthByApp = async () => {
    setLoader(true);
    const data = await authServices.generateQRforTwoFAAuthByApp();
    setLoader(false);
    setBase64(data.base64);
    setSecret(data.secret);
  };

  const twoFAverificationByEmail = async () => {
    let data = await authServices.twoFAverificationByEmail();
    if (data?.status_code === 200) {
      setOTPSent(true);
      setCurrentStep(3);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <Form.Label>
              <b>
                Authentication Type <span style={{ color: "red" }}>*</span>
              </b>
            </Form.Label>
            <Form.Select
              name="authType"
              value={authType}
              onChange={(e) => setAuthType(e.target.value)}
              placeholder="Select your Authentication Type"
              style={{ boxShadow: "none" }}
            >
              <option value="">Select</option>
              <option value="Email">By Email</option>
              <option value="Apps">By Apps</option>
            </Form.Select>
            <div className="text-end mt-2">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <button
                    type="submit"
                    onClick={() => {
                      if (authType === "Apps") {
                        setCurrentStep(3);
                      } else if (authType === "Email") {
                        setCurrentStep(5);
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </Form.Group>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="qr_imag mb-3">
              <div className="security_shield">
                <i className="fa-solid fa-shield-halved"></i>
                <p className="m-0">
                  <b>Disabled</b>
                </p>
              </div>
            </div>
            <p style={{ fontSize: "12px", textAlign: "center" }}>
              To enable Email 2FA, send OTP to the registered email address. If
              you click on Send OTP button, OTP will be automatically sent to
              your registered email address.
            </p>
            <div className="hstack gap-3 justify-content-end pt-2  d-flex">
              <div className="button_type">
                <button
                  style={{ marginRight: 5 }}
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </button>
                <button onClick={() => generateOTPforTwoFAAuth()}>OTP</button>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="qr_imag mb-3">
              <div className="security_shieldQr">
                <img src={QRCODE} alt="QR" />
                <p className="m-0"></p>
              </div>
            </div>
            <div className=" gap-3 justify-content-between pt-2 d-flex w-100">
              <div className="button_type">
                <button
                  style={{ marginRight: 41 }}
                  onClick={() => setCurrentStep(2)}
                >
                  Back
                </button>
                <button
                  // onClick={() => setCurrentStep(3)}
                  style={{ width: 250, marginRight: 37 }}
                  onClick={() => generateQRforTwoFAAuthByApp()}
                >
                  {loader && (
                    <Spinner style={{ width: 14, height: 14 }} />
                  )}{" "}
                  {base64 ? "Re-Generate QR" : "Generate QR"}
                </button>
                <button onClick={() => setCurrentStep(4)}>Next</button>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="text-center">
              <label className="small" htmlFor="inputCountry">
                Enter OTP
              </label>
              <div className="otp-input-fields justify-content-center">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="\d*"
                    maxLength="1"
                    className={`otp__digit otp__field__${index + 1}`}
                    value={value}
                    onChange={(e) => handleInputChange(index, e)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
            </div>
            <div className="hstack gap-3 justify-content-end gap-2 pt-4">
              <div className="button_type">
                <button
                  style={{ marginRight: 5 }}
                  onClick={() => setCurrentStep(3)}
                >
                  Back
                </button>
                <button onClick={() => { setCurrentStep(5); verificationForEnableTwoFAByEmail() }}>Enable</button>
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="qr__imag mb-3">
              <div className="security_shield">
                <i className="fa-solid fa-unlock"></i>
                <p className="m-0">
                  <b>Disabled</b>
                </p>
              </div>
            </div>
            <p style={{ fontSize: 12, textAlign: "center" }}>
              To enable Apps 2FA, generate QR. After generating the QR, you have
              to scan the QR with the Authenticator app, after that the OTP will
              be automatically sent to your device.
            </p>
            <div className="hstack gap-3 justify-content-end pt-2  d-flex">
              <div className="button_type">
                <button
                  style={{ marginRight: 5 }}
                  onClick={() => setCurrentStep(1)}
                >
                  Show Qr
                </button>
                <button onClick={() => setCurrentStep(2)}>Disabled</button>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="modal_closeButton">
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={Hide} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body className="modal_bodyText">
        {renderStepContent(currentStep)}
      </Modal.Body>
    </Modal>
  );
};

export default TwoFAmodal;
