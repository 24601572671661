import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Offcanvas,
  Card,
  OverlayTrigger,
  Tooltip,
  Collapse,
} from "react-bootstrap";

const HeadingTemplatesCustomization = ({
  show,
  onHide,
  size,
  id,
  type,
  sections,
  addUpdateFormById,
}) => {
  const [label, setLabel] = useState("");
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState("#000000");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [alignment, setAlignment] = useState("center");
  const [customize, setCustomize] = useState(false);

  const handleSaveData = () => {
    let headingObj = {};
    headingObj.id = id;
    headingObj.type = type;
    headingObj.customize = customize;
    if (label) {
      headingObj.label = label;
    }
    headingObj.labelStyle = {
      color: color,
      bold: isBold,
      italic: isItalic,
      alignment: alignment,
    };

    const existingIndex = sections?.findIndex((item) => item.id === id);
    if (existingIndex !== -1) {
      sections[existingIndex] = headingObj;
    } else {
      sections.push(headingObj);
    }
    window.dispatchEvent(new Event("storage"));
    onHide();
    addUpdateFormById();
  };

  useEffect(() => {
    let findField = sections?.find((item) => item.id === id);
    setLabel(findField?.label);
    setColor(findField?.labelStyle?.color);
    setIsBold(findField?.labelStyle?.bold);
    setIsItalic(findField?.labelStyle?.italic);
    setAlignment(findField?.labelStyle?.alignment);
    setCustomize(findField?.customize);
  }, [id, sections]);

  const handleAlignment = (align) => {
    setAlignment(align);
  };

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };

  return (
    <Offcanvas show={show} size={size} onHide={onHide} placement="end">
      <Offcanvas.Header>
        <div className="hstack justify-content-between w-100 gap-3">
          <Offcanvas.Title>
            <b>Heading</b>
          </Offcanvas.Title>
          <div
            onClick={onHide}
            className="offcanvas_Close"
            title="Close"
            style={{ cursor: "pointer" }}
          >
            X
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <div className="p-2 pb-0">
          <Form.Group>
            <div className="hstack justify-content-between">
              <Form.Label>
                <b> Label</b>
              </Form.Label>
              {label && (
                <Form.Check
                  type="switch"
                  label="Customize Label"
                  onChange={setCustomization}
                  checked={customize}
                />
              )}
            </div>
            <Form.Control
              type="text"
              placeholder="Enter Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </Form.Group>
        </div>
        <Collapse in={customize}>
          <div className="p-2">
            <Form.Label className="mb-1">
              <b>Customization</b>
            </Form.Label>
            <Card className="toggle-card shadow-sm border">
              <div className="pb-1">
                <div className="hstack border-bottom">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
                  >
                    <div className="date-icon">
                      <Form.Control
                        type="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                      />
                    </div>
                  </OverlayTrigger>
                  <div className="vr"></div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-bold">Text Bold</Tooltip>}
                  >
                    <div
                      className="date-icon"
                      onClick={() => setIsBold(!isBold)}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className={`fa-solid fa-bold ${
                          isBold ? "bg-body-secondary rounded-1" : ""
                        }`}
                      ></i>
                    </div>
                  </OverlayTrigger>
                  <div className="vr"></div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
                  >
                    <div
                      className="date-icon"
                      onClick={() => setIsItalic(!isItalic)}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className={`fa-solid fa-italic ${
                          isItalic ? "bg-body-secondary rounded-1" : ""
                        }`}
                      ></i>
                    </div>
                  </OverlayTrigger>
                  <div className="vr"></div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-left">Align Left</Tooltip>}
                  >
                    <div
                      className="date-icon"
                      onClick={() => handleAlignment("left")}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className={`fa-solid fa-align-left ${
                          alignment === "left"
                            ? "bg-body-secondary rounded-1"
                            : ""
                        }`}
                      ></i>
                    </div>
                  </OverlayTrigger>
                  <div className="vr"></div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-center">Align Center</Tooltip>
                    }
                  >
                    <div
                      className="date-icon"
                      onClick={() => handleAlignment("center")}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className={`fa-solid fa-align-center ${
                          alignment === "center"
                            ? "bg-body-secondary rounded-1"
                            : ""
                        }`}
                      ></i>
                    </div>
                  </OverlayTrigger>
                  <div className="vr"></div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-right">Align Right</Tooltip>}
                  >
                    <div
                      className="date-icon"
                      onClick={() => handleAlignment("right")}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className={`fa-solid fa-align-right ${
                          alignment === "right"
                            ? "bg-body-secondary rounded-1"
                            : ""
                        }`}
                      ></i>
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="p-2">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      <b>Output</b>
                    </Form.Label>
                    <Card className="border">
                      <Card.Body>
                        <Card.Text
                          style={{
                            color,
                            fontWeight: isBold ? "bold" : "normal",
                            fontStyle: isItalic ? "italic" : "normal",
                            textAlign:
                              alignment === "left"
                                ? "left"
                                : alignment === "center"
                                ? "center"
                                : alignment === "right"
                                ? "right"
                                : "left",
                          }}
                        >
                          {label || "Output Result"}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Form.Group>
                </div>
              </div>
            </Card>
          </div>
        </Collapse>
        <Form.Group className="text-end p-2">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-save">Apply Changes</Tooltip>}
          >
            <Button
              variant="outline-dark"
              className="apply-button-templates p-1 px-3"
              onClick={handleSaveData}
            >
              Apply
            </Button>
          </OverlayTrigger>
        </Form.Group>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HeadingTemplatesCustomization;
