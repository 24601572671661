import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ScaleTempleteCustomization from "./ScaleTempleteCustomization";
import UseToggle from "../../../Hooks/UseToggle";
import { v4 as uuidv4 } from "uuid";

const Scale = ({ deleteSection, section, sections, addUpdateFormById }) => {
  const [filledDesc, setFilledDesc] = useState("");
  const { toggle, setToggle } = UseToggle();
  const [scale, setScale] = useState("");
  const [formData, setFormData] = useState(section);
  const [scalebox, setScalebox] = useState([{ id: uuidv4(), value: "" }]);

  const handleDescClick = (index) => {
    const newFilledDesc = [...filledDesc];
    newFilledDesc[index] = !newFilledDesc[index];
    setFilledDesc(newFilledDesc);
  };

  const renderButtons = (filled, handleClick, prefix, formData) => {
    const totalOptions = formData?.options?.length || 1;

    return formData?.options?.map((option, index) => {
      const hue = (120 / (totalOptions - 1)) * index;
      const buttonColor = `hsl(${hue}, 100%, 50%)`;

      return (
        <button
          key={option.id}
          className={`btn btn-scale rounded-0 ${prefix}-${index + 1} ${filled[index] ? "filled" : ""
            }`}
          onClick={() => handleClick(index)}
          style={{ backgroundColor: buttonColor, color: "#fff" }}
        >
          {option.value}
        </button>
      );
    });
  };


  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section, sections]);



  return (
    <>
      <div className="position-relative" style={{ marginBottom: 25 }}>
        <div className="templatesHeading mt-2"><span style={{ color: "#fff", fontWeight: "bold", fontSize: "11px" }}>SCALE</span></div>
      </div>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between mb-2">
          <Form.Label className="mt-2 mb-0 d-flex gap-1">
            <b>
              <span
                style={{
                  fontWeight: formData?.labelStyle?.bold
                    ? formData.customize
                      ? "bold"
                      : "normal"
                    : "normal",
                  fontStyle: formData?.labelStyle?.italic
                    ? formData.customize
                      ? "italic"
                      : "normal"
                    : "normal",
                  color: formData.customize
                    ? formData?.labelStyle?.color
                    : "#000",
                }}
              >
                {formData?.label ? formData?.label : "Label"}
              </span>
              {formData?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </b>
            {formData?.help && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-header">{formData?.help}</Tooltip>
                }
              >
                <i className="fa-solid fa-circle-question text-dark"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header">Your Scale Order No.</Tooltip>}
            >
              <Form.Control
                type="text"
                disabled
                value={formData?.orderNo}
                className="templateorderform_Control"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Scale Customize</Tooltip>}
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={setToggle}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-pen-to-square px-1 py-1 text-success"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Delete Scale</Tooltip>}
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={() => deleteSection()}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-trash text-danger px-1 py-1 fs-12"></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className=" form-long_text-input mt-1">
          <div
            className="chart-scale"
            onChange={(e) => setScale(e.target.value)}
          >
            {renderButtons(filledDesc, handleDescClick, "btn-scale-desc", formData)}
          </div>
        </div>
      </div>


      <ScaleTempleteCustomization
        show={!toggle}
        onHide={setToggle}
        Title="Scale"
        id={formData?.id}
        type={formData?.type}
        addUpdateFormById={addUpdateFormById}
        sections={sections}
        scalebox={scalebox}
        setScalebox={setScalebox}

      />
    </>
  );
};

export default Scale;
