import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import SignImage from "../../Images/loginimage.png";
import { NavLink } from "react-router-dom";
import { invitedDataServices } from "../../APIServices/inviteAndRegisterServices";
import { addressServices } from "../../APIServices/addressServices";
import SignupSuccess from "./SignupSuccess";

const SignUp = () => {
  const [companyName, setCompanyName] = useState("");
  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [officePhoneNumber, setOfficePhoneNumber] = useState("");
  const [city, setCity] = useState([]);
  const [city_id, setCityID] = useState("");
  const [state, setState] = useState([]);
  const [state_id, setStateID] = useState("");
  const [Country, setCountry] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountry(data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setState(data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCity(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let clientData = {};
    clientData.companyName = companyName;
    clientData.shortName = shortName;
    clientData.website = website;
    clientData.Address = address;
    clientData.email = email;
    clientData.officePhoneNumber = officePhoneNumber;
    clientData.City = city_id;
    clientData.State = state_id;
    clientData.Country = country_id;
    clientData.zipCode = zipCode;
    let data = await invitedDataServices.registration(clientData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      setCompanyName("");
      setShortName("");
      setWebsite("");
      setEmail("");
      setOfficePhoneNumber("");
      setAddress("");
      setZipCode("");
      setCityID("");
      setStateID("");
      setCountryID("");
      setStatusCode(true);
    }
  };
  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits");
    }
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email");
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      {statusCode ? (
        <SignupSuccess />
      ) : (
        <Container fluid>
          <Row>
            <Col md={7} className="p-0">
              <div className="loginimg">
                <img src={SignImage} alt="loginImage" />
              </div>
            </Col>
            <Col md={5} className="loginsection_two">
              <div className="loginside p-5">
                <div className="shadow p-5 rounded">
                  <h5 className="text-center mb-3">
                    <b>Sign Up</b>
                  </h5>
                  <Row>
                    <Form.Group as={Col} md={6} className="mb-2">
                      <Form.Label>
                        <b>Company Name</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your First Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-2">
                      <Form.Label>
                        <b>Short Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Middle Name"
                        onChange={(e) => setShortName(e.target.value)}
                        value={shortName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-2">
                      <Form.Label>
                        <b>WebSite</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Last Name"
                        onChange={(e) => setWebsite(e.target.value)}
                        value={website}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label>
                        Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          validateEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label>
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Phone Number"
                        onChange={(e) => {
                          setOfficePhoneNumber(e.target.value);
                          validatePhoneNumber(e.target.value);
                        }}
                        value={officePhoneNumber}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter your address"
                        style={{ height: "29px" }}
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label>
                        Country <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          setCountryID(e.target.value);
                          getStates(e.target.value);
                        }}
                        value={country_id}
                      >
                        <option>-Select-</option>
                        {Country?.length > 0 &&
                          Country.map((item, key) => (
                            <option value={item.id} key={key} title={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label>
                        State <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          setStateID(e.target.value);
                          getCities(e.target.value);
                        }}
                        value={state_id}
                      >
                        <option>-select-</option>
                        {state.length > 0 &&
                          state.map((item, key) => (
                            <option value={item.id} key={key} title={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label>
                        City <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => setCityID(e.target.value)}
                        value={city_id}
                      >
                        <option>-select-</option>
                        {city.length > 0 &&
                          city.map((item, key) => (
                            <option value={item.id} key={key}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label>
                        Zip Code <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Enter Postal Code"
                        onChange={(e) => setZipCode(e.target.value)}
                        value={zipCode}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-2">
                      <div className="button_type">
                        <button
                          type="submit"
                          className="loginButton w-100 m-auto mt-2"
                          disabled={
                            !companyName ||
                            !address ||
                            !officePhoneNumber ||
                            !email ||
                            !city_id ||
                            !state_id ||
                            !country_id ||
                            !zipCode ||
                            emailError ||
                            phoneError
                          }
                          onClick={handleSubmit}
                        >
                          {loader ? (
                            <Spinner
                              animation="border"
                              style={{ width: 15, height: 15 }}
                              className="me-2"
                            />
                          ) : null}
                          SignUp
                        </button>
                      </div>

                    </Form.Group>
                    <Form.Group as={Col} md="12">
                      <div className="d-flex justify-content-between">
                        {emailError || phoneError ? (
                          <div>
                            <p className="message m-0" style={{ color: "red" }}>
                              {emailError}
                            </p>
                            <p className="message m-0" style={{ color: "red" }}>
                              {phoneError}
                            </p>
                          </div>
                        ) : (
                          <p
                            className="message"
                            style={
                              statusMessage?.statusCode === 200
                                ? { color: "green" }
                                : { color: "red" }
                            }
                          >
                            {statusMessage?.customMessage}
                          </p>
                        )}
                        <p className="message" title="Click for Login Panel">
                          <NavLink to="/">
                            <b>Already have Account? Login</b>
                          </NavLink>
                        </p>
                      </div>
                    </Form.Group>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default SignUp;
