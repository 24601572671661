import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { userServices } from "../APIServices/userServices";
import { NoDataFoundTable, SpinerDataLoader } from "../Hooks/NoDataFoundTable";
import Paginations from "../Hooks/Paginations";

const UserStudyList = ({ projectId }) => {
    const [studyList, setStudyList] = useState([]);
    const [userStudyId, setUserStudyId] = useState("");
    const [loader, setLoader] = useState(true);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "active":
                return "green";
            case "inactive":
                return "red";
            case "pending":
                return "orange";
            default:
                return "inherit";
        }
    };

    const handleRowClick = (id) => {
        setUserStudyId(id);
    };

    const getStudyByProjectId = async () => {
        let data = await userServices.getStudyByProjectId(projectId);
        setStudyList(data?.data);
        setLoader(false);
    };

    const handleLoginClick = (studyID) => {
        localStorage.setItem("loginType", "study");
        window.location.href = `/study/${studyID}`;
    };
    console.log("studyList", studyList);

    useEffect(() => {
        getStudyByProjectId();
    }, [projectId]);

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = studyList?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(studyList?.length / itemsPerPage);

    return (
        <>
            {loader ? (
                <SpinerDataLoader />
            ) : result?.length > 0 ? (
                <div className="table-container overflow-auto m-1">
                    <Table
                        id="resizable-table"
                        bordered
                        className="custom-table"
                        striped
                        hover
                    >
                        <thead className="position-sticky" style={{ top: -1 }}>
                            <tr style={{ cursor: "pointer" }}>
                                <th style={{ width: 42 }}>Sr.</th>
                                <th>Company Name</th>
                                <th>Project Name</th>
                                <th>Study Name</th>
                                <th>Status</th>
                                <th style={{ width: 186 }} className="text-center">Login Assign Workflow</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result?.map((item, index) => (
                                <tr
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    // className={userStudyId === item.projectID ? "selected" : ""}
                                    onClick={() => handleRowClick(item.projectID)}
                                >
                                    <td>{startIndex + index + 1}</td>
                                    <td>{item.companyName}</td>
                                    <td>{item.projectName}</td>
                                    <td>{item.studyName}</td>
                                    <td>
                                        <div
                                            style={{ color: getStatusColor(item.status), fontSize: 12, textTransform: 'capitalize' }}
                                        >
                                            {item.status}
                                        </div>
                                    </td>
                                    <td className="button_type text-center">
                                        <button
                                            type="submit"
                                            style={{
                                                fontSize: 12,
                                                borderRadius: 3,
                                                padding: "2px 8px",
                                            }}
                                            onClick={() => handleLoginClick(item.studyID)}
                                        >
                                            Login
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <NoDataFoundTable MSG={"No User Study Data Found"} />
            )}

            <div>
                <Paginations
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={studyList?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                />
            </div>
        </>
    );
};

export default UserStudyList;
