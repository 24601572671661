import React from "react";
import { Outlet, Navigate } from "react-router";
import { getStore } from "../Utils/UniversalFunction";
import { rolesType } from "../config/rolesType";
import PageRectriction403 from "../Pages/PageRestricton/PageRectriction403";

const auth = getStore("token");
const { pmuser, pmclient } = rolesType;

const currentUrl = window.location.href;
const splitUrl = currentUrl?.split("/");
const currentPath = splitUrl[3];

const role = JSON.parse(localStorage.getItem("role"));

const DashboardPermission = ({
  twoFA,
  authType,
  email,
  currentUserData,
  passwordUpdate,
}) => {
  if (auth) {
    return twoFA ? (
      <>
        {/* <OtpVerification
          authType={authType}
          currentUserData={currentUserData}
          email={email}
        /> */}
      </>
    ) : (
      // ) : !passwordUpdate ? (
      //   <ChangePassword currentUserData={currentUserData} />
      <Outlet to="/analytics" />
    );
  } else {
    return (window.location = "/");
  }
};

const UserPermission = () => {
  if (auth) {
    // return twoFA ? (
    //   <TwoFAmodal />
    // ) : !passwordUpdate ? (
    //   <ChangePassword />
    // ) :
    pmuser === role ? <Outlet to="/analytics" /> : <PageRectriction403 />;
  } else {
    return (window.location = "/");
  }
};

const ClientPermission = () => {
  if (auth) {
    // return twoFA ? (
    //   <TwoFAmodal />
    // ) : !passwordUpdate ? (
    //   <ChangePassword />
    // ) :
    pmclient === role ? <Outlet to="/analytics" /> : <PageRectriction403 />;
  } else {
    return (window.location = "/");
  }
};

const LoginPermission = () => {
  if (!auth) {
    if (currentPath === "login") {
      return <Outlet to="/login" />;
    } else if (currentPath === "forget-password") {
      return <Outlet to="/forget-password" />;
    } else if (currentPath === "otp-verify") {
      return <Outlet to="/otp-verify" />;
    } else if (currentPath === "change-password") {
      return <Outlet to="/change-password" />;
    } else if (currentPath.split("=")[0] === "resetPassword?token") {
      return <Outlet to="/resetPassword" />;
    } else if (currentPath === "sign-up") {
      return <Outlet to="/sign-up" />;
    } else if (currentPath === "") {
      return <Outlet to="/" />;
    }
  } else {
    if (
      currentPath === "" ||
      currentPath === "login" ||
      currentPath === "forget-password" ||
      currentPath === "otp-verify" ||
      currentPath === "change-password" ||
      currentPath === "sign-up"
    ) {
      return <Navigate to="/analytics" />;
    }
  }
};

export const Permission = {
  LoginPermission,
  DashboardPermission,
  UserPermission,
  ClientPermission,
};
