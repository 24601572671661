import React from "react";
import { Form } from "react-bootstrap";

const AdvanceTab = ({ minValue, setMinValue, maxValue, setMaxValue }) => {
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Min Value </b>
          </Form.Label>
          <Form.Control
            type="time"
            defaultValue={minValue}
            onChange={(e) => setMinValue(e.target.value)}
            placeholder="Enter Label"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Max Value </b>
          </Form.Label>
          <Form.Control
            type="time"
            defaultValue={maxValue}
            onChange={(e) => setMaxValue(e.target.value)}
            placeholder="Enter Label"
          />
        </Form.Group>
      </div>
    </>
  );
};

export default AdvanceTab;
