import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import WorkflowList from "./WorkflowList";
import { NavLink } from "react-router-dom";
import UseToggle from "../../Hooks/UseToggle";
import WorkflowPipline from "./WorkflowPipline";
import AddWorkFlowModal from "./AddWorkFlowModal";
import { Button, Form, InputGroup, Placeholder } from "react-bootstrap";
import AddPropertiesModal from "./AddPropertiesModal";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Component/RightClick/RightClick";
import { workflowServices } from "../../APIServices/workflowServices";
import WorkflowCalender from "./WorkflowCalender";

function WorkflowSidebar({
  isWorkflowCollapsed,
  roleType,
  teamsData,
  workflowList,
  getWorkflows,
  setWorkflowList,
  setWorkFlowId,
  workFlowId,
  userworkflowid,
  studyId,
  clientStudyworkFlowId,
  currentUserId,
  tableRef,
  userCurrrentData
}) {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [selectedTab, setSelectedTab] = useState("WorkflowPipline");
  const [templates, setTemplates] = useState([]);
  const [workflowData, setWorkflowData] = useState("");
  const [name, setName] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [visibleItems, setVisibleItems] = useState(16);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const loginType = localStorage.getItem("loginType");

  const updateWorkflow = (id, name, templates) => {
    setToggle();
    setWorkFlowId(id);
    setName(name);
    setTemplates(templates);
  };

  const handleViewProperty = (item) => {
    setToggle1();
    setWorkflowData(item);
  };

  const deleteWorkflow = async (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete WorkFlow!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await workflowServices.deleteWorkflow(Id);
        if (data?.status_code === 200) {
          getWorkflows();
        }
      }
    });
  };

  const updateWorkflowStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Workflow Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await workflowServices.updateWorkflow(id, data);
        if (results?.status_code === 200) {
          getWorkflows();
        }
      }
    });
  };

  const searchWorkflow = async (name) => {
    setListLoader(true);
    let data = await workflowServices.searchWorkflows(name);
    if (!name) {
      getWorkflows();
    } else {
      setWorkflowList(data.data);
      setListLoader(false);
    }
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  return (
    <>
      <div className="d-flex second_sidebaar">
        {!studyId && (
          <div
            style={{ width: isWorkflowCollapsed ? "0%" : "18%" }}
            className={isWorkflowCollapsed ? "smoothCollapse1" : "smoothCollapse"}
          >
            <div className="sidebar_section border-end">
              <div className="hstack inputWorkFlowsection border-bottom">
                <Form.Control
                  type="search"
                  onChange={(e) => searchWorkflow(e.target.value)}
                  placeholder="Search here..."
                  className="addworkflowcrontol rounded-1 border-0"
                />
                <button
                  title="Add New Workflow"
                  className="teams_button fs-12 border-0 border-top"
                  style={{ height: 34, width: 31, padding: 10 }}
                  onClick={setToggle}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>

              <div className="workflow_text w-100 ">
                <div className="sid_li">
                  <NavLink to={"#"}>
                    <div
                      className="overflow-auto"
                      style={{ height: "calc(100vh - 128px)" }}
                    >
                      {workflowList?.length > 0 ? (
                        workflowList.slice(0, visibleItems).map((item, index) => (
                          <div
                            key={index}
                            className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${item?._id?.$oid === workFlowId
                              ? "sidebarMenuActive"
                              : ""
                              }`}
                            onClick={() => setWorkFlowId(item?._id?.$oid)}
                          >
                            <ContextMenuTrigger
                              id={`workflowMenu_${index}`}
                              collect={() => ({
                                id: `workflowMenu_${index}` + 1,
                              })}
                            >
                              <div
                                className={`${item?.status === "inactive" ? "text-muted" : ""
                                  }`}
                              >
                                {listLoader ? (
                                  <Placeholder animation="glow">
                                    <Placeholder xs={1} className="" />
                                    <Placeholder xs={11} />
                                  </Placeholder>
                                ) : (
                                  <div>
                                    <i class="fa-brands fa-wordpress me-2"></i>
                                    <span style={{ fontSize: "13px" }}>
                                      {item.name}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </ContextMenuTrigger>
                            <RightClick
                              ContextId={`workflowMenu_${index}`}
                              Menu1
                              MenuName1={
                                item.status === "active" ? "Inctive" : "Active"
                              }
                              icons1={
                                <i
                                  class={`fa-solid fa-circle ${item.status === "active"
                                    ? "text-danger"
                                    : "text-success"
                                    }`}
                                ></i>
                              }
                              className1={"text-white"}
                              handleClick1={() =>
                                updateWorkflowStatus(
                                  item?._id?.$oid,
                                  item?.status === "active"
                                    ? "inactive"
                                    : "active"
                                )
                              }
                              Menu2
                              MenuName2={"Update"}
                              icons2={
                                <i class="fa-solid fa-pen-to-square text-white"></i>
                              }
                              className2={"text-white"}
                              handleClick2={() =>
                                updateWorkflow(
                                  item?._id?.$oid,
                                  item?.name,
                                  item?.templates
                                )
                              }
                              Menu3
                              MenuName3={"Properties"}
                              icons3={
                                <i class="fa-solid fa-circle-info text-white"></i>
                              }
                              className3={"text-white"}
                              handleClick3={() => handleViewProperty(item)}
                              Menu4
                              MenuName4={"Delete"}
                              icons4={
                                <i class="fa-solid fa-trash-can text-danger"></i>
                              }
                              className4={"text-white "}
                              handleClick4={() => deleteWorkflow(item?._id?.$oid)}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="p-2 workflowMenuList">
                          <i class="fa-regular fa-face-frown ms-2 me-2"></i>
                          <span style={{ fontSize: "13px" }}>
                            Workflow not found!
                          </span>
                        </div>
                      )}
                      {visibleItems < workflowList?.length && (
                        <div
                          onClick={loadMore}
                          className="p-2 workflowMenuList sidebarMenuActive position-sticky bottom-0"
                        >
                          <i class="fa-solid fa-hourglass-end ms-2 me-2"></i>
                          <span style={{ fontSize: "13px" }}>Load More...</span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="sidebar_button_2  left-toc-collapsed"
          style={{ width: isWorkflowCollapsed || studyId ? "100%" : "82%" }}
        >
          <div className="d-flex border-bottom">
            <div
              className=""
              style={{ width: 67, paddingLeft: 10 }}
            >
              <div
                className={`workflowheader pt-2 ${selectedTab === "WorkflowPipline" ? "active border-bottom-active fw-bold" : "text-muted"}`}
                onClick={() => setSelectedTab("WorkflowPipline")}
                style={{ cursor: "pointer" }}
              >
                <i
                  className={`fa-brands fa-flipboard fs-13 me-1 ${selectedTab === "WorkflowPipline" ? "fw-bold" : "text-muted"}`}
                ></i>
                <span className={`fs-13 ${selectedTab === "WorkflowPipline" ? "fw-bold" : "text-muted"}`}>Board</span>
              </div>
            </div>

            <div className="" style={{ width: 67, paddingLeft: 26 }}>
              <div
                className={`workflowheader pt-2 ${selectedTab === "WorkflowList" ? "active border-bottom-active fw-bold" : "text-muted"
                  }`}
                onClick={() => setSelectedTab("WorkflowList")}
                style={{ cursor: "pointer" }}
              >
                <i
                  className={`fa-solid fa-list-check fs-13 me-1 ${selectedTab === "WorkflowList" ? "fw-bold" : "text-muted"
                    }`}
                ></i> <span className={`fs-13 ${selectedTab === "WorkflowList" ? "fw-bold" : "text-muted"}`}>List</span>
              </div>
            </div>

            {loginType === "study" && (
              <div className="" style={{ width: 67, paddingLeft: 26 }}>
                <div
                  className={`workflowheader pt-2 ${selectedTab === "WorkflowCalender" ? "active border-bottom-active fw-bold" : "text-muted"
                    }`}
                  onClick={() => setSelectedTab("WorkflowCalender")}
                  style={{ cursor: "pointer", borderbottom: "1px solid red" }}
                >
                  <i
                    className={`fa-solid fa-calendar-days fs-13 me-1 ${selectedTab === "WorkflowCalender" ? "fw-bold" : "text-muted"
                      }`}
                  ></i> <span className={`fs-13 ${selectedTab === "WorkflowCalender" ? "fw-bold" : "text-muted"}`}>Cal</span>
                </div>
              </div>
            )}
          </div>


          {selectedTab === "WorkflowPipline" && (
            <WorkflowPipline
              setWorkFlowId={setWorkFlowId}
              workFlowId={workFlowId}
              roleType={roleType}
              userworkflowid={userworkflowid}
              teamsData={teamsData}
              clientStudyworkFlowId={clientStudyworkFlowId}
              userCurrrentData={userCurrrentData}
            />
          )}
          {selectedTab === "WorkflowList" && <WorkflowList workFlowId={workFlowId} tableRef={tableRef} />}
          {loginType === "study" && (
            <>
              {selectedTab === "WorkflowCalender" && <WorkflowCalender currentUserId={currentUserId} />}
            </>
          )}

        </div>
      </div>
      <AddWorkFlowModal
        Show={!toggle}
        Hide={setToggle}
        getWorkflows={getWorkflows}
        workFlowId={workFlowId}
        templates={templates}
        setWorkFlowId={setWorkFlowId}
        name={name}
        setName={setName}
        Size={"md"}
      />
      <AddPropertiesModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        workflowData={workflowData}
      />
    </>
  );
}

export default WorkflowSidebar;
