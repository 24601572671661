import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Date from "./Date/Date";
import Time from "./Time/Time";
import TextInput from "./TextInputShort/TextInputShort";
import FormRadiobox from "./FormRadiobox/FormRadiobox";
import UploadFile from "./UploadFile/UploadFile";
import FormTable from "./FormTable/FormTable";
import SignatureBox from "./SignatureBox/SignatureBox";
import TemplateOption from "./TemplateOption";
import FooterNote from "./FooterComponent/FooterNote";
import TextArea from "./TextArea/TextAreaLong";
import SelectForm from "./SelectForm/SelectForm";
import NumberInput from "./NumberInput/NumberInput";
import Heading from "./Heading/Heading";
import RatingSection from "./RatingSection/RatingSection";
import Separator from "./Separator/Separator";
import TermsConditions from "./TermsConditions/TermsConditions";
import Scale from "./Scale/Scale";
import FormCheckbox from "./FormCheckbox/FormCheckboxs";
import TemplateSidebar from "./TemplateSidebar";
import { tempelatesServices } from "../../APIServices/tempelatesServices";

const FormBuilder = ({ isCollapseTempelates }) => {
  const [sections, setSections] = useState([]);
  const [templateRowId, setTemplateRowId] = useState("");
  const [columns, setColumns] = useState([]);

  const addFormSection = (type) => {
    const id = uuidv4();
    const newSection = [{ id, type }];
    let newArray = [...sections, ...newSection];
    setSections(newArray);
  };

  const addUpdateFormById = async () => {
    let userData = {};
    userData.templateID = templateRowId;
    userData.form = sections;
    let data = await tempelatesServices.addUpdateFormById(userData);
    if (data?.status_code === 200) {
      getFormByTemplateId();
    }
  };

  console.log(">>>sections", sections);


  const updateSection = async (id, newData) => {
    const updatedSections = sections?.map((section) =>
      section.id === id ? { ...section, data: newData } : section
    );
    setSections(updatedSections);
    let userData = {};
    userData.templateID = templateRowId;
    userData.form = updatedSections;
    let data = await tempelatesServices.addUpdateFormById(userData);
    if (data?.status_code === 200) {
      getFormByTemplateId();
    }
  };

  const deleteSection = async (id) => {
    const updatedSections = sections?.filter((section) => section.id !== id);
    setSections(updatedSections);
    let userData = {};
    userData.templateID = templateRowId;
    userData.form = updatedSections;
    let data = await tempelatesServices.addUpdateFormById(userData);
    if (data?.status_code === 200) {
      getFormByTemplateId();
    }
  };

  const getFormByTemplateId = async () => {
    let data = await tempelatesServices.getFormByTemplateId(templateRowId);
    let result = data?.data;
    setSections(result?.form || []);
  };

  console.log("sections", sections);

  useEffect(() => {
    getFormByTemplateId();
  }, [templateRowId]);

  const renderSection = (section) => {
    const components = {
      heading: Heading,
      date: Date,
      time: Time,
      textinput: TextInput,
      numberinput: NumberInput,
      textarea: TextArea,
      checkbox: FormCheckbox,
      radio: FormRadiobox,
      select: SelectForm,
      uploadfile: UploadFile,
      formtable: FormTable,
      reating: RatingSection,
      separator: Separator,
      termsConditions: TermsConditions,
      scale: Scale,
      // signaturebox: SignatureBox,
      footernote: FooterNote,
    };

    const Component = components[section.type];

    if (Component) {
      return (
        <Component
          section={section}
          key={section.id}
          data={section.data}
          updateData={(newData) => updateSection(section.id, newData)}
          deleteSection={() => deleteSection(section.id)}
          sections={sections}
          addUpdateFormById={addUpdateFormById}
          setColumns={setColumns}
          columns={columns}
        />
      );
    }
    return null;
  };

  console.log("columns", columns);

  return (
    <>

      <div className="d-flex second_sidebaar">
        <div
          style={{ width: isCollapseTempelates ? "0%" : "18%" }}
          className={
            isCollapseTempelates ? "smoothCollapse1" : "smoothCollapse"
          }
        >
          <TemplateSidebar
            setTemplateRowId={setTemplateRowId}
            templateRowId={templateRowId}
          />
        </div>
        {templateRowId && (
          <div
            className="sidebar_button_2  left-toc-collapsed"
            style={{ width: isCollapseTempelates ? "100%" : "82%" }}
          >
            <Container fluid>
              <Row>
                <Col md={9} className="p-0">
                  {sections?.length > 0 ? (
                    <div className="p-4 overflow-auto"
                      style={{ height: "calc( 100vh - 86px)" }}
                    >
                      {sections?.map(renderSection)}
                    </div>
                  ) : (
                    <div
                      className="overflow-auto hstack justify-content-center"
                      style={{ height: "calc( 100vh - 86px)" }}
                    >
                      <div className="form-content text-center">
                        Drag a field from the right to this area.
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={3} className="p-0">
                  <TemplateOption addFormSection={addFormSection} />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>

    </>
  );
};

export default FormBuilder;
