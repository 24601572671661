import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { teamServices } from "../../APIServices/teamServices";
import UpdateTeamModal from "./UpdateTeamModal";
import UseToggle from "../../Hooks/UseToggle";
import Paginations from "../../Hooks/Paginations";
import Moment from "react-moment";
import { NoDataFoundTable } from "../../Hooks/NoDataFoundTable";
import TeamViewDetailsOffcanvas from "./TeamViewDetailsOffcanvas";

const TeamTableData = ({
  tableRef,
  onMouseDown,
  teamsData,
  selectedRow,
  setSelectedRow,
  getUsers,
  loading,
}) => {
  const { toggle, setToggle } = UseToggle();
  const [userDetails, setUserDetails] = useState("");
  const [userID, SetUserID] = useState("");
  const { toggle1, setToggle1 } = UseToggle();

  const changeStatus = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Team User Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await teamServices.changeStatus(_id, data);
        if (results?.status_code === 200) {
          getUsers();
        }
      }
    });
  };

  const deleteUser = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Team User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await teamServices.deleteUser(_id);
        if (results?.status_code === 200) {
          getUsers();
        }
      }
    });
  };

  const updateUser = (item) => {
    setToggle();
    setUserDetails(item);
  };

  const handleClickRow = (item) => {
    setSelectedRow(item._id.$oid);
    SetUserID(item?._id?.$oid);

  }

  const handleViwClick = (item) => {
    SetUserID(item?._id?.$oid);
    setToggle1();
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = teamsData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(teamsData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <Spinner animation="border" />
        </div>
      ) : result?.length > 0 ? (
        <>
          <div
            className="team_table overflow-auto"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <Table
              id="resizable-table"
              className="custom-table"
              ref={tableRef}
              striped
              border
              hover
            >
              <thead
                className="position-sticky bg-white px-2"
                style={{ top: -1 }}
              >
                <tr>
                  <th
                    style={{ width: 50 }}
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    Sr.
                  </th>
                  <th
                    style={{ width: 222 }}
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Full Name
                  </th>
                  <th
                    style={{ width: 219 }}
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Email
                  </th>
                  <th
                    style={{ width: 161 }}
                    onMouseDown={(e) => onMouseDown(e, 3)}
                  >
                    Gender
                  </th>
                  <th
                    style={{ width: 116 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Phone
                  </th>
                  <th
                    style={{ width: 140 }}
                    onMouseDown={(e) => onMouseDown(e, 5)}
                  >
                    Status
                  </th>
                  <th
                    style={{ width: 170 }}
                    onMouseDown={(e) => onMouseDown(e, 7)}
                  >
                    Modified Date | Time
                  </th>
                  <th style={{ width: 55, textAlign: "center" }}>View</th>
                  <th style={{ width: 55, textAlign: "center" }}>Update</th>
                  <th style={{ width: 55, textAlign: "center" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={item.id}
                    className={selectedRow === item._id.$oid ? "selected" : ""}
                    onClick={() => handleClickRow(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{startIndex + index + 1}</td>
                    <td>{item.fullName}</td>
                    <td>{item.email}</td>
                    <td><span className="p-1">
                      {capitalizeFirstLetter(item?.gender || '')}
                    </span></td>
                    <td>{item.phone}</td>
                    <td>
                      <select
                        className={`status-select ${item.status.toLowerCase()}`}
                        value={item.status}
                        onChange={(e) =>
                          changeStatus(item?._id?.$oid, e.target.value)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "0",
                          margin: "0",
                          border: "none",
                          boxSizing: "border-box",
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.updated_at}
                      </Moment>
                    </td>
                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => handleViwClick(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => updateUser(item)}
                      >
                        Update
                      </button>
                    </td>
                    <td className="text-center">
                      <span className="text-danger">
                        <i
                          className="fa-solid fa-trash"
                          onClick={() => deleteUser(item?._id?.$oid)}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={teamsData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoDataFoundTable MSG={"Teams Data Is Not Found"} />
      )}
      <UpdateTeamModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        getUsers={getUsers}
        teamsData={teamsData}
        setUserDetails={setUserDetails}
        userDetails={userDetails}
        Title="Update Team"
        Submit={"Add User"}
      />
      <TeamViewDetailsOffcanvas
        show={!toggle1}
        onHide={setToggle1}
        userID={userID}


      />
    </>
  );
};
export default TeamTableData;
