import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { loginActivitiesServices } from "../../APIServices/loginActivitiesServices";

const AnalyticsDonutChart = () => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  const fetchLoginData = async () => {
    const loginData = await loginActivitiesServices.loginActivities();
    const failedLoginData = await loginActivitiesServices.getFailLogin();

    const loginCount = loginData?.data?.length || 0;
    const failedLoginCount = failedLoginData?.data?.length || 0;
    const logoutCount = 15; // Assuming 15 is a static value or can be fetched similarly

    setChartData({
      series: [loginCount, logoutCount],
      options: {
        chart: {
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 580,
            options: {
              chart: {
                width: 200,
                height: 200,
              },
            },
          },
        ],
        labels: ["Login", "Logout"],
        colors: ["#103c5e", "#366488"],
        plotOptions: {
          pie: {
            donut: {
              size: "45%",
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    fetchLoginData();
  }, []);

  return (
    <div className="graph__title">
      <p>
        <b>Login Activities</b>
      </p>
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="donut"
          height="300"
        />
      </div>
    </div>
  );
};

export default AnalyticsDonutChart;
