import React, { useState } from 'react'
import { LiaCheckDoubleSolid, } from "react-icons/lia";

const SingleChat = ({ teamsData }) => {
    console.log("teamsData", teamsData);

    return (
        <div className="p-2">
            <div className="p-3 border shadow-sm rounded-3 mb-2">
                <div className="hstack mb-2">
                    <b>Friends</b>
                </div>
                <div
                    className="message-list"
                    style={{
                        maxHeight: '200px', // Set the desired height here
                        overflowY: 'auto'  // Enable vertical scrolling
                    }}
                >
                    {teamsData?.map((item, index) => (
                        <div className="message-container hstack justify-content-between p-2 chat_hover_effect" style={{ cursor: "pointer" }}>
                            <div className="hstack mb-2">
                                <div className="avatar-container">
                                    <div className="modal-messageName-icon">
                                        <span>
                                            {`${item?.firstName?.charAt(0).toUpperCase()}.${item?.middleName?.charAt(0).toUpperCase() || item?.lastName?.charAt(0).toUpperCase()}`}
                                        </span>
                                    </div>
                                    <span className="status-indicator"></span>
                                </div>

                                <div className="message-content ms-2">
                                    <h6 className="mb-0">{item?.fullName}</h6>
                                    <p class="text-muted mb-0" style={{ fontSize: 12 }}>I'm Busy Now.</p>
                                </div>
                            </div>
                            <div>
                                <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                <div className='text-end'>
                                    <LiaCheckDoubleSolid style={{ color: "blue" }} />
                                </div>
                            </div>

                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default SingleChat