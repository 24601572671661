import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";
import { teamServices } from "../../APIServices/teamServices";

const AddTeamModal = ({ Show, Submit, Hide, Size, Title, getUsers }) => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.Address = address;
    userData.email = email;
    userData.gender = gender;
    userData.phone = phone;
    userData.City = city_id;
    userData.State = state_id;
    userData.Country = country_id;
    userData.zipCode = postalCode;
    userData.role = "user";
    let data = await teamServices.addUsers(userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      clearFormData();
      getUsers();
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits");
    }
  };

  const isDisabled =
    !firstName ||
    !address ||
    !phone ||
    !email ||
    !city_id ||
    !state_id ||
    !country_id ||
    !postalCode ||
    emailError ||
    phoneError;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill all mandatory fields
    </Tooltip>
  );
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
      setIsEmailValid(true);
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email");
      setIsEmailValid(false);
    }
  };

  const clearFormData = () => {
    Hide();
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setGender("");
    setAddress("");
    setCityID("");
    setStateID("");
    setCountryID("");
    setPostalCode("");
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header className="modal_closeButton">
          <Modal.Title>Add Team</Modal.Title>
          <div
            onClick={() => clearFormData()}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body className="modal_bodyText">
          <Row>
            <FormGroup as={Col} md={6} className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                <b>First Name</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                className="rounded-1"
                placeholder="Enter Your First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </FormGroup>
            <FormGroup as={Col} md={6} className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                <b>Middle Name</b>
              </Form.Label>
              <Form.Control
                type="text"
                className="rounded-1"
                placeholder="Enter Your Middle Name"
                onChange={(e) => setMiddleName(e.target.value)}
                value={middleName}
              />
            </FormGroup>
            <FormGroup
              as={Col}
              md={6}
              className="mb-2"
              placeholder="Enter Your Last Name"
            >
              <Form.Label className="mb-1 fw-bold">
                <b>Last Name</b>
              </Form.Label>
              <Form.Control
                type="text"
                className="rounded-1"
                placeholder="Enter Your Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </FormGroup>
            <FormGroup as={Col} md={6} className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                <b>Email</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                className="rounded-1"
                placeholder="Enter your email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                value={email}
              />
              {emailError && (
                <p className="text-danger" style={{ marginTop: "5px" }}>
                  {emailError}
                </p>
              )}
            </FormGroup>
            <Form.Group as={Col} md={6} className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                <b>Phone</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                className="rounded-1"
                placeholder="Enter Phone Number"
                onChange={(e) => {
                  setPhone(e.target.value);
                  validatePhoneNumber(e.target.value);
                }}
                value={phone}
              />
              {phoneError && (
                <p className="text-danger" style={{ marginTop: "5px" }}>
                  {phoneError}
                </p>
              )}
            </Form.Group>
            <FormGroup as={Col} md={6} className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                <b>Gender</b>
              </Form.Label>
              <Form.Select
                className="rounded-1"
                onChange={(e) => setGender(e.target.value)}
                aria-label="Default select example"
              >
                <option>Select Gender</option>
                <option selected={gender === "male"} value="male">
                  Male
                </option>
                <option selected={gender === "female"} value="female">
                  Female
                </option>
                <option selected={gender === "other"} value="other">
                  Other
                </option>
              </Form.Select>
            </FormGroup>
            <FormGroup as={Col} md={12} className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                <b>Address</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                className="rounded-1"
                placeholder="Enter your address"
                style={{ height: 100 }}
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </FormGroup>

            <Form.Group as={Col} md="6" className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                className="rounded-1"
                onChange={(e) => {
                  setCountryID(e.target.value);
                  getStates(e.target.value);
                }}
                value={country_id}
              >
                <option>-Select-</option>
                {countryLists?.length > 0 &&
                  countryLists.map((item, key) => (
                    <option value={item.id} key={key} title={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                State <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                className="rounded-1"
                onChange={(e) => {
                  setStateID(e.target.value);
                  getCities(e.target.value);
                }}
                value={state_id}
              >
                <option>-Select-</option>
                {stateLists.length > 0 &&
                  stateLists.map((item, key) => (
                    <option value={item.id} key={key} title={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                City <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                className="rounded-1"
                onChange={(e) => setCityID(e.target.value)}
                value={city_id}
              >
                <option>-Select-</option>
                {cityLists.length > 0 &&
                  cityLists.map((item, key) => (
                    <option value={item.id} key={key}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Zip Code <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                className="rounded-1"
                type="number"
                placeholder="Enter Postal Code"
                onChange={(e) => setPostalCode(e.target.value)}
                value={postalCode}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <OverlayTrigger
                  placement="top"
                  overlay={isDisabled ? renderTooltip : <span></span>}
                >
                  <button
                    type="submit"
                    disabled={
                      !firstName ||
                      !address ||
                      !phone ||
                      !email ||
                      !city_id ||
                      !state_id ||
                      !country_id ||
                      !postalCode ||
                      !postalCode ||
                      emailError ||
                      phoneError
                    }
                    onClick={handleSubmit}
                  >
                    {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 15, height: 15 }}
                        className="me-2"
                      />
                    ) : null}
                    Submit
                  </button>
                </OverlayTrigger>
              </div>
            </Form.Group>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddTeamModal;
