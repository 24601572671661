import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import AddDesignationOffcanvas from "./AddDesignationOffcanvas";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UseToggle from "../../../Hooks/UseToggle";
import { orgServices } from "../../../APIServices/orgServices";
import Paginations from "../../../Hooks/Paginations";
import { NoDataFoundTable } from "../../../Hooks/NoDataFoundTable";

const DeginationTable = () => {
  const { toggle1, setToggle1, } = UseToggle();
  const [designationData, setDesignationData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [designationId, setDesignationId] = useState("");
  const [desinationName, setDesinationName] = useState("");

  const getDesignation = async () => {
    let data = await orgServices.getAllDesignation();
    setDesignationData(data?.data);
    setLoading(false);
  }

  const deleteDesignation = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Designation!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await orgServices.deleteDesignation(_id);
        if (results?.status_code === 200) {
          getDesignation();
        }
      }
    });
  };

  const handleUpdateClick = (item) => {
    setToggle1();
    setDesignationId(item?._id?.$oid)
    setDesinationName(item?.designationName);


  }

  const updateDesignation = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Designation Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await orgServices.updateDesignation(_id, data);
        if (results?.status_code === 200) {
          getDesignation();
        }
      }
    });
  };

  useEffect(() => {
    getDesignation();
  }, []);

  console.log("designationId", designationId);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = designationData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(designationData?.length / itemsPerPage);

  return (
    <div>
      <div className="hstack inputWorkFlow gap-2 px-1 border-bottom" style={{ padding: 6 }}>
        <button
          onClick={setToggle1}
          title="Add Designations"
          className="teams_button border rounded-1 fs-12"
        >
          <i className="fa-solid fa-plus text-muted mt-1"></i>
        </button>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <Spinner animation="border" />
        </div>
      ) : result?.length > 0 ? (
        <>
          <div
            className="team_table overflow-auto"
            style={{ height: "calc(100vh - 130px" }}
          >
            <Table
              id="resizable-table"
              bordered
              className="custom-table"
              striped
              border
              hover
            >
              <thead className="position-sticky bg-white px-2" style={{ top: -1 }}>
                <tr style={{ cursor: "pointer" }}>
                  <th style={{ border: "1px solid #896e6e5e", width: 35 }}>
                    Sr.
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 130 }}>
                    Designation Name
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 130 }}>Status</th>
                  <th style={{ border: "1px solid #896e6e5e", width: 150 }}>
                    Create Date | Time
                  </th>
                  <th style={{ border: "1px solid #896e6e5e", width: 150 }}>
                    Update Date | Time
                  </th>
                  <th style={{ width: 55, textAlign: "center" }}>Update</th>
                  <th style={{ width: 55, textAlign: "center" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={item.id}
                    className={
                      selectedRow === item._id.$oid ? "selected" : ""
                    }
                    onClick={() => setSelectedRow(item._id.$oid)}
                  >
                    <td>{startIndex + index + 1}</td>
                    <td>{item.designationName}</td>
                    <td>
                      <select
                        className={`status-select ${item.status.toLowerCase()}`}
                        value={item.status}
                        onChange={(e) =>
                          updateDesignation(item?._id?.$oid, e.target.value)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "0",
                          margin: "0",
                          border: "none",
                          boxSizing: "border-box",
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td><Moment format="DD MMM YYYY | HH:MM">
                      {item.created_at}
                    </Moment>
                    </td>
                    <td><Moment format="DD MMM YYYY | HH:MM">
                      {item.updated_at}
                    </Moment>
                    </td>

                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => handleUpdateClick(item)}
                      >
                        Update
                      </button>
                    </td>
                    <td className="text-center">
                      <span className="text-danger">
                        <i
                          className="fa-solid fa-trash"
                          onClick={() => deleteDesignation(item?._id?.$oid)}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={designationData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "80vh",
            justifyContent: "center",
          }}
        >
          <NoDataFoundTable MSG={"Designation  Table is Not Found"} />
        </div>
      )}

      <AddDesignationOffcanvas
        show={!toggle1}
        onHide={setToggle1}
        getDesignation={getDesignation}
        designationData={designationData}
        designationId={designationId}
        setDesignationId={setDesignationId}
        setDesinationName={setDesinationName}
        desinationName={desinationName} />


    </div>
  );
};

export default DeginationTable;
