import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { tempelatesServices } from "../../APIServices/tempelatesServices";
import Swal from "sweetalert2";

function TemplatesAddModal({
  Show,
  Hide,
  Size,
  name,
  setName,
  getAllTemplates,
  templateId,
  setTemplateId,
}) {
  const [loader, setLoader] = useState(false);

  const addTemplate = async (e) => {
    setLoader(true);
    let userData = {};
    userData.name = name;
    let data = await tempelatesServices.addtemplate(userData);
    if (data?.status_code === 200) {
      clearFormData();
    }
  };

  const updateTemplate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Tempelate Title!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let data = {};
        data.name = name;
        let results = await tempelatesServices.updateTemplate(templateId, data);
        if (results?.status_code === 200) {
          clearFormData();
        }
      }
    });
  };

  const isDisabled = !name;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill Template Name
    </Tooltip>
  );

  const clearFormData = () => {
    setLoader(false);
    Hide();
    setName("");
    getAllTemplates();
    setTemplateId("");
  };

  return (
    <>
      <Modal show={Show} onHide={Hide} size={Size}>
        <Modal.Header className="modal_closeButton">
          <Modal.Title>{!templateId ? "Add" : "Update"} Tempelates</Modal.Title>
          <div
            onClick={() => clearFormData()}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body className="modal_bodyText">
          <Form.Label className="mb-2 fw-bold">
            <b>Templats Name</b>
            <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Tempelates Name"
            className="rounded-1"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <OverlayTrigger
                  placement="top"
                  overlay={isDisabled ? renderTooltip : <span></span>}
                >
                  <button
                    disabled={!name || loader}
                    variant="outline-dark"
                    type="submit"
                    className="modalFooterSubmit_button m-0"
                    onClick={!templateId ? addTemplate : updateTemplate}
                  >
                    {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 15, height: 15 }}
                        className="me-2"
                      />
                    ) : (
                      <i
                        className={`me-2 fa ${
                          !templateId ? "fa-square-plus" : "fa-pen-to-square"
                        }`}
                      ></i>
                    )}
                    {!templateId ? "Add" : "Update"} Tempelate
                  </button>
                </OverlayTrigger>
              </div>
            </Form.Group>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TemplatesAddModal;
