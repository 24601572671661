import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
    handleResponse,
    headersWithAuth,
    headersWithAuthWithBody,
    headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const invoiceServices = {
    addInvoice,
    addInvitei,
    getUsers,
    changeStatus,
    deleteInvoice,
    updateUser,
    getSponsorByStatus,
    getInvoiceBySponsor,
    updateInvoice,
    getInvoiceById,
};

//addUser
async function addInvoice(userData) {
    const response = await fetch(
        `${config.API_URL}addInvoice`,
        headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}

//addInvitation
async function addInvitei(userData) {
    const response = await fetch(
        `${config.API_URL}addInvitei`,
        headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}

// getUsers
async function getUsers() {
    const response = await fetch(
        `${config.API_URL}getUsers`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

//getSponsorByStatus
async function getSponsorByStatus() {
    const response = await fetch(
        `${config.API_URL}getSponsorByStatus`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

//getInvoiceBySponsor

async function getInvoiceBySponsor() {
    const response = await fetch(
        `${config.API_URL}getInvoiceBySponsor`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

// changeStatus;
async function changeStatus(_id, userData) {
    const response = await fetch(
        `${config.API_URL}changeStatus/${_id}`,
        headersWithAuthWithBody("PUT", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}

//deleteUser;
async function deleteInvoice(_id) {
    const response = await fetch(
        `${config.API_URL}deleteInvoice/${_id}`,
        headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

//updateUser
async function updateUser(_id, userData) {
    const response = await fetch(
        `${config.API_URL}updateUser/${_id}`,
        headersWithAuthWithBody("PUT", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}


//updateInvoice
async function updateInvoice(_id, userData) {
    const response = await fetch(
        `${config.API_URL}updateInvoice/${_id}`,
        headersWithAuthWithBody("PUT", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}

//getInvoiceById
async function getInvoiceById(_id) {
    const response = await fetch(
        `${config.API_URL}getInvoiceById/${_id}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}