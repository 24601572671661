import React from "react";
import TeamTableData from "../Team/TeamTableData";

const TeamList = ({
  tableRef,
  onMouseDown,
  selectedRow,
  setSelectedRow,
  getUsers,
  loading,
  teamsData,
}) => {
  return (
    <>
      <TeamTableData
        getUsers={getUsers}
        onMouseDown={onMouseDown}
        loading={loading}
        tableRef={tableRef}
        teamsData={teamsData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </>
  );
};

export default TeamList;
