import React, { useEffect, useState } from "react";
import { Form, FormGroup, NavLink, Placeholder } from "react-bootstrap";
import Ssc from "./QutationFiles/Ssc";
import QuotationPhase from "./QuotationPhase";
import { workflowServices } from "../../APIServices/workflowServices";

const PhaseSidebar = ({ isCollapseQuotations }) => {
  const [workflowList, setWorkflowList] = useState([]);
  const [workFlowId, setWorkFlowId] = useState("");
  const [workFlowName, setWorkFlowName] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [visibleItems, setVisibleItems] = useState(16);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const getActiveWorkflow = async () => {
    setListLoader(true);
    let data = await workflowServices.getActiveWorkflow();
    setWorkflowList(data.data);
    setListLoader(false);
  };

  useEffect(() => {
    getActiveWorkflow();
  }, []);

  return (
    <>
      <div className="sidebar_section">
        <div className="d-flex second_sidebaar">
          <div
            style={{ width: isCollapseQuotations ? "0%" : "18%" }}
            className={
              isCollapseQuotations ? "smoothCollapse1" : "smoothCollapse"
            }
          >
            <div className="sidebar_section border-end">
              <div className="border-bottom text-center quoutationBackground">
                {!isCollapseQuotations && (
                  <FormGroup>
                    <Form.Label>
                      <span className="fs-13">Services</span>
                    </Form.Label>
                  </FormGroup>
                )}
              </div>

              <div className="workflow_text w-100 ">
                <div className="sid_li">
                  <NavLink to={"#"}>
                    <div
                      className="overflow-auto"
                      style={{ height: "calc(100vh - 128px)" }}
                    >
                      {workflowList?.length > 0 ? (
                        workflowList
                          .slice(0, visibleItems)
                          .map((item, index) => (
                            <div
                              key={index}
                              className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${
                                item?._id?.$oid === workFlowId
                                  ? "sidebarMenuActive"
                                  : ""
                              }`}
                              onClick={() => {
                                setWorkFlowId(item?._id?.$oid);
                                setWorkFlowName(item.name);
                              }}
                            >
                              <div
                                className={`${
                                  item?.status === "inactive"
                                    ? "text-muted"
                                    : ""
                                }`}
                              >
                                {listLoader ? (
                                  <Placeholder animation="glow">
                                    <Placeholder xs={1} className="" />
                                    <Placeholder xs={11} />
                                  </Placeholder>
                                ) : (
                                  <div>
                                    <i class="fa-brands fa-wordpress me-2"></i>
                                    <span style={{ fontSize: "13px" }}>
                                      {item.name}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="p-2 workflowMenuList">
                          <i class="fa-regular fa-face-frown ms-2 me-2"></i>
                          <span style={{ fontSize: "13px" }}>
                            Quotations not found!
                          </span>
                        </div>
                      )}
                      {visibleItems < workflowList?.length && (
                        <div
                          onClick={loadMore}
                          className="p-2 workflowMenuList sidebarMenuActive position-sticky bottom-0"
                        >
                          <i class="fa-solid fa-hourglass-end ms-2 me-2"></i>
                          <span style={{ fontSize: "13px" }}>Load More...</span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sidebar_button_2 left-toc-collapsed"
            style={{ width: isCollapseQuotations ? "100%" : "82%" }}
          >
            {workFlowName === "Statistical Input to Protocol" && (
              <QuotationPhase workFlowId={workFlowId} />
            )}

            {workFlowName === "Randomization Schedule Generation" && <Ssc />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhaseSidebar;
