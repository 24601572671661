import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { userServices } from "../APIServices/userServices";

const Study = () => {
  const [studyList, setStudyList] = useState([]);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  let { projectId } = useParams();

  const getStudyByProjectId = async () => {
    let data = await userServices.getStudyByProjectId(projectId);
    setStudyList(data?.data);
  };

  const handleLoginClick = (studyID) => {
    localStorage.setItem("loginType", "study");
    window.location.href = `/study/${studyID}`;
  };

  useEffect(() => {
    getStudyByProjectId();
  }, [projectId]);

  console.log("studyList",studyList);
  
  return (
    <div>
      <div
        className="overflow-auto m-1"
        style={{ height: "calc(100vh - 130px" }}
      >
        <Table
          id="resizable-table"
          bordered
          className="custom-table"
          striped
          border
          hover
        >
          <thead className="position-sticky" style={{ top: -1 }}>
            <tr style={{ cursor: "pointer" }}>
              <th style={{ width: 42 }}>Sr.</th>
              <th>Company Name</th>
              <th>Project Name</th>
              <th>Study Name</th>
              <th>Status</th>
              <th style={{ width: 129 }}>Login</th>
            </tr>
          </thead>
          <tbody>
            {studyList?.map((item, index) => (
              <tr key={item.id} style={{ cursor: "pointer" }}>
                <td>{index + 1}</td>
                <td>{item.companyName}</td>
                <td>{item.projectName}</td>
                <td>{item.studyName}</td>
                <div
                  style={{ color: getStatusColor(item.status), fontSize: 12 }}
                >
                  {item.status}
                </div>
                <td className="text-center">
                  <div className="button_type">
                    <button
                      type="submit"
                      style={{ padding: "2px 20px" }}
                      onClick={() => handleLoginClick(item.studyID)}
                    >
                      Login
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Study;
