import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Collapse,
  Tooltip,
} from "react-bootstrap";
import { workflowServices } from "../../APIServices/workflowServices";
import Swal from "sweetalert2";
import Multiselect from "multiselect-react-dropdown";
import { NavLink, useParams } from "react-router-dom";

const TaskDetailModal = ({
  Show,
  Hide,
  taskData,
  getMilestones,
  roleType,
  teamsData,
  taskID,
  commentTaskId,
  selectedOptiones,
  setSelectedOptiones,
  userCurrrentData
}) => {
  const [activeTab, setActiveTab] = useState("comments");
  const [headerTitle, setHeaderTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [options, setOptions] = useState([]);
  const [optiones, setOptiones] = useState([]);
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoadering] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [taskActivityData, setTaskActivityData] = useState([]);
  const [allTaskComment, setAllTaskComment] = useState([]);
  const [commentStatusMessage, setCommentStatusMessage] = useState("");
  const [status, setStatus] = useState("");
  const [taskstatus, setTaskStatus] = useState("Pending");
  const [statusCode, setStatusCode] = useState("");
  const [selecteduserIds, setSelectedUserIds] = useState([]);
  const [openTextFormate, setOpenTextFormate] = useState(false);
  const [fileType, setFileType] = useState("");
  const [profileImage, setProfileImage] = useState("");

  // >>>>>>>>>>>>Show Comment
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setComment((prevComment) => `${prevComment}\n[File: ${file.name}]`);
    }
    setFileType(e.target.value);
  };

  // >>>>>>>>>>>>

  const { studyId } = useParams();

  // >>>>>>>>>>Assign too Multiselect

  const handleSelect = (selectedList) => {
    setSelectedOptiones(selectedList);
    updateSelectedIds(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptiones(NewObjectData);
    updateSelectedIds(NewObjectData);
  };

  const updateSelectedIds = (selectedList) => {
    const ids = selectedList.map((item) => item.value);
    setSelectedUserIds(ids);
  };

  const fetchOptiones = async () => {
    const formattedOptiones = teamsData?.map((item) => ({
      label: item?.fullName,
      value: item?._id?.$oid, // Use the _id.$oid as the value
    }));

    setOptiones(formattedOptiones);
  };
  // >>>>>>>>>>>>>

  // ....................Tempelates Multiselect
  const fetchOptions = async () => {
    let data = await workflowServices.getTemplateForTask(taskID);
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((template) => ({
        label: template.name,
        value: template._id,
      }));
      setOptions(formattedOptions);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [taskID]);

  const handleCheck = (templateId) => {
    setCheckedState((prevStates) =>
      prevStates?.map((item) =>
        item.templateId === templateId
          ? { ...item, required: !item.required }
          : item
      )
    );
  };

  const templateSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    let checkedData = selectedList?.map((item) => ({
      templateId: item?.value,
      required: false,
    }));
    setCheckedState(checkedData);
  };

  const templateRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptions(NewObjectData);
  };
  // ..............................

  // >>>>>>>>>>>>For Status Change

  const handleChange = (event) => {
    setTaskStatus(event.target.value);
  };

  const getColorForStatus = (status) => {
    switch (status) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black";
    }
  };
  // >>>>>>>>>>>>>>>

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const currentPath = splitUrl[3];
  const loginType = localStorage.getItem("loginType");

  const updateTask = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Task Details Sections!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.taskName = headerTitle;
        userData.startDate = startDate;
        userData.endDate = endDate;
        userData.description = description;
        userData.status = status;
        // userData.status = taskstatus;
        userData.templates = checkedState;
        let results = await workflowServices.updateTask(
          taskData?._id?.$oid,
          userData
        );
        if (results?.status_code === 200) {
          setLoader(false);
          clearFormData();
          getMilestones();
        }
      }
    });
  };

  const handleSubmit = async (e) => {
    setLoadering(true);
    e.preventDefault();
    let userData = {};
    userData.studyID = studyId;
    userData.taskID = taskData?.taskID;
    userData.userID = selecteduserIds;
    let data = await workflowServices.addAssignedStudyAndTask(userData);
    setStatusCode(data);
    if (data?.status_code === 200) {
      clearFormData();
      setLoadering(false);
    } else {
      setLoadering(false);
    }
  };

  // const updateTaskComment = async () => {
  //   let userData = {};
  //   userData.comments = comment;
  //   let results = await workflowServices.updateTask(
  //     taskData?._id?.$oid,
  //     userData
  //   );
  //   results?.status_code === 200;
  // };

  const addTaskComment = async (e) => {
    e.preventDefault();
    setLoader(true);
    let userData = {};
    userData.comment = comment;
    userData.file = fileType;
    let data = await workflowServices.addTaskComment(userData, commentTaskId);
    setCommentStatusMessage(data);
    if (data?.status_code === 200) {
      setLoader(false);
      setStatusCode(true);
    }
  };

  const getAllTaskComment = async () => {
    let data = await workflowServices.getAllTaskComment(commentTaskId);
    setAllTaskComment(data?.data);
  };

  const getTaskLogActivity = async () => {
    let data = await workflowServices.getTaskLogActivity();
    setTaskActivityData(data?.data);
  };


  useEffect(() => {
    setProfileImage(
      userCurrrentData?.imageUrl
        ? userCurrrentData?.imageUrl
        : ""
    );
  }, [userCurrrentData]);



  useEffect(() => {
    getAllTaskComment();
  }, [commentTaskId]);

  useEffect(() => {
    getTaskLogActivity();
  }, []);

  const handleLoginClick = (_id) => {
    setLoader(true);
    window.location.href = `/form/${_id}`;
    setLoader(false);
  };

  const clearFormData = () => {
    Hide();
    setHeaderTitle("");
    setStartDate("");
    setEndDate("");
    setStatus("");
    setDescription("");
    setComment("");
    setLoader("");
  };

  useEffect(() => {
    setHeaderTitle(taskData?.taskName || "");
    setStartDate(taskData?.startDate || "");
    setEndDate(taskData?.endDate || "");
    setStatus(taskData?.status || "");
    setDescription(taskData?.description || "");
  }, [taskData]);



  useEffect(() => {
    fetchOptiones();
  }, [teamsData]);

  return (
    <>
      <div className="milestonemodal_Font">
        <Offcanvas
          show={Show}
          onHide={Hide}
          placement="end"
          style={{ width: "660px" }}
        >
          <Offcanvas.Header className="offcanvas_header">
            <Offcanvas.Title>
              <FormControl
                className="offcanvas_workflow w-100"
                type="text"
                onChange={(e) => setHeaderTitle(e.target.value)}
                value={headerTitle}
                readOnly={roleType === "user"}
              />
            </Offcanvas.Title>
            <div className="hstack gap-3">
              <div
                onClick={() => clearFormData()}
                className="offcanvas_Close"
                title="Close"
              >
                X
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <div className="px-3 pt-2">
              <Container fluid className="p-0">
                <Row>
                  {(roleType === "user" || roleType === "client") && (
                    <Col md={6} className="mb-2" style={{ paddingRight: 15 }}>
                      <Form.Group>
                        <Form.Label>
                          <b>Start Date :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          style={{ height: 39 }}
                          disabled={roleType === "user"}
                          onChange={(e) => setStartDate(e.target.value)}
                          value={startDate}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {(roleType === "user" || roleType === "client") && (
                    <Col md={6} className="mb-2" style={{ paddingRight: 15 }}>
                      <Form.Group>
                        <Form.Label>
                          <b>End Date :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          style={{ height: 39 }}
                          disabled={roleType === "user"}
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  {roleType === "client" && currentPath !== "clientstudy" && (
                    <Col md={12} className=" mb-2">
                      <Form.Group>
                        <Form.Label className="mb-1 fw-bold">
                          <b>Status :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="rounded-1"
                          style={{
                            height: 39,
                            color: getColorForStatus(status),
                          }}
                        >
                          <option selected={status === "active"} value="active" style={{ color: "green" }}>
                            Active
                          </option>
                          <option selected={status === "inactive"} value="inactive" style={{ color: "red" }}>
                            Inactive
                          </option>
                          <option selected={status === "pending"} value="pending" style={{ color: "orange" }}>
                            Pending
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                  {currentPath === "clientstudy" && (
                    <Col md={12} className=" mb-2">
                      <Form.Group>
                        <Form.Label className="mb-1 fw-bold">
                          <b> Task Status :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          value={taskstatus}
                          className="rounded-1"
                          style={{
                            height: 39,
                            color: getColorForStatus(status),
                          }}
                          onChange={handleChange}
                        >
                          <option selected={taskstatus === "start"} value="start" className="text-primary">
                            Start
                          </option>
                          <option selected={taskstatus === "complete"} value="complete" className="text-success">
                            complete
                          </option>
                          <option selected={taskstatus === "pending"} value="pending" className="text-secondary">
                            Pending
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Container>

              {currentPath === "clientstudy" && roleType !== "user" && (
                <Form.Group className="mb-2 ">
                  <Form.Label className="mb-1 fw-bold">
                    <b>Assign To:</b>
                  </Form.Label>
                  <div style={{ alignItems: "end" }} className="d-flex gap-2 w-100">
                    <div className="w-100">
                      <Multiselect
                        className="task-multiselect"
                        displayValue="label"
                        onKeyPressFn={() => { }}
                        onRemove={handleRemove}
                        onSelect={handleSelect}
                        options={optiones}
                        // style={{ width: "100%" }}
                        selectedValues={selectedOptiones}
                      />
                    </div>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-save">Assign Task To User</Tooltip>
                      }
                    >
                      <div className="button_type">
                        <button
                          onClick={handleSubmit}

                        >
                          {loading ? (
                            <Spinner
                              animation="border"
                              style={{ width: 15, height: 15 }}
                              className="me-2"
                            />
                          ) : null}
                          Assign
                        </button>
                      </div>
                    </OverlayTrigger>
                  </div>

                </Form.Group>
              )}
              {currentPath !== "clientstudy" && roleType !== "user" && (
                <>
                  <Form.Group className="mb-2  gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      <b>Templates :</b>
                    </Form.Label>
                    <Multiselect
                      displayValue="label"
                      onKeyPressFn={() => { }}
                      onRemove={templateRemove}
                      onSelect={templateSelect}
                      options={options}
                      selectedValues={selectedOptions}
                    />
                  </Form.Group>
                  {selectedOptions?.length > 0 && (
                    <Form.Group className="mt-2">
                      <Form.Label>
                        <b>Check Required Templates</b>
                      </Form.Label>
                      {selectedOptions?.map((item) => (
                        <Form.Check
                          key={item.value}
                          type="checkbox"
                          value={item.value}
                          label={item.label}
                          onChange={() => handleCheck(item.value)}
                        />
                      ))}
                    </Form.Group>
                  )}
                </>
              )}
              {roleType === "user" && (
                <>
                  <Form.Group className="mb-2 gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      <b>Templates :</b>
                    </Form.Label>
                    {!(currentPath === "clientstudy" || roleType === "user") ? (
                      <Multiselect
                        displayValue="label"
                        onKeyPressFn={() => { }}
                        onRemove={templateRemove}
                        onSelect={templateSelect}
                        options={options}
                        selectedValues={selectedOptions}
                      />
                    ) : (
                      <>
                        <div>
                          {taskData?.templates?.map((item, index) => (
                            <NavLink key={index} to={`temp/${item?._id}`}
                              onClick={() => handleLoginClick(item._id)}
                            >
                              <Badge
                                bg="secondary"
                                className="custom_badge p-2 rounded me-2"
                                style={{ cursor: "pointer" }}
                              >
                                {item?.name}
                              </Badge>
                            </NavLink>
                          ))}
                        </div>
                        {selectedOptions?.length > 0 && (
                          <Form.Group className="mt-2">
                            <Form.Label>
                              <b>Check Required Templates</b>
                            </Form.Label>
                            {selectedOptions?.map((item) => (
                              <Form.Check
                                key={item.value}
                                type="checkbox"
                                value={item.value}
                                label={item.label}
                                onChange={() => handleCheck(item.value)}
                              />
                            ))}
                          </Form.Group>
                        )}
                      </>
                    )}
                  </Form.Group>
                </>
              )}

            </div>

            <div className="px-3 pt-1">
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Description:</b>
                </Form.Label>
                <ReactQuill
                  onChange={setDescription}
                  value={description}
                  readOnly={
                    roleType === "user" || currentPath === "clientstudy"
                  }
                />
              </Form.Group>
            </div>
            <div className="">
              {(currentPath === "clientstudy" || loginType === "study") && (
                <>
                  <hr style={{ margin: "0px" }} />
                  <div className="offcanvas-message mb-3">
                    <div className="offcanvas-message-box hstack gap-2">
                      <div className="date-buttonchange d-flex">
                        <div
                          className={`workflowheader p-2 ${activeTab === "comments" ? "active border-bottom-active fw-bold" : "text-muted"}`}
                          onClick={() => handleTabClick("comments")}
                          style={{ marginLeft: 10, cursor: "pointer" }}
                        >
                          Comments
                        </div>
                        <div
                          className={`workflowheader p-2 px-2 ${activeTab === "activity" ? "active border-bottom-active fw-bold" : "text-muted"
                            }`}
                          onClick={() => handleTabClick("activity")}
                          style={{ marginLeft: 10, cursor: "pointer" }}
                        >
                          All Activity
                        </div>
                      </div>
                    </div>
                    <hr style={{ margin: "0px" }} />
                    {activeTab === "comments" && (
                      <div className="offcanbodytwo">
                        <div>
                          <div className="comments-parentClass">
                            <div className="d-flex p-2">
                              <div className="icon_wrap p-2">
                                <img src={profileImage} alt="Profile" title="User Profile" />
                              </div>
                              <div className="w-100 position-relative">
                                <Form.Control
                                  as="textarea"
                                  placeholder="Leave a comment here"
                                  style={{ height: "170px", marginRight: "13px" }}
                                  className="offcanvas-description mt-2"
                                  onChange={(e) => setComment(e.target.value)}
                                  value={comment}
                                />
                                <div className="position-absolute bottom-0 start-0">
                                  <div className="position-relative">
                                    <Collapse in={openTextFormate}>
                                      <div>
                                        <div
                                          className="menu-section overflow-auto"
                                          style={{ height: 150, position: "absolute" }}
                                        >
                                          <ul className="menu-list">
                                            <li>
                                              <i className="fa-solid fa-bold px-2"></i>
                                              Bold
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-italic px-2"></i>
                                              Italic
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-underline px-2"></i>
                                              underline
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-code px-2"></i>
                                              Code
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-list-ol"></i>
                                              Numbered list
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-list-ul px-2"></i>
                                              Bullet List
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Collapse>
                                    <div className="customCummontOPtion">
                                      <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                      />
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip-bold">
                                            Attach a file an upload image
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          className="fa-solid fa-paperclip px-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={handleIconClick}
                                        ></i>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip-bold">
                                            Text Formatting
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          onClick={() =>
                                            setOpenTextFormate(!openTextFormate)
                                          }
                                          className="fa-brands fa-autoprefixer px-2"
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip-bold">
                                            At-Mention
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-at px-2"></i>
                                        </span>
                                      </OverlayTrigger>
                                    </div>


                                    <div className="customCummontOPtion">
                                      <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "activity" && (
                      <div className="d-flex px-3">
                        <div>
                          {taskActivityData?.slice(0, 4)?.map((item, index) => (
                            <div key={index} className="d-flex align-items-center pt-3">
                              {/* <div className="offcanvas-messageName-icon">
                                <span>
                                  {userCurrrentData?.shortName}
                                </span>
                              </div> */}
                              <div className="icon_wrap p-2">
                                <img src={profileImage} alt="Profile" title="User Profile" />
                                <span className="ms-2">{item?.comment}</span>
                              </div>

                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {activeTab === "comments" && (
                <div className="button_type text-end p-2 mt-3">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-save">
                        {currentPath === "clientstudy" ? "Add Comment" : "Apply Changes"}
                      </Tooltip>
                    }
                  >
                    {(roleType === "client" || roleType === "user") && (
                      <button
                        onClick={
                          currentPath === "clientstudy" ? addTaskComment : updateTask
                        }
                      >
                        {loader ? (
                          <Spinner
                            animation="border"
                            style={{ width: 15, height: 15 }}
                            className="me-2"
                          />
                        ) : currentPath === "clientstudy" ? (
                          "Comment"
                        ) : (
                          "Update"
                        )}
                      </button>
                    )}
                  </OverlayTrigger>
                </div>
              )}
            </div>


          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default TaskDetailModal;
