import React, { useState } from "react";
import { Button } from "react-bootstrap";
import EActivity from "./ElearningProperties/EActivity";
import AssignUser from "./ElearningProperties/AssignUser";
import ELearningActivity from "./ElearningProperties/ELearningActivity";

const ELearningProperties = ({ setIsSectionVisible, companyID, elearningID }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const [activeTabs, setActiveTabs] = useState("assign User");


  return (
    <>
      <div className={isFullScreen ? "full-screen-component" : ""}>
        <div className="hstack paginate fixed_tab_button justify-content-between border-bottom">
          <div className="hstack ms-2 gap-1">
            {["assign User", "learning Activity", "activity"].map((tab) => (

              <Button
                key={tab}
                className={` w-100 border-top rounded-top border-dark border-start border-end border-0 rounded-0 clientButton ${activeTabs === tab ? "active" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                style={{ minWidth: "140px" }}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </Button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <button
                title="Hide Tables"
                onClick={() => setIsSectionVisible(false)}
                className="teams_button border rounded-1 fs-12"
                style={{ marginRight: 8 }}
              >
                <i
                  className="fa-solid fa-angle-down"

                ></i>
              </button>
            </div>
            <div
              className={`app-container ${isFullScreen ? "fullscreen" : ""}`}
            >
              <button onClick={toggleFullScreen}
                title="View Full Mode"
                className="teams_button border rounded-1 fs-12"
                style={{ marginRight: 15 }}
              >
                <i
                  className={`fa-solid ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                    }`}
                  title={!isFullScreen ? "Full Mode" : "Original Mode"}
                ></i>
              </button>
            </div>
          </div>
        </div>

        {activeTabs === "assign User" ? (
          <AssignUser companyID={companyID} elearningID={elearningID} />
        ) : activeTabs === "learning Activity" ? (
          <ELearningActivity elearningID={elearningID} />
        ) : activeTabs === "activity" ? (
          <EActivity />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ELearningProperties;
