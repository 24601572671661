import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import { NavLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { elearningServices } from "../../../APIServices/elearningServices";
import { Badge, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';

const AddTrainingData = ({ Show, Hide, Title, mode, updateTrainingDetails, updateTrainingQuizDetails, getAllTraining, trandingId, questions, setQuestions, multiselectAnswer, updateTrainingId }) => {

  let [type, setType] = useState("");
  let [title, setTitle] = useState("");
  let [loader, setLoader] = useState(false);
  let [fileType, setFileType] = useState("");
  let [uploadFile, setUploadFile] = useState("");
  let [categories, setCategories] = useState("");
  let [description, setDescription] = useState("");
  let [selectAnswerOption, setSelectAnswerOption] = useState(null);
  let [numberOfQuestions, setNumberOfQuestions] = useState("");
  const { trainingID } = useParams();
  const questionId = uuidv4();

  // const [questions, setQuestions] = useState([
  //   { questionId, question: "", options: ["", "", "", ""], answerType: "", multiselectAnswer },
  // ]);

  // console.log("questions", questions);



  console.log("updateTrainingDetails", updateTrainingDetails);

  const addTrainingQuiz = async (e) => {
    setLoader(true);
    let userData = {};
    userData.title = title;
    userData.description = description;
    userData.type = type;
    userData.questionPerQuiz = numberOfQuestions;
    userData.category = categories;
    userData.quiz = questions;
    userData.questionId = questionId;
    let data = await elearningServices.addTrainingQuiz(trandingId, userData);
    setLoader(false);
    getAllTraining();
    if (data?.status_code === 200) {
    } else {
      setLoader(false);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target?.files;
    if (files && files.length > 0) {
      const fileData = files[0];
      setUploadFile(fileData);
    }
  };

  const handleAddTrainingFile = async (e) => {
    setLoader(true)
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("fileType", fileType);
    let result = await elearningServices.addTrainingFile(formData, trandingId);
    setLoader(false)
    getAllTraining();
    if (result?.status_code === 200) {
    } else {
      setLoader(false);
    }
  }

  const handleSave = () => {
    if (type === "file") {
      handleAddTrainingFile();
    } else if (type === "quiz") {
      addTrainingQuiz();
    }
  };

  const handleSaveQuiz = () => {
    if (type === "file") {
      updateTrainingFile();
    } else if (type === "quiz") {
      updateTrainingquiz();
    }
  };

  const updateTrainingFile = async () => {
    let _id = updateTrainingDetails?._id?.$oid;
    let userData = {};
    userData.title = title;
    userData.description = description;
    userData.type = type;
    userData.fileType = fileType;
    userData.uploadFile = uploadFile;
    setLoader(true);
    let data = await elearningServices.updateTrainingFile(_id, userData);
    setLoader(false);
    if (data?.status_code === 200) {
      clearFormData();
      getAllTraining();
    }
  };

  const clearFormData = () => {
    setDescription("");
    setTitle("");
    setType("");
    setFileType("");
    setUploadFile("");
  };



  const updateTrainingquiz = async () => {
    let _id = updateTrainingQuizDetails?._id?.$oid;
    let userData = {};
    userData.title = title;
    userData.description = description;
    userData.type = type;
    userData.category = categories;
    userData.questionPerQuiz = numberOfQuestions;
    userData.quiz = questions;
    setLoader(true);
    let data = await elearningServices.updateTrainingquiz(_id, userData);
    setLoader(false);
    if (data?.status_code === 200) {
      clearFormQuizData();
      getAllTraining();
    }
  };

  const deleteTrainingQuestion = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Training Data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",

    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await elearningServices.deleteTrainingQuestion(updateTrainingId);
        if (data?.status_code === 200) {
          getAllTraining();
          Hide()
        }
      }
    });
  };

  const clearFormQuizData = () => {
    setDescription("");
    setTitle("");
    setType("");
    setCategories("");
    setNumberOfQuestions("");
    setQuestions([]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };



  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAnswerTypeChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answerType = value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answer = value;
    setSelectAnswerOption(newQuestions);
  };

  const handleAnswerMultiChange = (qIndex, selectedOptions) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].multiselectAnswer = selectedOptions.map(option => option.label);
    setQuestions(newQuestions);
  };


  const addQuestion = () => {
    setQuestions([
      ...questions,
      { questionId, question: "", options: ["", "", "", ""], answerType: "", multiselectAnswer },
    ]);
  };

  const deleteQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const addOption = (qIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.push("");
    setQuestions(newQuestions);
  };

  const deleteOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options = newQuestions[qIndex].options.filter(
      (_, i) => i !== oIndex
    );
    setQuestions(newQuestions);
  };



  useEffect(() => {
    if (updateTrainingDetails) {
      setType(updateTrainingDetails?.type || "");
      setTitle(updateTrainingDetails?.title || "");
      setFileType(updateTrainingDetails?.fileType || "");
      setDescription(updateTrainingDetails?.description || "");
      setUploadFile(updateTrainingDetails?.uploadFile || "");
    }
  }, [updateTrainingDetails]);

  useEffect(() => {
    if (updateTrainingQuizDetails) {
      setType(updateTrainingQuizDetails?.type || "");
      setTitle(updateTrainingQuizDetails?.title || "");
      setCategories(updateTrainingQuizDetails?.category || "");
      setDescription(updateTrainingQuizDetails?.description || "");
      setNumberOfQuestions(updateTrainingQuizDetails?.questionPerQuiz || "");
      if (updateTrainingQuizDetails?.quiz?.length) {
        setQuestions(updateTrainingQuizDetails.quiz);
      }
    }
  }, [updateTrainingQuizDetails]);

  return (
    <div>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 600 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={title}
                className="rounded-1"
                placeholder="Enter Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                className="rounded-1"
                placeholder="Enter Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="rounded-1"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option selected={type === "file" && "file"} value="file">
                  File
                </option>
                <option selected={type === "quiz" && "quiz"} value="quiz">
                  Quiz
                </option>
              </Form.Select>
            </Form.Group>
            {type === "file" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    File Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={fileType}
                    className="rounded-1"
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <option value="">Select File Type</option>
                    <option
                      selected={fileType === "video" && "video"}
                      value="video"
                    >
                      Video
                    </option>
                    <option selected={fileType === "pdf" && "pdf"} value="pdf">
                      Pdf
                    </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    Upload File <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    className="rounded-1"
                    disabled={!fileType}
                    accept={`${fileType === "video" ? ".mp4" : ".pdf"}`}
                    onChange={handleFileChange}
                  />
                </Form.Group>
                {mode === "update" && (
                  <div className="button_type">
                    <NavLink to={uploadFile}>
                      <button >
                        Download File
                      </button>
                    </NavLink>
                  </div>
                )}
              </>
            )}

            {type === "quiz" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    No.of Question for Quiz {" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={numberOfQuestions}
                    placeholder="Enter No. of Question for Quiz"
                    className="rounded-1"
                    onChange={(e) => setNumberOfQuestions(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    Categories <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="rounded-1"
                    value={categories}
                    onChange={(e) => setCategories(e.target.value)}
                  >
                    <option value="">Select Categories</option>
                    <option
                      selected={categories === "finalQuiz" && "finalQuiz"}
                      value="finalQuiz"
                    >
                      Final Quiz
                    </option>
                    <option
                      selected={
                        categories === "knowledgeQuiz" && "knowledgeQuiz"
                      }
                      value="knowledgeQuiz"
                    >
                      Knowledge Checking Quiz
                    </option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
            {type === "quiz" && categories && (
              <Form.Group className="mb-2">
                <div className="border rounded-1 p-3 mt-4 pt-4 position-relative">
                  <Badge
                    className="position-absolute rounded-1 text-muted bg-success-subtle"
                    style={{ top: -10 }}
                  >
                    Quiz
                  </Badge>
                  <div>
                    {questions?.map((q, qIndex) => (
                      <div key={qIndex} className="mb-2">
                        <div className="mt-2">
                          <div className="shadow-sm p-2 border rounded-1">
                            <Form.Group className="mb-2">
                              <div className="hstack justify-content-between">
                                <Form.Label className="fw-bold my-1 fontSize12">
                                  Question {qIndex + 1}{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <i
                                  className="fa-solid fa-trash-can text-danger fontSize12 CP"
                                  title="Delete Question"
                                  onClick={() => deleteQuestion(qIndex)}
                                ></i>
                              </div>
                              <Form.Control
                                as="textarea"
                                className="rounded-1"
                                value={q.question}
                                placeholder="Type Question"
                                onChange={(e) =>
                                  handleQuestionChange(qIndex, e.target.value)
                                }
                              />
                            </Form.Group>
                            <Row>
                              {q?.options?.map((option, oIndex) => (
                                <React.Fragment key={oIndex}>
                                  <Form.Group as={Col} md={3} className="mb-2">
                                    <Form.Label className="fw-bold mb-1 fontSize12">
                                      Option {oIndex + 1}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md={9}
                                    className="hstack gap-2 mb-2"
                                  >
                                    <Form.Control
                                      type="text"
                                      className="rounded-1"
                                      value={option}
                                      placeholder="Type Answer"
                                      onChange={(e) =>
                                        handleOptionChange(
                                          qIndex,
                                          oIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <i
                                      className="fa-solid fa-trash-can text-danger fontSize12 CP"
                                      title={`Delete Option ${oIndex + 1}`}
                                      onClick={() =>
                                        deleteOption(qIndex, oIndex)
                                      }
                                    ></i>
                                  </Form.Group>
                                </React.Fragment>
                              ))}
                              <Form.Group className="mb-2">
                                <button
                                  className="border fontSize12 rounded-1 p-1 px-2 updateProfile"
                                  onClick={() => addOption(qIndex)}
                                >
                                  + Add More Options
                                </button>
                              </Form.Group>
                            </Row>
                            <Form.Group className="mb-2">
                              <Form.Label className="mb-1 fw-bold fontSize12">
                                Answer Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                className="rounded-1"
                                value={q.answerType}
                                onChange={(e) =>
                                  handleAnswerTypeChange(qIndex, e.target.value)
                                }
                              >
                                <option value="">Select Answer Type</option>
                                <option value="singleSelect">
                                  Single Select
                                </option>
                                <option value="multiSelect">
                                  Multi Select
                                </option>
                              </Form.Select>
                            </Form.Group>
                            {q.answerType === "multiSelect" && (
                              <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold fontSize12">
                                  Answer <span className="text-danger">*</span>
                                </Form.Label>
                                <Multiselect
                                  displayValue="label"
                                  value={q.multiselectAnswer.map(answer => ({ label: answer, value: answer }))}
                                  options={q.options.map((option, i) => ({ label: `Option ${i + 1}`, value: option }))}
                                  onSelect={(list) => handleAnswerMultiChange(qIndex, list)}
                                  onRemove={(list) => handleAnswerMultiChange(qIndex, list)}
                                />
                              </Form.Group>
                            )}
                            {q?.answerType === "singleSelect" && (
                              <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold fontSize12">
                                  Answer <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select className="rounded-1"
                                  value={q.answer}
                                  onChange={(e) =>
                                    handleAnswerChange(qIndex, e.target.value)
                                  }>
                                  <option value="">Select Answer</option>
                                  {q.options.map((option, oIndex) => (
                                    <option
                                      key={oIndex}
                                      value={`Option ${oIndex + 1}`}
                                      className={`option.status === "true"`}
                                    >
                                      {`Option ${oIndex + 1}`}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={addQuestion}
                      className="submitbutton rounded-1 mt-3"
                    >
                      <i className="fa-solid fa-square-plus fontSize12 CP me-1"></i>{" "}
                      Add Question
                    </button>
                  </div>
                </div>
              </Form.Group>
            )}
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2 button_type">
            {mode === "add" ? (
              <button
                onClick={() => {
                  Hide();
                  clearFormData();
                  clearFormQuizData();
                  setLoader(false);
                }}
                className="border border-danger btn btn-outline-danger fontSize12 rounded-1 p-1 px-2"
              >
                Clear
              </button>
            ) : (
              <button
                onClick={() => {
                  deleteTrainingQuestion(updateTrainingQuizDetails?._id?.$oid);

                }}
                className="border border-danger btn btn-outline-danger fontSize12 rounded-1 p-1 px-2"
              >
                <i
                  className={` fa-solid fa-trash-can me-2`}
                ></i> Delete
              </button>
            )}
            <button className="border fontSize12 rounded-1 p-1 px-2 updateProfile" onClick={mode === "add" ? handleSave : handleSaveQuiz}>
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i
                  className={` fa-solid  ${mode === "add" ? "fa-square-plus" : "fa-square-pen"
                    } me-2`}
                ></i>
              )}
              {`${mode === "add" ? "Add" : "Update"} Training`}
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddTrainingData;
