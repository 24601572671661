import React from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const AdvanceTab = ({ minLength, setMinLength, maxLength, setMaxLength }) => {
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Min-File Size</b>
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              defaultValue={minLength}
              onChange={(e) => setMinLength(e.target.value)}
              placeholder="Enter Minimum File Length"
            />
            <Button variant="secondary" className="p-1">
              KB
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Max-File Size</b>
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              defaultValue={maxLength}
              onChange={(e) => setMaxLength(e.target.value)}
              placeholder="Enter Maximum File Length"
            />
            <Button variant="secondary" className="p-1">
              KB
            </Button>
          </InputGroup>
        </Form.Group>
      </div>
    </>
  );
};

export default AdvanceTab;
