import React from "react";

const Loading = () => {
  return (
    <>
      <div className="container">
        <div className="lock"></div>
        <div className="message">
          <h1 className="forbidden-code">Loader</h1>
          <h1>Page not Found</h1>
          <p>
            Please check with the site admin if you believe this is a mistake.
          </p>
        </div>
      </div>
    </>
  );
};

export default Loading;
