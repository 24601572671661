import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithBody,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const orgServices = {
  addDesignation,
  addOrganisationChart,
  addInvitei,
  getAllDesignation,
  changeStatus,
  deleteDesignation,
  updateDesignation,
  getActiveDesignation,
  getAssignedUserInOrganizationChart,
  getOrganizationChart,
  getParentUserInOrganizationChart,
  deleteOrg,
  getAssignedUserInOrgByParent,
};

//addUser
async function addDesignation(userData) {
  const response = await fetch(
    `${config.API_URL}addDesignation`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
//addOrganisationChart
async function addOrganisationChart(userData) {
  const response = await fetch(
    `${config.API_URL}addOrganisationChart`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//addInvitation
async function addInvitei(userData) {
  const response = await fetch(
    `${config.API_URL}addInvitei`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// getAllDesignation
async function getAllDesignation() {
  const response = await fetch(
    `${config.API_URL}getAllDesignation`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getOrganizationChart
async function getOrganizationChart() {
  const response = await fetch(
    `${config.API_URL}getOrganizationChart`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getActiveDesignation
async function getActiveDesignation() {
  const response = await fetch(
    `${config.API_URL}getActiveDesignation`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getAssignedUserInOrganizationChart
async function getAssignedUserInOrganizationChart() {
  const response = await fetch(
    `${config.API_URL}getAssignedUserInOrganizationChart`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getParentUserInOrganizationChart
async function getParentUserInOrganizationChart() {
  const response = await fetch(
    `${config.API_URL}getParentUserInOrganizationChart`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// changeStatus;
async function changeStatus(_id, userData) {
  const response = await fetch(
    `${config.API_URL}changeStatus/${_id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//deleteUser;
async function deleteDesignation(_id) {
  const response = await fetch(
    `${config.API_URL}deleteDesignation/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//updateUser
async function updateDesignation(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateDesignation/${_id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//deleteOrg;
async function deleteOrg(_id) {
  const response = await fetch(
    `${config.API_URL}deleteOrg/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}


//getAssignedUserInOrgByParent
async function getAssignedUserInOrgByParent(employeeId) {
  const response = await fetch(
    `${config.API_URL}getAssignedUserInOrgByParent/${employeeId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
