import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import GeneralTab from "./GeneralTab";
import AdvanceTab from "./AdvanceTab";
import AccessTab from "./AccessTab";

const UploadFileTempelateCustomize = ({
  show,
  onHide,
  size,
  Title,
  id,
  type,
  sections,
  addUpdateFormById,
}) => {
  const [selectedTab, setSelectedTab] = useState("General");
  const [label, setLabel] = useState("");
  const [color, setColor] = useState("#000000");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [required, setRequired] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [help, setHelp] = useState("");
  const [formate, setFormate] = useState("");
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  useEffect(() => {
    const findField = sections.find((item) => item.id === id);
    if (findField) {
      setLabel(findField.label);
      setColor(findField.labelStyle?.color);
      setIsBold(findField.labelStyle?.bold);
      setIsItalic(findField.labelStyle?.italic);
      setRequired(findField.required);
      setCustomize(findField?.customize);
      setOrderNo(findField.orderNo);
      setDefaultValue(findField.defaultValue);
      setHelp(findField.help);
      setFormate(findField.formate);
      setMinLength(findField.minLength);
      setMaxLength(findField.maxLength);
      setFileUpload(findField.fileUpload);
    }
  }, [id]);

  const handleSaveData = () => {
    let headingObj = {};
    headingObj.id = id;
    headingObj.type = type;
    headingObj.required = required;
    headingObj.customize = customize;
    if (label) {
      headingObj.label = label;
    }
    if (orderNo) {
      headingObj.orderNo = orderNo;
    }
    if (defaultValue) {
      headingObj.defaultValue = defaultValue;
    }
    if (help) {
      headingObj.help = help;
    }
    if (minLength) {
      headingObj.minLength = minLength;
    }
    if (maxLength) {
      headingObj.maxLength = maxLength;
    }
    if (fileUpload) {
      headingObj.fileUpload = fileUpload;
    }
    if (formate) {
      headingObj.formate = formate;
    }
    headingObj.labelStyle = {
      color: color,
      bold: isBold,
      italic: isItalic,
    };

    const existingIndex = sections?.findIndex((item) => item.id === id);
    if (existingIndex !== -1) {
      sections[existingIndex] = headingObj;
    } else {
      sections.push(headingObj);
    }
    window.dispatchEvent(new Event("storage"));
    onHide();
    addUpdateFormById();
  };

  return (
    <Offcanvas show={show} size={size} onHide={onHide} placement="end">
      <Offcanvas.Header>
        <div className="hstack justify-content-between w-100 gap-3">
          <Offcanvas.Title>
            <b>{Title}</b>
          </Offcanvas.Title>
          <div onClick={onHide} className="offcanvas_Close" title="Close">
            X
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <div className="offcanvas-message-box gap-2">
          <div className="date-buttonchange d-flex h-stack">
            <div
              className={`workflowheader w-100 text-center p-1 ${selectedTab === "General" ? "active border-bottom-active fw-bold" : "text-muted"}`}
              onClick={() => setSelectedTab("General")}
              style={{ marginLeft: 5, cursor: "pointer" }}
            >
              General
            </div>
            <div
              className={`workflowheader p-1 text-center w-100 ${selectedTab === "Advance" ? "active border-bottom-active fw-bold" : "text-muted"
                }`}
              onClick={() => setSelectedTab("Advance")}
              style={{ marginLeft: 10, cursor: "pointer" }}
            >
              Advance
            </div>
            <div
              className={`workflowheader p-1 w-100 text-center ${selectedTab === "Access" ? "active border-bottom-active fw-bold" : "text-muted"
                }`}
              onClick={() => setSelectedTab("Access")}
              style={{ marginLeft: 10, cursor: "pointer" }}
            >
              Access
            </div>
          </div>
        </div>
        <hr style={{ margin: "0px" }} />
        {selectedTab === "General" && (
          <GeneralTab
            label={label}
            setLabel={setLabel}
            color={color}
            setColor={setColor}
            isBold={isBold}
            setIsBold={setIsBold}
            isItalic={isItalic}
            setIsItalic={setIsItalic}
            required={required}
            setRequired={setRequired}
            customize={customize}
            setCustomize={setCustomize}
            orderNo={orderNo}
            setOrderNo={setOrderNo}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            help={help}
            setHelp={setHelp}
            formate={formate}
            setFormate={setFormate}
            fileUpload={fileUpload}
            setFileUpload={setFileUpload}
          />
        )}
        {selectedTab === "Advance" && (
          <AdvanceTab
            minLength={minLength}
            setMinLength={setMinLength}
            maxLength={maxLength}
            setMaxLength={setMaxLength}
          />
        )}
        {selectedTab === "Access" && <AccessTab id={id} type={type} />}
        <div className="button_type text-end p-2">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-save">Apply Changes</Tooltip>}
          >
            <button
              onClick={() => handleSaveData()}
            >
              Apply
            </button>
          </OverlayTrigger>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UploadFileTempelateCustomize;
