import { result } from "lodash";
import React, { useEffect, useState } from "react";
import { Offcanvas, Table } from "react-bootstrap";
import Moment from "react-moment";
import { elearningServices } from "../../../APIServices/elearningServices";
import { NoDataFoundTable, SpinerDataLoader } from "../../../Hooks/NoDataFoundTable";

const ClientSideProgressReportOffCanvas = ({ Show, Hide, Title, progressTrainingID, userID }) => {
    const [progressReportdata, setProgressReportdata] = React.useState([]);
    const [loading, setLoading] = useState(false);

    const getViewProgressReport = async () => {
        setLoading(true);
        let data = await elearningServices.getViewProgressReport(progressTrainingID, userID);
        setProgressReportdata(data?.data);
        setLoading(false);
    };

    useEffect(() => {
        getViewProgressReport();
    }, [progressTrainingID, userID]);

    console.log("progressReportdata", progressReportdata);

    return (
        <div>
            <Offcanvas show={Show} onHide={Hide} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{Title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div className="p-2 overflow-auto"
                        style={{ height: "calc(100vh - 185px" }}
                    >
                        {loading ? (
                            <SpinerDataLoader />
                        ) : progressReportdata && progressReportdata.length > 0 ? (

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className="fw-bold">Attributes</th>
                                        <th className="fw-bold">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Training Name</b>
                                        </td>
                                        <td>{progressReportdata?.trainingName}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>User Name</b>
                                        </td>
                                        <td>{progressReportdata?.userName}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Total Quizz Question</b>
                                        </td>
                                        <td>{progressReportdata?.total_question}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Attempt Qizz Question</b>
                                        </td>
                                        <td>{progressReportdata?.no_of_attemptedQues}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Status</b>
                                        </td>
                                        <td style={{ color: progressReportdata?.status === 'fail' ? 'red' : progressReportdata?.status === 'passed' ? 'green' : 'black', textTransform: 'capitalize' }}>
                                            {progressReportdata?.status}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>percentage</b>
                                        </td>
                                        <td>
                                            <span className="p-1">{progressReportdata?.percentage}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        ) : (
                            <NoDataFoundTable MSG={"please Complett Final Quizz"} />
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default ClientSideProgressReportOffCanvas;

