import React, { useEffect, useState } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { useParams } from 'react-router-dom';
import { workflowServices } from '../../APIServices/workflowServices';

const WorkflowCalender = () => {
    const [WorkflowCalenderData, setWorkflowCalenderData] = useState([]);
    const [loader, setLoader] = useState(true);
    const { studyId } = useParams();

    const getAllTaskByUser = async () => {
        let data = await workflowServices.getAllTaskByUser(studyId);
        setWorkflowCalenderData(data?.data);
        setLoader(false);
    };

    useEffect(() => {
        getAllTaskByUser();
    }, [studyId]);

    const mapToCalendarEvents = () => {
        return WorkflowCalenderData?.map(event => ({
            title: event.title,
            start: event.date,
            backgroundColor: event.backgroundColor,
            borderColor: event.borderColor,
            textColor: event.textcolor
        }));
    };

    const getEventClassNames = (eventInfo) => {
        switch (eventInfo.event.extendedProps.status) {
            case 'pending':
                return 'event-pending';
            case 'finished':
                return 'event-finished';
            case 'not started':
                return 'event-not-started';
            default:
                return '';
        }
    };

    console.log("WorkflowCalenderData", WorkflowCalenderData);

    return (
        <>
            <div className="overflow-hidden p-2" style={{ height: "calc(100vh - 138px)" }}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        start: "prev,next",
                        center: "title",
                        end: "dayGridMonth,timeGridWeek,timeGridDay,list,today",
                    }}
                    weekends={true}
                    events={mapToCalendarEvents()}
                    eventClassNames={getEventClassNames}
                />
            </div>
        </>
    );
};

export default WorkflowCalender;
