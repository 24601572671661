import React, { useEffect, useState } from 'react'
import UseToggle from '../../Hooks/UseToggle'
import AddInvoiceDataModal from './AddInvoiceDataModal';
import { invoiceServices } from '../../APIServices/invoiceServices';
import Moment from 'react-moment';
import { ContextMenuTrigger } from 'react-contextmenu';
import RightClick from '../../Component/RightClick/RightClick';
import Paginations from '../../Hooks/Paginations';
import { NoDataFoundTable } from '../../Hooks/NoDataFoundTable';
import { Spinner, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import UpdateInvoiceData from './UpdateInvoiceData';
import Swal from 'sweetalert2';

const TaxInvoiceData = () => {
    const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
    const [invoiceSpouncerData, setInvoiceSpouncerData] = React.useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [activeSponcerId, setActiveSponcerId] = useState("");
    const [updateInvoiceDetails, setUpdateInvoiceDetails] = useState("");
    const [updateInvoiceId, setUpdateInvoiceId] = useState("");
    const [activeSpouncer, setActiveSpouncer] = useState([]);

    const getInvoiceBySponsor = async () => {
        let data = await invoiceServices.getInvoiceBySponsor();
        setInvoiceSpouncerData(data?.data);
        setLoader(false);
    };

    const getSponsorByStatus = async () => {
        let data = await invoiceServices.getSponsorByStatus();     //For Invoice Getting Active Sponcer data
        setActiveSpouncer(data?.data);
    };



    useEffect(() => {
        getInvoiceBySponsor();
        getSponsorByStatus();
    }, []);

    const handleLoginClick = (_id) => {
        setLoader(true);
        window.location.href = `/invoice/${_id}`;
        setLoader(false);
    };

    const updateInvoice = (item) => {
        setToggle1();
        setUpdateInvoiceDetails(item);
        setUpdateInvoiceId(item?._id);
    };

    const deleteInvoice = async (_id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to Delete Invoice!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#103C5E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = {};
                data.status = status;
                let results = await invoiceServices.deleteInvoice(_id);
                if (results?.status_code === 200) {
                    getInvoiceBySponsor();
                }
            }
        });
    };

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = invoiceSpouncerData?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(invoiceSpouncerData?.length / itemsPerPage);

    return (
        <div>
            <>
                <div className="hstack  inputWorkFlow gap-2 px-1 border-bottom" style={{ padding: 6 }}>
                    <button
                        onClick={setToggle}
                        title="Add Quotations"
                        className="teams_button border rounded-1 fs-12"
                    >
                        <i className="fa-solid fa-plus text-muted mt-1"></i>
                    </button>
                </div>
                {loader ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "65vh" }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : result?.length > 0 ? (
                    <>
                        <div
                            className="team_table overflow-auto"
                            style={{ height: "calc(100vh - 130px" }}
                        >
                            <Table
                                id="resizable-table"
                                bordered
                                className="custom-table"
                                striped
                                border
                                hover
                            >
                                <thead className="position-sticky" style={{ top: -1 }}>
                                    <tr style={{ cursor: "pointer" }}>
                                        <th style={{ border: "1px solid #896e6e5e", width: 47 }}>
                                            Sr.
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 150 }}>
                                            Company Name
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 150 }}>
                                            Sponsor Name
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 135 }}>Currency</th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 135 }}>Amount Due</th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 135 }}>Invoice No</th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 135 }}>Due Date</th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 135 }}>Tax Invoice</th>

                                        <th style={{ border: "1px solid #896e6e5e", width: 173 }}>
                                            Create Date | Time
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 173 }}>
                                            Update Date | Time
                                        </th>
                                        <th style={{ width: 115 }} className="text-center">
                                            View
                                        </th>
                                        <th style={{ width: 115 }} className="text-center">
                                            Update
                                        </th>
                                        <th style={{ width: 80 }} className="text-center">
                                            Delete
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result?.map((item, index) => (
                                        <tr
                                            style={{ cursor: "pointer" }}
                                            key={item.id}
                                            className={
                                                selectedRow === item?._id ? "selected" : ""
                                            }
                                            onClick={() => setSelectedRow(item._id)}
                                        >
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {startIndex + index + 1}
                                                </ContextMenuTrigger>
                                                <RightClick
                                                    ContextId={`clientData${index}`}
                                                    Menu2
                                                    MenuName2={"Update"}
                                                    icons2={
                                                        <i class="fa-solid fa-pen-to-square text-white"></i>
                                                    }
                                                    className2={"text-white"}
                                                    handleClick2={() => updateInvoice(item)}
                                                    Menu3
                                                    MenuName3={"Delete"}
                                                    icons3={
                                                        <i class="fa-solid fa-trash-can text-danger"></i>
                                                    }
                                                    className3={"text-white "}
                                                    handleClick3={() => deleteInvoice(item?._id)}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.companyName}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.sponsorName}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.currency}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.amountDue}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.invoiceNo}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.dueDate}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.taxInvoice}
                                                </ContextMenuTrigger>
                                            </td>


                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    <Moment format="DD MMM YYYY | HH:MM">
                                                        {item.created_at}
                                                    </Moment>
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                {" "}
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    <Moment format="DD MMM YYYY | HH:MM">
                                                        {item.updated_at}
                                                    </Moment>
                                                </ContextMenuTrigger>
                                            </td>
                                            <td className="button_type text-center">
                                                <NavLink
                                                    to={`clientstudy/${item?._id}`}
                                                    onClick={() => handleLoginClick(item._id)}
                                                >
                                                    <button
                                                        style={{
                                                            fontSize: 11,
                                                            borderRadius: 3,
                                                            padding: "2px 8px",
                                                        }}
                                                    >
                                                        View Invoice
                                                    </button>
                                                </NavLink>
                                            </td>
                                            <td className="button_type text-center">
                                                <button
                                                    style={{
                                                        fontSize: 11,
                                                        borderRadius: 3,
                                                        padding: "2px 8px",
                                                    }}
                                                    onClick={() => updateInvoice(item)}
                                                >
                                                    Update
                                                </button>
                                            </td>
                                            <td className="text-center">
                                                <span className="text-danger">
                                                    <i
                                                        className="fa-solid fa-trash"
                                                        onClick={() => deleteInvoice(item?._id)}
                                                    ></i>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="position-sticky bottom-0 bg-white">
                                <Paginations
                                    totalPage={totalPage}
                                    pageNumber={pageNumber}
                                    itemsPerPage={itemsPerPage}
                                    totalItems={invoiceSpouncerData?.length}
                                    setPageNumber={setPageNumber}
                                    setItemsPerPage={setItemsPerPage}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "80vh",
                            justifyContent: "center",
                        }}
                    >
                        <NoDataFoundTable MSG={"Invoice Data is Not Found"} />
                    </div>
                )}
            </>
            <AddInvoiceDataModal
                Show={!toggle}
                Hide={setToggle}
                Size={"lg"}
                Title="Add User"
                Submit={"Add User"}
                activeSpouncer={activeSpouncer}
                getInvoiceBySponsor={getInvoiceBySponsor}
                setActiveSponcerId={setActiveSponcerId}
                activeSponcerId={activeSponcerId}

            />
            <UpdateInvoiceData
                Show={!toggle1}
                Hide={setToggle1}
                Size={"lg"}
                updateInvoiceDetails={updateInvoiceDetails}
                getInvoiceBySponsor={getInvoiceBySponsor}
                updateInvoiceId={updateInvoiceId}
                activeSpouncer={activeSpouncer}
                setActiveSponcerId={setActiveSponcerId}
                setActiveSpouncer={setActiveSpouncer}
                Title="Add User"
                Submit={"Add User"}

            />
        </div>
    )
}

export default TaxInvoiceData


// import React, { useRef, useState } from "react";
// import { authServices } from "../../../APIServices/authServices";
// import { Alert, Form, Spinner } from "react-bootstrap";
// const TwoFactorAuthByApps = ({ appTwoFA, setNextBy, getTwoFactorAuthType }) => {
//     const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
//     const otpInputs = useRef([]);
//     const [statusMessage, setStatusMessage] = useState("");
//     const [loader, setLoader] = useState(false);
//     const handleInputChange = (index, e) => {
//         const value = e.target.value;
//         if (value === "" || /^[0-9]$/.test(value)) {
//             const updatedValues = [...otpValues];
//             updatedValues[index] = value;
//             setOtpValues(updatedValues);
//             if (value !== "" && index < 5) {
//                 otpInputs.current[index + 1].focus();
//             }
//             if (value === "" && index > 0) {
//                 otpInputs.current[index - 1].focus();
//             }
//         }
//     };
//     const [base64, setBase64] = useState("");
//     const [secret, setSecret] = useState("");
//     const [check, setCheck] = useState(false);
//     const [disable, setDisable] = useState(false);
//     const [showQR, setShowQR] = useState(false);

//     const generateQRCode = async () => {
//         setLoader(true);
//         const data = await authServices.generateQRCode();
//         setLoader(false);
//         setBase64(data.base64);
//         setSecret(data.secret);
//     };
//     const showQRCode = async () => {
//         const data = await authServices.showQRCode();
//         setBase64(data);
//         if (data) {
//             setShowQR(true);
//         }
//     };
//     const enableTwoFactorAuth = async () => {
//         setLoader(true);
//         const userData = { secret, OTP: otpValues };
//         const data = await authServices.enableTwoFactorAuth(userData);
//         setStatusMessage(data);
//         setLoader(false);
//         if (data?.statusCode === 200) {
//             getTwoFactorAuthType();
//             setOtpValues(["", "", "", "", "", ""]);
//         }
//         setTimeout(() => {
//             setStatusMessage("");
//         }, 3000);
//     };
//     const disableTwoFactorAuth = async () => {
//         setLoader(true);
//         const userData = { secret, otp: otpValues };
//         const data = await authServices.disableTwoFactorAuth(userData);
//         setStatusMessage(data);
//         setLoader(false);
//         if (data?.statusCode === 200) {
//             getTwoFactorAuthType();
//             setDisable(false);
//             setShowQR(false);
//             setCheck(false);
//             setNextBy(true);
//             setOtpValues(["", "", "", "", "", ""]);
//             setBase64("");
//             setSecret("");
//         }
//         setTimeout(() => {
//             setStatusMessage("");
//         }, 3000);
//     };
//     const getQRDataForDisable = async () => {
//         const data = await authServices.getQRData();
//         setSecret(data);
//         setDisable(true);
//     };
//     return (
//         <>
//             {!disable ? (
//                 <>
//                     {!appTwoFA ? (
//                         <>
//                             {!check ? (
//                                 <>
//                                     {base64 ? (
//                                         <div className="qr__imag">
//                                             <img
//                                                 style={{ color: "red" }}
//                                                 src={base64}
//                                                 alt="QR Code"
//                                             />
//                                         </div>
//                                     ) : (
//                                         <>
//                                             <div className="qr__imag mb-3">
//                                                 <div className="security-shield">
//                                                     <i className="fa-solid fa-unlock mb-2"></i>
//                                                     <p className="m-0">
//                                                         <b>Disabled</b>
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                             <p style={{ fontSize: 12, textAlign: "center" }}>
//                                                 To enable Apps 2FA, generate QR. After generating the
//                                                 QR, you have to scan the QR with the Authenticator app,
//                                                 after that the OTP will be automatically sent to your
//                                                 device.
//                                             </p>
//                                         </>
//                                     )}
//                                     {base64 ? (
//                                         <div className="hstack gap-3 justify-content-end pt-3 w-full">
//                                             <button
//                                                 className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                                 onClick={() => setBase64("")}
//                                             >
//                                                 Back
//                                             </button>
//                                             <button
//                                                 className="p-1 px-3 border rounded-1 text-white bg-color fs-13 w-100"
//                                                 onClick={() => generateQRCode()}
//                                             >
//                                                 {loader && (
//                                                     <Spinner style={{ width: 14, height: 14 }} />
//                                                 )}{" "}
//                                                 {base64 ? "Re-Generate QR" : "Generate QR"}
//                                             </button>
//                                             <button
//                                                 className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                                 onClick={() => {
//                                                     setCheck(true);
//                                                 }}
//                                             >
//                                                 Next
//                                             </button>
//                                         </div>
//                                     ) : (
//                                         <div className="hstack gap-3 justify-content-end pt-2">
//                                             <button
//                                                 className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                                 onClick={() => setNextBy(false)}
//                                             >
//                                                 Back
//                                             </button>
//                                             <button
//                                                 className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                                 style={{ width: 140 }}
//                                                 onClick={() => generateQRCode()}
//                                             >
//                                                 {loader && (
//                                                     <Spinner style={{ width: 14, height: 14 }} />
//                                                 )}{" "}
//                                                 {base64 ? "Re-Generate QR" : "Generate QR"}
//                                             </button>
//                                         </div>
//                                     )}
//                                 </>
//                             ) : (
//                                 <>
//                                     <center>
//                                         <Form.Label className="fw-bold mb-0">Enter OTP</Form.Label>
//                                     </center>
//                                     <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
//                                         {otpValues.map((value, index) => (
//                                             <input
//                                                 key={index}
//                                                 type="text"
//                                                 pattern="\d*"
//                                                 maxLength="1"
//                                                 className={`border text-center otp__digit otp__field__${index + 1
//                                                     }`}
//                                                 style={{ height: 35, width: 35 }}
//                                                 value={value}
//                                                 onChange={(e) => handleInputChange(index, e)}
//                                                 ref={(input) => (otpInputs.current[index] = input)}
//                                             />
//                                         ))}
//                                     </div>
//                                     <div className="hstack gap-2 justify-content-between">
//                                         <button
//                                             className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                             onClick={() => {
//                                                 setCheck(false);
//                                                 setOtpValues(["", "", "", "", "", ""]);
//                                             }}
//                                         >
//                                             Back
//                                         </button>
//                                         <button
//                                             className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                             onClick={() => enableTwoFactorAuth()}
//                                         >
//                                             {loader && <Spinner style={{ width: 14, height: 14 }} />}{" "}
//                                             Enable
//                                         </button>
//                                     </div>
//                                     {statusMessage && (
//                                         <Alert
//                                             className="fs-10 py-1 px-1 m-0 text-center mt-2"
//                                             variant={
//                                                 statusMessage?.statusCode === 200 ? "success" : "danger"
//                                             }
//                                         >
//                                             {statusMessage?.customMessage}
//                                         </Alert>
//                                     )}
//                                 </>
//                             )}
//                         </>
//                     ) : (
//                         <>
//                             {!showQR ? (
//                                 <>
//                                     <div className="qr__imag">
//                                         <div className="security-shield">
//                                             <i className="fa-solid fa-lock mb-2"></i>
//                                             <p className="m-0">
//                                                 <b>Enabled (2FA by Apps)</b>
//                                             </p>
//                                         </div>
//                                     </div>
//                                     <div className="hstack gap-3 justify-content-center pt-3">
//                                         <button
//                                             className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                             onClick={() => showQRCode()}
//                                         >
//                                             Show QR
//                                         </button>
//                                         <button
//                                             className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                             onClick={() => getQRDataForDisable()}
//                                         >
//                                             Disable
//                                         </button>
//                                     </div>
//                                 </>
//                             ) : (
//                                 <>
//                                     <div className="qr__imag">
//                                         {base64 && <img src={base64} alt="QR Code" />}
//                                     </div>
//                                     <div className="hstack gap-3 justify-content-center pt-3">
//                                         <button
//                                             className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                             onClick={() => setShowQR(false)}
//                                         >
//                                             Hide QR
//                                         </button>
//                                         <button
//                                             className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                                             onClick={() => getQRDataForDisable()}
//                                         >
//                                             Disable
//                                         </button>
//                                     </div>
//                                 </>
//                             )}
//                         </>
//                     )}
//                 </>
//             ) : (
//                 <>
//                     <center>
//                         <Form.Label className="mb-0 fw-bold">Enter OTP</Form.Label>
//                     </center>
//                     <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
//                         {otpValues.map((value, index) => (
//                             <input
//                                 key={index}
//                                 type="text"
//                                 pattern="\d*"
//                                 maxLength="1"
//                                 className={`border text-center otp__digit otp__field__${index + 1
//                                     }`}
//                                 style={{ height: 35, width: 35 }}
//                                 value={value}
//                                 onChange={(e) => handleInputChange(index, e)}
//                                 ref={(input) => (otpInputs.current[index] = input)}
//                             />
//                         ))}
//                     </div>
//                     <div className="hstack gap-2 justify-content-between">
//                         <button
//                             className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                             onClick={() => {
//                                 setDisable(false);
//                                 setOtpValues(["", "", "", "", "", ""]);
//                             }}
//                         >
//                             Back
//                         </button>
//                         <button
//                             className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
//                             onClick={() => disableTwoFactorAuth()}
//                         >
//                             {loader && <Spinner style={{ width: 14, height: 14 }} />} Disable
//                             2FA
//                         </button>
//                     </div>
//                     {statusMessage && (
//                         <Alert
//                             className="fs-10 py-1 px-1 m-0 text-center mt-2"
//                             variant={statusMessage?.statusCode === 200 ? "success" : "danger"}
//                         >
//                             {statusMessage?.customMessage}
//                         </Alert>
//                     )}
//                 </>
//             )}
//         </>
//     );
// };
// export default TwoFactorAuthByApps;




