import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import Paginations from "../../../Hooks/Paginations";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../Hooks/NoDataFoundTable";

const LogTableData = ({ logData, tableRef, onMouseDown, loading }) => {
  const getActivityColor = (activity) => {
    switch (activity.trim().toLowerCase()) {
      case "login successfully":
        return "green";
      case "login failed":
        return "red";
      case "login pending":
        return "blue";
      default:
        return "inherit";
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = logData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(logData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <SpinerDataLoader />
      ) : result?.length > 0 ? (
        <>
          <div
            className="team_table overflow-auto"
            style={{ height: "calc(100vh-130px)" }}
          >
            <Table
              id="resizable-table"
              className="custom-table"
              ref={tableRef}
              striped
              bordered
              hover
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    style={{ width: 45 }}
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    Sr.
                  </th>
                  <th
                    style={{ width: 145 }}
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    IPs Address
                  </th>
                  <th
                    style={{ width: 210 }}
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    UserID
                  </th>
                  <th
                    style={{ width: 100 }}
                    onMouseDown={(e) => onMouseDown(e, 3)}
                  >
                    Type
                  </th>
                  <th
                    style={{ width: 160 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Activity
                  </th>
                  <th
                    style={{ width: 130 }}
                    onMouseDown={(e) => onMouseDown(e, 5)}
                  >
                    Time Login
                  </th>
                  <th
                    style={{ width: 160 }}
                    onMouseDown={(e) => onMouseDown(e, 6)}
                  >
                    City
                  </th>
                  <th
                    style={{ width: 103 }}
                    onMouseDown={(e) => onMouseDown(e, 7)}
                  >
                    Country
                  </th>
                  <th
                    style={{ width: 160 }}
                    onMouseDown={(e) => onMouseDown(e, 8)}
                  >
                    Region
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((log, index) => (
                  <tr key={index}>
                    <td>{startIndex + index + 1}</td>
                    <td>{log.ip}</td>
                    <td>{log.userID}</td>
                    <td>{log.type}</td>
                    <td className="text-capitalize">
                      <div style={{ color: getActivityColor(log.activity) }}>
                        {log.activity}
                      </div>
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY">{log.timestamp}</Moment>
                    </td>
                    <td>{log.location.city}</td>
                    <td>{log.location.country}</td>
                    <td>{log.location.region}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0  bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={logData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoDataFoundTable MSG={"Log Data Not Found"} />
      )}
    </>
  );
};

export default LogTableData;
