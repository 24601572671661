import React from "react";
import LoginImage from "../../Images/loginimage.png";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const SignUpSuccess = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={8} className="p-0">
            <div className="loginimg">
              <img src={LoginImage} alt="loginimage" />
            </div>
          </Col>
          <Col md={4}>
            <div className="loginside" style={{ marginTop: "150px" }}>
              <div className=".loginsection_two text-center w-100">
                <div className="">
                  <i
                    style={{ color: "green", fontSize: 50 }}
                    className="fa-solid fa-circle-check"
                    alt="Registration Successful"
                  ></i>
                </div>
                <Card className=" border-0">
                  <Card.Body>
                    <section className="sign-up w-100 m-auto py-4">
                      <h5 className="text-center mb-3">
                        <b>
                          Thanks! your account has been successfully created.
                        </b>
                      </h5>
                      <Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          className="mb-3 text-center"
                        >
                          <Card.Text>
                            Please check your inbox, your
                            <b>login credentials</b> is sent on your
                            <b>email</b>, which will be require for
                            <b>Login </b> your account.
                          </Card.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="text-center">
                          <NavLink to={"/"}>
                            <Button
                              type="submit"
                              variant="outline-dark"
                              className="loginButton mt-3 w-25"
                            >
                              Login
                            </Button>
                          </NavLink>
                        </Form.Group>
                      </Row>
                    </section>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignUpSuccess;
