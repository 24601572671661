import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const elearningServices = {
  addTrainingFile,
  getAllTraining,
  addElearningMeta,
  addTrainingQuiz,
  getAllTrainingQuiz,
  getAllTrainingFile,
  getAllElearningMeta,
  updateTrainingFile,
  updateTrainingquiz,
  updateElearningMeta,
  deleteElearningMeta,
  deleteTrainingQuestion,
  addAssignedUserForTraining,
  getUserTrainingQues,
  getAllUsersByElearning,
  getTrainingQuesByElearning,
  addUserQuizQues,
  deleteUserTrainingAns,
  getAllElearningLog,
  getUserProgressReport,
  getTrainingForUser,
  getUserSideProgressReport,
  getViewProgressReport,
};


// add ElearningMeta data
async function addElearningMeta(userData) {
  const response = await fetch(
    `${config.API_URL}addElearningMeta`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// add Elearning file data
async function addTrainingFile(formData, trainingID) {
  const response = await axios.post(
    `${config.API_URL}addTrainingFile/${trainingID}`,
    formData, {
    headers: {
      "Authorization": headers.authorization,
    },
  }
  );
  const data = await response.data;
  handleResponse(data);
  return data;
}

// add Elearning Quiz data
async function addTrainingQuiz(trainingID, userData) {
  const response = await fetch(
    `${config.API_URL}addTrainingQuiz/${trainingID}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//addUserQuizQues
async function addUserQuizQues(trainingID, userData) {
  const response = await fetch(
    `${config.API_URL}addUserQuizQues/${trainingID}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// add Elearning Quiz data
async function addAssignedUserForTraining(trainingID, userData) {
  const response = await fetch(
    `${config.API_URL}addAssignedUserForTraining/${trainingID}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// get e learning data
async function getAllElearningMeta(studyId) {
  const response = await fetch(
    `${config.API_URL}getAllElearningMeta/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// async function getAllElearningMeta(studyId) {
//   const url = studyId 
//     ? `${config.API_URL}getAllElearningMeta?studyId=${studyId}` 
//     : `${config.API_URL}getAllElearningMeta`;
//   const response = await fetch(url, headersWithAuthWithoutBody("GET", headers));
//   const data = await response.json();
//   return data;
// }



//get e getAllElearningLog
async function getAllElearningLog() {
  const response = await fetch(
    `${config.API_URL}getAllElearningLog`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get e training data
async function getAllTrainingFile() {
  const response = await fetch(
    `${config.API_URL}getAllTrainingFile`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get e training data
async function getAllTrainingQuiz() {
  const response = await fetch(
    `${config.API_URL}getAllTrainingQuiz`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get e training data
async function getAllTraining(trainingID) {
  const response = await fetch(
    `${config.API_URL}getAllTraining/${trainingID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getViewProgressReport
async function getViewProgressReport(trainingID, userID) {
  const response = await fetch(
    `${config.API_URL}getViewProgressReport/${trainingID}/${userID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}



//  getUserProgressReport
async function getUserProgressReport(trainingID) {
  const response = await fetch(
    `${config.API_URL}getUserProgressReport/${trainingID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getUserSideProgressReport
async function getUserSideProgressReport(trainingID) {
  const response = await fetch(
    `${config.API_URL}getUserSideProgressReport/${trainingID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get getTrainingQuesByElearning
async function getTrainingQuesByElearning(trainingID) {
  const response = await fetch(
    `${config.API_URL}getTrainingQuesByElearning/${trainingID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get getAllUsersByElearning
async function getAllUsersByElearning(trainingID) {
  const response = await fetch(
    `${config.API_URL}getAllUsersByElearning/${trainingID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}


// //For User Side
// async function getUserTrainingQues(studyId) {
//   const response = await fetch(
//     `${config.API_URL}getUserTrainingQues/${studyId}`,
//     headersWithAuthWithoutBody("GET", headers)
//   );
//   const data = await response.json();
//   return data;
// }

//For User Side  getUserTrainingQues
async function getUserTrainingQues(trainingIds) {
  const response = await fetch(
    `${config.API_URL}getUserTrainingQues/${trainingIds}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}


//getTrainingForUser
async function getTrainingForUser(studyId) {
  const response = await fetch(
    `${config.API_URL}getTrainingForUser/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Update  e learning data
async function updateElearningMeta(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateElearningMeta/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//Update  e training file data
async function updateTrainingFile(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateTrainingFile/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//Update  e training Quiz data
async function updateTrainingquiz(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateTrainingquiz/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//delete elearning data
async function deleteElearningMeta(_id) {
  const response = await fetch(
    `${config.API_URL}deleteElearningMeta/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//delete etraining data
async function deleteTrainingQuestion(_id) {
  const response = await fetch(
    `${config.API_URL}deleteTrainingQuestion/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//deleteUserTrainingAns
async function deleteUserTrainingAns(questionID, trainingID) {
  const response = await fetch(
    `${config.API_URL}deleteUserTrainingAns/${questionID}/${trainingID}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}