import React from "react";
import { Form } from "react-bootstrap";

const AdvanceTab = ({ minLength, setMinLength, maxLength, setMaxLength }) => {
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Min Length </b>
          </Form.Label>
          <Form.Control
            type="number"
            defaultValue={minLength}
            onChange={(e) => setMinLength(e.target.value)}
            placeholder="Enter Min Value"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Max Length </b>
          </Form.Label>
          <Form.Control
            type="number"
            defaultValue={maxLength}
            onChange={(e) => setMaxLength(e.target.value)}
            placeholder="Enter Max Value"
          />
        </Form.Group>
      </div>
    </>
  );
};

export default AdvanceTab;
