import React, { useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import UseToggle from "../../Hooks/UseToggle";
import { clientServices } from "../../APIServices/clientServices";
import Swal from "sweetalert2";
import UpdateClientModal from "./UpdateClientModal";
import Moment from "react-moment";
import Paginations from "../../Hooks/Paginations";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../Hooks/NoDataFoundTable";

const SponsorList = ({
  tableRef,
  onMouseDown,
  selectedRow,
  setSelectedRow,
  getAllSponsor,
  loading,
  clientData,
  setPropertiesData,
}) => {
  const { toggle1, setToggle1 } = UseToggle();
  const [clientDetails, setClientDetails] = useState("");


  const updateClientstatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Client Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await clientServices.updateSponsorClient(id, data);
        if (results?.status_code === 200) {
          getAllSponsor();
        }
      }
    });
  };

  const deleteClient = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Client!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await clientServices.deleteSponsorClient(_id);
        if (data?.status_code === 200) {
          getAllSponsor();
        }
      }
    });
  };

  const updateClient = (item) => {
    setToggle1();
    setClientDetails(item);
  };

  const handleRowClick = (item) => {
    setSelectedRow(item._id.$oid);
    setPropertiesData(item);
  };

  const resizerRefs = useRef([]);

  useEffect(() => {
    const resizers = resizerRefs.current;
    let startX, startWidth;

    const initResize = (e, index) => {
      startX = e.clientX;
      startWidth = resizers[index].parentElement.offsetWidth;
      document.addEventListener("mousemove", doResize);
      document.addEventListener("mouseup", stopResize);
    };

    const doResize = (e) => {
      const width = startWidth + (e.clientX - startX);
      resizers.forEach((resizer, index) => {
        if (index === parseInt(resizer.dataset.index)) {
          resizer.parentElement.style.width = `${width}px`;
        }
      });
    };

    const stopResize = () => {
      document.removeEventListener("mousemove", doResize);
      document.removeEventListener("mouseup", stopResize);
    };

    resizers.forEach((resizer, index) => {
      resizer.addEventListener("mousedown", (e) => initResize(e, index));
    });
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = clientData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(clientData?.length / itemsPerPage);

  useEffect(() => {
    getAllSponsor();
  }, []);

  return (
    <>
      {loading ? (
        <SpinerDataLoader />
      ) : result?.length > 0 ? (
        <>
          <div
            className="team_table overflow-auto"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <Table
              id="resizable-table"
              className="custom-table"
              ref={tableRef}
              striped
              border
              hover
            >
              <thead
                className="position-sticky bg-white px-2"
                style={{ top: -1 }}
              >
                <tr style={{ cursor: "pointer" }}>
                  <th
                    style={{ width: 15 }}
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    Sr.
                  </th>
                  <th
                    style={{ width: 80 }}
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Company Name
                  </th>
                  <th
                    style={{ width: 100 }}
                    onMouseDown={(e) => onMouseDown(e, 3)}
                  >
                    Website
                  </th>
                  <th
                    style={{ width: 70 }}
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Status
                  </th>
                  <th
                    style={{ width: 75 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Update Date | Time
                  </th>
                  <th style={{ width: 30, textAlign: "center" }} onMouseDown={(e) => onMouseDown(e, 5)}>View</th>
                  <th style={{ width: 30, textAlign: "center" }} onMouseDown={(e) => onMouseDown(e, 5)}>Update</th>
                  <th style={{ width: 30, textAlign: "center" }} onMouseDown={(e) => onMouseDown(e, 6)}>Remove</th>
                </tr>

              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={item.id}

                    className={selectedRow === item._id.$oid ? "selected" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      {startIndex + index + 1}
                    </td>
                    <td>
                      {item.companyName}
                    </td>
                    <td>
                      {item.website}
                    </td>
                    <td>
                      <select
                        className={`status-select ${item.status.toLowerCase()}`}
                        value={item.status}
                        onChange={(e) =>
                          updateClientstatus(item?._id?.$oid, e.target.value)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "0",
                          margin: "0",
                          border: "none",
                          boxSizing: "border-box",
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.updated_at}
                      </Moment>
                    </td>

                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => handleRowClick(item)}
                      >
                        View
                      </button>
                    </td>

                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => updateClient(item)}
                      >
                        Update
                      </button>
                    </td>
                    <td className="text-center">
                      <span className="text-danger">
                        <i
                          className="fa-solid fa-trash"
                          onClick={() => deleteClient(item?._id?.$oid)}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 mx-1">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={clientData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>


        </>
      ) : (
        <NoDataFoundTable MSG={"Sponsor Not Found!"} />
      )}
      <UpdateClientModal
        Show={!toggle1}
        Hide={setToggle1}
        clientDetails={clientDetails}
        setClientDetails={setClientDetails}
        getAllSponsor={getAllSponsor}
        Size={"md"}
        Title="Update Client Modal"
      />
    </>
  );
};

export default SponsorList;
