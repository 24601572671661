import { useCallback, useState, useReducer } from "react";

const UseToggle = () => {
  const [toggle, setToggle] = useReducer((show) => !show, true);
  const [toggle1, setToggle1] = useReducer((show) => !show, true);
  const [toggle2, setToggle2] = useReducer((show) => !show, true);
  const [toggle3, setToggle3] = useReducer((show) => !show, true);
  const [toggle4, setToggle4] = useReducer((show) => !show, true);
  const [toggle5, setToggle5] = useReducer((show) => !show, true);
  const [toggle6, setToggle6] = useReducer((show) => !show, true);
  const [toggle7, setToggle7] = useReducer((show) => !show, true);
  const [toggle8, setToggle8] = useReducer((show) => !show, true);
  const [toggle9, setToggle9] = useReducer((show) => !show, true);
  const [toggle10, setToggle10] = useReducer((show) => !show, true);
  const [toggle11, setToggle11] = useReducer((show) => !show, true);
  const [toggle12, setToggle12] = useReducer((show) => !show, true);
  const [toggle13, setToggle13] = useReducer((show) => !show, true);
  const [toggle14, setToggle14] = useReducer((show) => !show, true);
  const [toggle15, setToggle15] = useReducer((show) => !show, true);
  const [toggle16, setToggle16] = useReducer((show) => !show, true);
  const [toggle17, setToggle17] = useReducer((show) => !show, true);
  const [toggle18, setToggle18] = useReducer((show) => !show, true);
  const [toggle19, setToggle19] = useReducer((show) => !show, true);
  const [toggle20, setToggle20] = useReducer((show) => !show, true);

  const [loader, setLoader] = useState(true);

  const togglePassword = useCallback(
    (type) => (!type ? "text" : "password"),
    []
  );

  return {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    toggle4,
    setToggle4,
    toggle5,
    setToggle5,
    toggle6,
    setToggle6,
    toggle7,
    setToggle7,
    toggle8,
    setToggle8,
    toggle9,
    setToggle9,
    toggle10,
    setToggle10,
    toggle11,
    setToggle11,
    toggle12,
    setToggle12,
    toggle13,
    setToggle13,
    toggle14,
    setToggle14,
    toggle15,
    setToggle15,
    toggle16,
    setToggle16,
    toggle17,
    setToggle17,
    toggle18,
    setToggle18,
    toggle19,
    setToggle19,
    toggle20,
    setToggle20,

    loader,
    setLoader,

    togglePassword,
  };
};
export default UseToggle;
