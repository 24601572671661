import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import LoginImage from "../../Images/loginimage.png";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const forgetPassword = async () => {
    setLoading(true);
    let userData = { email };
    let data = await authServices.requestForResetPassword(userData);
    setLoading(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      // window.location.href = "/changepassword";
    }

    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email");
    }
  };

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col md={7} className="p-0">
              <div className="loginimg">
                <img src={LoginImage} alt="loginImage" />
              </div>
            </Col>
            <Col md={5} className="loginsection_two">
              <div className="loginside p-5">
                <div className="shadow p-5 rounded">
                  <div className="loginContent gap-3">
                    <h5 className="text-center"> <b>Reset Password</b></h5>
                  </div>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>
                      Email
                      <span style={{ color: "red", marginleft: "5px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter Email ID"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(e.target.value);
                      }}
                      disabled={loading}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <div className="button_type">
                      <NavLink to={"/changepassword"}>
                        <button
                          type="submit"
                          variant="outline-dark"
                          className=" mt-2 w-100"
                          onClick={forgetPassword}
                          disabled={!email || emailError || loading}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Reset"
                          )}
                        </button>
                      </NavLink>
                    </div>

                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    {emailError ? (
                      <p className=" m-0" style={{ color: "red" }}>
                        *{emailError}
                      </p>
                    ) : (
                      <p
                        className=""
                        style={
                          statusMessage?.statusCode === 200
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {statusMessage?.customMessage}
                      </p>
                    )}
                    <div className="mt-1">
                      <NavLink to="/">
                        <b>Back To Login</b>
                      </NavLink>
                    </div>
                  </div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;

