import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithBody,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const clientServices = {
  addClient,
  addSponsorClient,
  getAllSponsor,
  updateSponsorClient,
  deleteSponsorClient,
  getSponsorByStatus,
  searchSponsor,
  addProject,
  getAllProject,
  updateProject,
  deleteProject,
  addStudy,
  getAllStudy,
  updateStudy,
  deleteStudy,
  getUserLogs,
  getAllAssignedSponsor,  //user Side Api Services
};

//addClient
async function addClient(clientData) {
  const response = await fetch(
    `${config.API_URL}addClient`,
    headersWithAuth("POST", clientData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// addSponsorClient
async function addSponsorClient(userData) {
  const response = await fetch(
    `${config.API_URL}addSponsorClient`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getAllSponsor
async function getAllSponsor() {
  const response = await fetch(
    `${config.API_URL}getAllSponsor`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//  User Side getAllAssignedSponsor
async function getAllAssignedSponsor() {
  const response = await fetch(
    `${config.API_URL}getAllAssignedSponsor`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getUserLogs
async function getUserLogs() {
  const response = await fetch(
    `${config.API_URL}getUserLogs`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Update Sponsor Client
async function updateSponsorClient(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateSponsorClient/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getSponsorByStatus
async function getSponsorByStatus(status) {
  const response = await fetch(
    `${config.API_URL}getSponsorByStatus?status=${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//deleteSponsorClient
async function deleteSponsorClient(_id) {
  const response = await fetch(
    `${config.API_URL}deleteSponsorClient/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//searchSponsor
async function searchSponsor(name) {
  const response = await fetch(
    `${config.API_URL}searchSponsor?name=${name}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//add project
async function addProject(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addProject/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getAllProject
async function getAllProject(_id) {
  const response = await fetch(
    `${config.API_URL}getAllProject/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Update updateProject
async function updateProject(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateProject/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//deleteProject
async function deleteProject(_id) {
  const response = await fetch(
    `${config.API_URL}deleteProject/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//addStudy
async function addStudy(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addStudy/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//getAllStudy
async function getAllStudy(_id) {
  const response = await fetch(
    `${config.API_URL}getAllStudy/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// updateStudy
async function updateStudy(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateStudy/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//deleteStudy
async function deleteStudy(_id) {
  const response = await fetch(
    `${config.API_URL}deleteStudy/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
