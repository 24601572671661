import React, { useEffect, useState } from 'react'
import invoicelogo from "../../Images/galax logo.png"
import { Table } from 'react-bootstrap';
import { invoiceServices } from '../../APIServices/invoiceServices';
import { useParams } from 'react-router-dom';

const Invoice = () => {
    const [invoiceData, setInvoiceData] = useState([]);
    const { _id } = useParams();

    const getInvoiceById = async () => {
        let data = await invoiceServices.getInvoiceById(_id);
        setInvoiceData(data?.data);
    };

    console.log("invoiceData", invoiceData);


    useEffect(() => {
        getInvoiceById();
    }, [_id]);

    console.log("invoiceData", invoiceData);


    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <div className="hstack justify-content-between">
                <h2 style={{ textAlign: 'center' }}>Tax Invoice</h2>  <img src={invoicelogo} alt='logo' />
            </div>
            <div className="mb-2">
                <Table bordered style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <td rowSpan="9">
                                <strong>Galaxbiotech Solution Private Limited</strong><br />
                                T6 3rd Main Rd Samrat L/O, Sarvobhogam Nagar Arkere,<br />
                                Bannerghatta Road, Bangalore South, Bangalore- 560076, Karnataka<br />
                                <strong>Contact Name:</strong> Gaurav Chauhan<br />
                                <strong>Email:</strong> gaurav.chauhan@galaxbiotech.com
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="text-end"><b>Original Copy</b></td>
                        </tr>
                        <tr>
                            <td className="text-end"><strong>TAX INVOICE</strong></td>
                            <td className="text-end">{invoiceData?.taxInvoice}</td>
                        </tr>
                        <tr>
                            <td className="text-end"><strong>Amount Due</strong></td>
                            <td className="text-end">{invoiceData?.amountDue}</td>
                        </tr>
                        <tr>
                            <td className="text-end"><strong>Invoice Date</strong></td>
                            <td className="text-end">{invoiceData?.dueDate}</td>
                        </tr>
                        <tr>
                            <td className="text-end"><strong>Country of Supply</strong></td>
                            <td className="text-end">{invoiceData?.country}</td>
                        </tr>
                        <tr>
                            <td className="text-end"><strong>Currency</strong></td>
                            <td className="text-end">{invoiceData?.currency}</td>
                        </tr>
                        <tr>
                            <td className="text-end"><strong>Due Date</strong></td>
                            <td className="text-end">{invoiceData?.dueDate}</td>
                        </tr>
                    </tbody>
                </Table>

            </div>

            <div className="mb-2">
                <Table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                    <tbody>
                        {invoiceData?.sponsorDetail?.map((item, index) => (
                            <tr>
                                <td colSpan="6">
                                    <strong>Bill To</strong><br />
                                    <strong>{invoiceData?.sponsorName}</strong><br />
                                    {item?.Address},{item?.CityName},{item?.StateName},{item?.CountryName}, {item?.zipCode}<br />
                                    <strong>Contact Name:</strong> {item?.sponsor_name}<br />
                                    <strong>Email:</strong> {item?.email}<br />
                                    <strong>Phone No:</strong> {item?.officePhoneNumber}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="mb-2">
                <Table bordered style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>

                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Description of Services</th>
                            <th>HSN/SAC</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Percentage</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceData?.service?.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.serviceName}</td>
                                <td>{item?.hsnSac}</td>
                                <td>{item?.unit}</td>
                                <td>{item?.price}</td>
                                <td>0%</td>
                                <td>$ XXXX</td>

                            </tr>
                        ))}
                        <tr>
                            <td colSpan="5" style={{ textAlign: 'right' }}>CGST @ 0%</td>
                            <td>0%</td>
                            <td>$ 0</td>
                        </tr>
                        <tr>
                            <td colSpan="5" style={{ textAlign: 'right' }}>SGST @ 0%</td>
                            <td>0%</td>
                            <td>$ 0</td>
                        </tr>
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'right' }}>
                                <strong>Total Taxable Value</strong>
                            </td>
                            <td>$ XXXX</td>
                        </tr>
                        <tr>
                            <td colSpan="7">
                                <strong>Total Value in words (USD):</strong> XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                            </td>
                        </tr>


                    </tbody>
                </Table>
            </div>

        </div>
    );

}

export default Invoice
