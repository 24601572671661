import React, { useState } from "react";
import { Card, Collapse, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const GeneralTab = ({
  label,
  setLabel,
  color,
  setColor,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  required,
  setRequired,
  customize,
  setCustomize,
  orderNo,
  setOrderNo,
  help,
  setHelp,
  columns,
  setColumns,
  setRows,
  rows,
}) => {
  const [open, setOpen] = useState(false);
  const [editingHeaderIndex, setEditingHeaderIndex] = useState(null);

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };

  const deleteRow = () => {
    if (rows?.length > 0) {
      setRows(rows.slice(0, -1));
    }
  };

  const deleteColumn = () => {
    if (columns?.length > 0) {
      const updatedColumns = columns.slice(0, -1);
      const lastColumn = columns[columns?.length - 1];
      setColumns(updatedColumns);
      setRows(
        rows.map((row) => {
          const updatedRow = { ...row };
          delete updatedRow[lastColumn];
          return updatedRow;
        })
      );
    }
  };

  const handleHeaderChange = (e, index) => {
    const newHeaderValues = [...columns];
    newHeaderValues[index] = e.target.value;
    setColumns(newHeaderValues);
  };


  const addColumnData = async () => {
    const newColumn = {
      name: `New Column ${columns?.length + 1}`,
      type: "text"
    };
    let newColumnData = [...columns, newColumn]
    setColumns(newColumnData);
    if (rows.length > 0) {
      const newRows = newColumnData?.map(column => ({
        name: column.name,
        value: '',
      }))
      setRows([...rows, newRows]);
    }
  }

  const addRowData = async () => {
    const newRows = columns?.map(column => ({
      name: column.name,
      value: '',
    }))
    setRows([...rows, newRows]);
  }

  return (
    <>
      <div className="mb-2 px-2">
        <Form.Group>
          <div className="hstack justify-content-between">
            <Form.Label>
              <b>Label</b>
            </Form.Label>
            {label && (
              <Form.Check
                type="switch"
                label="Customize Label"
                onChange={setCustomization}
                checked={customize}
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Table Label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Group>
      </div>
      <Collapse in={customize}>
        <div className="p-2">
          <Form.Label className="mb-1">
            <b>Customization</b>
          </Form.Label>
          <Card className="toogle-card shadow-sm border">
            <div className="hstack border-bottom">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
              >
                <div className="date-icon">
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bold"> Text Bold</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsBold(!isBold)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-bold ${isBold ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsItalic(!isItalic)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-italic ${isItalic ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
            </div>
            <div className="p-2">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  <b>Output</b>
                </Form.Label>
                <Card className="border h-1">
                  <Card.Body>
                    <Card.Text
                      className="text-center"
                      style={{
                        color,
                        fontWeight: isBold ? "bold" : "normal",
                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {label || "Output Result"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Check
                className="requird_form_check mb-2"
                type="switch"
                aria-label="option 1"
                checked={required}
                onClick={(e) => setRequired(e.target.value)}
                label="Required"
              />
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Order no</b>
                </Form.Label>
                <Form.Control
                  type="text and number"
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                  placeholder="Enter Order no"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Help </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="help Description"
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
        </div>
      </Collapse>
      <div className="fixed-buttongroup  mb-4 p-3">
        <div className="button_type gap-2 d-flex">
          <button onClick={addColumnData}>Add Column</button>
          <button disabled={columns?.length === 0} onClick={addRowData}>Add Row</button>
          <button onClick={deleteRow} className="bg-danger" disabled={rows.length === 0}>Delete Row</button>
          <button onClick={deleteColumn} className="bg-danger" disabled={columns.length === 0}>Delete Column</button>
        </div>
      </div>
      <div className="table-container overflow-auto m-1">
        <Table bordered>
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={index} className="p-1">
                  <Form.Control
                    type="text"
                    className="templateTable"
                    placeholder="Enter Your Table Header"
                    value={item.name}
                    onChange={(e) => handleHeaderChange(e, index)}
                    autoFocus
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((data, colIndex) => (
                  <td key={colIndex}>
                    <Form.Control
                      type="text"
                      className="border-0 rounded-0"
                      value={data.value}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[rowIndex][colIndex].value = e.target.value;
                        setRows(updatedRows);
                      }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default GeneralTab;