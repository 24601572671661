import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { Table } from "react-bootstrap";
import Pagination from "../../../Hooks/Pagination";
import { NoDataFoundTable, SpinerDataLoader } from "../../../Hooks/NoDataFoundTable";
import { elearningServices } from "../../../APIServices/elearningServices";
import Moment from "react-moment";
import ProgressReport from "../../ELearning/ProgressReport";
import UseToggle from "../../../Hooks/UseToggle";
import ClientSideProgressReportOffCanvas from "./ClientSideProgressReportOffCanvas";
import Paginations from "../../../Hooks/Paginations";

const ELearningActivity = ({ elearningID }) => {
  const [loader, setLoadeer] = useState(true);
  const { toggle1, setToggle1 } = UseToggle();
  const [elearnigActivityData, setElearningActivityData] = useState([]);
  const [progressTrainingID, setProgressTrainingID] = useState("");
  const [userID, setUserID] = useState("")

  const getUserProgressReport = async () => {
    let data = await elearningServices.getUserProgressReport(elearningID);
    console.log("data", data);
    setElearningActivityData(data?.data);
    setLoadeer(false)
  };

  useEffect(() => {
    getUserProgressReport();
  }, [elearningID]);

  const handleClickID = (item) => {
    setToggle1();
    setProgressTrainingID(item.trainingID)
    setUserID(item?.userID)
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = elearnigActivityData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(elearnigActivityData?.length / itemsPerPage);

  console.log("elearnigActivityData", elearnigActivityData);


  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : result?.length > 0 ? (
          <div className="team_table">
            <Table
              id="resizable-table"
              striped
              bordered
              hover
              className="custom-table"
            >
              <thead className="position-sticky bg-white px-2" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: 45 }}>Sr.</th>
                  <th>Name</th>
                  <th>Training Name</th>
                  <th>Assign Date | Time</th>
                  <th style={{ width: 100 }}>View Progress</th>
                  <th style={{ width: 100 }}>View Certificate</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.userName}</td>
                    <td>{item.trainingName}</td>
                    <td><Moment format="DD MMM YYYY | HH:MM">{item.assignDateTime}</Moment></td>
                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => handleClickID(item)}
                      >
                        View Progress
                      </button>
                    </td>
                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                      >
                        View Certificate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={elearnigActivityData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        ) : (
          <NoDataFoundTable MSG={"Elearning Activity Is Not Foud"} />
        )}
      </SplitPane>
      <ClientSideProgressReportOffCanvas Show={!toggle1} Hide={setToggle1} Title={"View Report"} progressTrainingID={progressTrainingID} userID={userID} />
    </>
  );
};

export default ELearningActivity;
