import React, { useState, useRef, useLayoutEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
import * as d3 from "d3"; // Import d3 to access the zoom functionality
import CustomNodeContent from "./CustomNodeContent";
import CustomExpandButton from "./CustomExpandButton";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { MdCropFree } from "react-icons/md";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import { Collapse, InputGroup } from "react-bootstrap";
import OrgAddUserOffcanvas from "./OrgAddUserOffcanvas";


const OrganizationalChart = (props) => {
  const d3Container = useRef(null);
  const [cardShow, setCardShow] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [openOverviewMap, setOpenOverviewMap] = useState(false);
  const overviewContainer = useRef(null);
  const handleShow = () => setCardShow(true);
  const handleClose = () => setCardShow(false);

  useLayoutEffect(() => {
    const toggleDetailsCard = (nodeId) => {
      handleShow();
      setEmployeeId(nodeId);

    };
    const chart = new OrgChart();

    if (props.data && d3Container.current) {
      chart
        .container(d3Container.current)
        .data(props.data)
        .nodeWidth((d) => 220)
        .nodeHeight((d) => 85)
        .compactMarginBetween((d) => 50)
        .onNodeClick((d) => {
          toggleDetailsCard(d);
        })
        .buttonContent((node, state) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CustomExpandButton {...node.node} />
          );
        })
        .nodeContent((d) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CustomNodeContent {...d} />
          );
        })
        .render();

      // Add zoom functionality to the main chart
      const svg = d3.select(d3Container.current).select("svg");

      // Check if SVG is available
      if (!svg.empty()) {
        const g = svg.select("g");

        const zoom = d3
          .zoom()
          .scaleExtent([0.5, 5]) // Set the zoom scale limits
          .on("zoom", (event) => {
            g.attr("transform", event.transform); // Apply the zoom and pan transform to the group
          });

        svg.call(zoom);

        // Zoom in, Zoom out, and Fit to Screen functions
        d3.select("#zoom-in").on("click", () => {
          zoom.scaleBy(svg.transition().duration(500), 1.2); // Zoom in by 20%
        });

        d3.select("#zoom-out").on("click", () => {
          zoom.scaleBy(svg.transition().duration(500), 0.8); // Zoom out by 20%
        });

        d3.select("#fit-to-screen").on("click", () => {
          const bounds = g.node().getBBox(); // Get bounding box of the chart
          const parent = d3Container.current;
          const fullWidth = parent.clientWidth;
          const fullHeight = parent.clientHeight;

          const width = bounds.width;
          const height = bounds.height;
          const midX = bounds.x + width / 2;
          const midY = bounds.y + height / 2;

          const scale = Math.min(fullWidth / width, fullHeight / height);
          const translate = [
            fullWidth / 2 - scale * midX,
            fullHeight / 2 - scale * midY,
          ];

          svg
            .transition()
            .duration(500)
            .call(
              zoom.transform,
              d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
            );
        });

        // Your overview map code...

      } else {
        console.error("SVG not rendered yet.");
      }
    }
  }, [props.data]);

  return (
    <div className="org-chart-container position-relative">
      <div className="org-chart" ref={d3Container}></div>
      <Collapse in={openOverviewMap}>
        <div className="overview border position-fixed z-3 bg-white overflow-hidden">
          <div className="overview-map" ref={overviewContainer}></div>
        </div>
      </Collapse>
      <InputGroup className="rounded-0 zoom-controls justify-content-end position-absolute bottom-0">
        <InputGroup.Text
          title="Zoom In"
          id="zoom-in"
          className="px-3 bg-white cursor-pointer rounded-0 border-bottom"
        >
          <CiSquarePlus />
        </InputGroup.Text>
        <InputGroup.Text
          title="Zoom Out"
          id="zoom-out"
          className="px-3 bg-white cursor-pointer rounded-0 border-bottom"
        >
          <CiSquareMinus />
        </InputGroup.Text>
        <InputGroup.Text
          title="Fit to screen"
          id="fit-to-screen"
          className="px-3 bg-white cursor-pointer rounded-0 border-bottom"
        >
          <MdCropFree />
        </InputGroup.Text>
        <InputGroup.Text
          title="Open Overview Map"
          onClick={() => setOpenOverviewMap(!openOverviewMap)}
          className={`px-3 bg-white cursor-pointer rounded-0 border-bottom border-end-0 ${openOverviewMap && "active"
            }`}
        >
          <LiaMapMarkedAltSolid />
        </InputGroup.Text>
      </InputGroup>
      {cardShow && (
        // <EmployeeDetailsCard
        //   employees={props.data}
        //   employee={props.data.find((employee) => employee.id === employeeId)}
        //   handleClose={handleClose}
        // />
        <OrgAddUserOffcanvas
          employees={props.data}
          employee={props.data.find((employee) => employee.id === employeeId)}
          handleClose={handleClose}
          employeeId={employeeId}
        />

      )}
    </div>
  );
};

export default OrganizationalChart;
