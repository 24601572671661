import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Modal } from "react-bootstrap";
const DownloadLogDataModal = ({ Show, Submit, Hide, Size }) => {
  const [formate, setFormate] = useState("");
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>Export Log Data</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <FormGroup>
              <Form.Label>
                <b>
                  Formate <span style={{ color: "red" }}>*</span>
                </b>
              </Form.Label>
              <Form.Select
                name="authType"
                placeholder="Select your Authentication Type"
                style={{ boxShadow: "none" }}
                onClick={(e) => e.target.value}
                value={formate}
              >
                <option value="">Select</option>
                <option value="Email">CSV</option>
                <option value="Apps">Excel</option>
              </Form.Select>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <button>{Submit}</button>
                </div>
              </Form.Group>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default DownloadLogDataModal;
