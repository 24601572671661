import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import ReactSelect from "react-select";
import { workflowServices } from "../../APIServices/workflowServices";
import Swal from "sweetalert2";
import Multiselect from "multiselect-react-dropdown";

function UpdateMilestone({
  Show,
  Submit,
  Hide,
  Size,
  Title,
  milestoneData,
  getMilestones,
  milestoneId,
  updateMilestoneID,
}) {
  const [milestoneName, setMilestoneName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [required, setRequired] = useState("");
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [checkedState, setCheckedState] = useState([]);

  const updateMilestone = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Workflow Title!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.milestoneName = milestoneName;
        userData.templates = checkedState;
        let results = await workflowServices.updateMilestone(
          updateMilestoneID,
          userData
        );
        if (results?.status_code === 200) {
          clearFormData();
          getMilestones();
          setLoader(false);
        } else {
          setLoader(false);
        }
      }
    });
  };

  const fetchOptions = async () => {
    let data = await workflowServices.getActiveTemplate();
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((template) => ({
        label: template.name,
        value: template?._id?.$oid,
      }));
      setOptions(formattedOptions);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleCheck = (templateId) => {
    setCheckedState((prevStates) =>
      prevStates?.map((item) =>
        item.templateId === templateId
          ? { ...item, required: !item.required }
          : item
      )
    );
  };

  const templateSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    let checkedData = selectedList?.map((item) => ({
      templateId: item?.value,
      required: false,
    }));
    setCheckedState(checkedData);
  };

  const templateRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptions(NewObjectData);
  };

  const clearFormData = () => {
    Hide();
    setMilestoneName("");
    setStartDate("");
    setEndDate("");
    setTemplateId("");
    setRequired("");
  };

  useEffect(() => {
    setMilestoneName(milestoneData?.milestoneName);
    setStartDate(milestoneData?.expectedStartDate);
    setEndDate(milestoneData?.expectedEndDate);
    setCheckedState(milestoneData?.templates);
  }, [milestoneData]);

  return (
    <>
      <div className="milestonemodal_Font">
        <Modal show={Show} size={Size} onHide={Hide}>
          <Modal.Header className="modal_closeButton">
            <Modal.Title>{Title}</Modal.Title>
            <div
              onClick={() => clearFormData()}
              className="close_modal"
              title="Close"
            >
              X
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFile" className="mb-2">
              <Form.Label style={{ fontSize: "13px" }}>
                <b>Milestone Name</b>
              </Form.Label>
              <Form.Control
                placeholder="Milestone Name"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  setMilestoneName(e.target.value);
                }}
                value={milestoneName}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <b>Templates</b>
              </Form.Label>

              <Multiselect
                displayValue="label"
                onKeyPressFn={() => {}}
                onRemove={templateRemove}
                onSelect={templateSelect}
                options={options}
                selectedValues={selectedOptions}
              />
            </Form.Group>
            {selectedOptions?.length > 0 && (
              <Form.Group className="mt-2">
                <Form.Label>
                  <b>Check Required Templates</b>
                </Form.Label>
                {selectedOptions?.map((item) => (
                  <Form.Check
                    key={item.value}
                    type="checkbox"
                    value={item.value}
                    label={item.label}
                    onChange={() => handleCheck(item.value)}
                  />
                ))}
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <div className="button_type">
                <button
                  type="submit"
                  onClick={() => updateMilestone()}
                  disabled={!milestoneName}
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 13, height: 13 }}
                      className="me-2"
                    />
                  ) : null}

                  {Submit}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default UpdateMilestone;
