import React from "react";
import { Spinner } from "react-bootstrap";
export const NoDataFoundTable = ({ MSG }) => (
  <p className={`hstack justify-content-center`} style={{ height: "96%" }}>
    {MSG}
  </p>
);
export const SpinerDataLoader = () => (
  <div className={`hstack justify-content-center h-100`}>
    <Spinner animation="border" />
  </div>
);

export const IdDetails = ({ CrfView, NoData }) => (
  <span className={CrfView ? "crfHight" : "tableRightContent"}>
    {NoData || "Please Select Any Item To Show Details"}
  </span>
);
