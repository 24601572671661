import React, { useEffect, useState } from "react";
import ProgressReport from "./ProgressReport";
import UseToggle from "../../Hooks/UseToggle";
import { Card, Col, Form, ProgressBar, Row } from "react-bootstrap";
import ELearningViewModule from "./ELearningViewModule";
import Moment from "react-moment";
import { NoDataFoundTable, SpinerDataLoader } from "../../Hooks/NoDataFoundTable";

const ElearningData = ({ setTrainingIds, trainingIds, userTrainingQues, loading }) => {
  const { toggle1, setToggle1 } = UseToggle();
  const [progressTrainingID, setProgressTrainingID] = useState("");

  const handleClickID = (item) => {
    setToggle1();
    setProgressTrainingID(item.trainingID)
  };

  console.log("userTrainingQues", userTrainingQues);

  return (
    <>
      {!trainingIds ? (
        <div
          className="p-2 overflow-auto"
          style={{ height: "calc(100vh - 185px)" }}
        >
          {loading ? (
            <SpinerDataLoader />
          ) : userTrainingQues && userTrainingQues.length > 0 ? (
            <div className="d-flex gap-2 flex-wrap">
              {userTrainingQues?.map((item) => (
                <Card
                  key={item.trainingID}
                  style={{ width: "18rem" }}
                  className="shadow-sm rounded-1 overflow-hidden"
                >
                  <Card.Body className="p-0">
                    <div className="p-2 px-3 border-bottom">
                      <Card.Title className="fontSize12 m-0 fw-bold">
                        {item?.trainingName}
                      </Card.Title>
                    </div>
                    <div className="px-3 py-1 overflow-auto" style={{ height: 150 }}>
                      <Card.Text className="text-muted" style={{ fontSize: 12 }}>
                        {item?.description}
                      </Card.Text>
                    </div>
                    <div className="px-3 py-1">
                      <Row>
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            Total Level :
                            <span
                              className="text-muted ms-1"
                              style={{ fontSize: 10 }}
                            >
                              {item?.no_of_question}
                            </span>
                          </p>
                        </Form.Group>
                      </Row>
                      <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                        Assign Date | Time :
                        <Moment format="DD MMMM YYYY || HH:MM">
                          {item?.created_at?.$date}
                        </Moment>
                      </p>
                      <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                        Completion Date | Time :
                        <span className="text-muted ms-1" style={{ fontSize: 10 }}>
                          12 Jun 2024 | 22:44:66
                        </span>
                      </p>
                      <Row>
                        <Form.Group as={Col} md={6}>
                          <button
                            className="text-white border-success bg-success border-0 w-100"
                            style={{
                              fontSize: 10,
                              borderRadius: 3,
                              padding: "2px 8px",
                            }}
                            onClick={() => handleClickID(item)}
                          >
                            View Progress
                          </button>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <button
                            onClick={() => setTrainingIds(item.trainingID)}
                            className="text-white border-success bg-success border-0 w-100"
                            style={{
                              fontSize: 10,
                              borderRadius: 3,
                              padding: "2px 8px",
                            }}
                          >
                            Complete Now
                          </button>
                        </Form.Group>
                      </Row>
                    </div>
                    <div className="border-top bg-light mt-1">
                      <ProgressBar
                        className="rounded-0"
                        style={{ height: 20 }}
                        animated
                        now={item?.progress_percentage}
                      />
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          ) : (
            <NoDataFoundTable MSG={"Please Assign Elearning Module for User"} />
          )}
        </div>
      ) : (
        <ELearningViewModule trainingIds={trainingIds} setTrainingIds={setTrainingIds} />
      )}
      <ProgressReport Show={!toggle1} Hide={setToggle1} Title={"View Report"} progressTrainingID={progressTrainingID} />
    </>
  );
};

export default ElearningData;

