import React, { useEffect, useState } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import { workflowServices } from "../../APIServices/workflowServices";
import Multiselect from "multiselect-react-dropdown";

function WorkFlowMileStoneModal({
  Show,
  Submit,
  Hide,
  Size,
  Title,
  workFlowId,
  setWorkFlowId,
}) {
  const [milestoneName, setMilestoneName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [options, setOptions] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [required, setRequired] = useState("");

  const addMilestone = async () => {
    let userData = {};
    userData.milestoneName = milestoneName;
    userData.templates = checkedState;

    let data = await workflowServices.addMilestone(userData, workFlowId);
    if (data?.status_code === 200) {
      clearFormData();
    }
  };

  const fetchOptions = async () => {
    let data = await workflowServices.getTemplateForMilestone(workFlowId);
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((template) => ({
        label: template.name,
        value: template?._id,
      }));
      setOptions(formattedOptions);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [workFlowId]);

  const templateSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    let checkedData = selectedList?.map((item) => ({
      templateId: item?.value,
      required: false,
    }));
    setCheckedState(checkedData);
  };

  const templateRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptions(NewObjectData);
  };

  const handleCheck = (templateId) => {
    setCheckedState((prevStates) =>
      prevStates?.map((item) =>
        item.templateId === templateId
          ? { ...item, required: !item.required }
          : item
      )
    );
  };

  const clearFormData = () => {
    Hide();
    setMilestoneName("");
    setStartDate("");
    setWorkFlowId("");
    setEndDate("");
    setTemplateId("");
    setRequired("");
    setTemplateList([]);
  };

  return (
    <>
      <div className="milestonemodal_Font">
        <Modal show={Show} size={Size} onHide={Hide}>
          <Modal.Header className="modal_closeButton">
            <Modal.Title>{Title}</Modal.Title>
            <div
              onClick={() => clearFormData()}
              className="close_modal"
              title="Close"
            >
              X
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFile" className="mb-2">
              <Form.Label style={{ fontSize: "13px" }}>
                <b>Milestone Name</b> <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                placeholder="Milestone Name"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  setMilestoneName(e.target.value);
                }}
                value={milestoneName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <b>Templates</b>
              </Form.Label>
              <Multiselect
                displayValue="label"
                onKeyPressFn={() => {}}
                onRemove={templateRemove}
                onSelect={templateSelect}
                options={options}
                selectedValues={selectedOptions}
              />
            </Form.Group>
            {selectedOptions?.length > 0 && (
              <Form.Group className="mt-2">
                <Form.Label>
                  <b>Check Required Templates</b>
                </Form.Label>
                {selectedOptions?.map((item) => (
                  <Form.Check
                    key={item.value}
                    type="checkbox"
                    value={item.value}
                    label={item.label}
                    onChange={() => handleCheck(item.value)}
                  />
                ))}
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <button
                    type="submit"
                    disabled={!milestoneName}
                    onClick={() => addMilestone()}
                  >
                    Submit
                  </button>
                </div>
              </Form.Group>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default WorkFlowMileStoneModal;
