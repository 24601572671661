import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <div className="header_section footer_section">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="footer-section hstack justify-content-between">
                <div className="footer_Copy pt-3 px-3">
                  <h5>@ Copyright information </h5>
                </div>
                <div className="footer_version text-end pt-3 px-3">
                  <h6>V1.000</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
