import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import NumberTempelateCustomization from "./NumberTempelateCustomization";

const NumberInput = ({
  deleteSection,
  section,
  sections,
  addUpdateFormById,
}) => {
  const { toggle, setToggle } = UseToggle();
  const [numberInput, setNumberInput] = useState("");
  const [formData, setFormData] = useState(section);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section, sections]);

  return (
    <>
      <div className="position-relative" style={{ marginBottom: 25 }}>
        <div className="templatesHeading mt-2"><span style={{ color: "#fff", fontWeight: "bold", fontSize: "11px" }}>NUMBER INPUT</span></div>
      </div>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between mb-2">
          <Form.Label className="mt-2 mb-0 d-flex gap-1">
            <b>
              <span
                style={{
                  fontWeight: formData?.labelStyle?.bold
                    ? formData.customize
                      ? "bold"
                      : "normal"
                    : "normal",
                  fontStyle: formData?.labelStyle?.italic
                    ? formData.customize
                      ? "italic"
                      : "normal"
                    : "normal",
                  color: formData.customize
                    ? formData?.labelStyle?.color
                    : "#000",
                }}
              >
                {formData?.label ? formData?.label : "Label"}
              </span>
              {formData?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </b>
            {formData?.help && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-header">{formData?.help}</Tooltip>
                }
              >
                <i className="fa-solid fa-circle-question text-dark"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header">Your Number Input Order No.</Tooltip>}
            >
              <Form.Control
                type="text"
                disabled
                value={formData?.orderNo}
                className="templateorderform_Control"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-header">Customize Number Input</Tooltip>
              }
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={setToggle}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-pen-to-square px-1 py-1 text-success"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-header"> Delete Number Input</Tooltip>
              }
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={() => deleteSection()}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-trash text-danger px-1 py-1 fs-12"></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <Form.Control
          type="number"
          disabled
          onChange={(e) => setNumberInput(e.target.value)}
          placeholder={formData?.placeholder}
          defaultValue={formData?.defaultValue}
        />
      </div>


      <NumberTempelateCustomization
        show={!toggle}
        onHide={setToggle}
        Title="Number's"
        id={formData?.id}
        type={formData?.type}
        addUpdateFormById={addUpdateFormById}
        sections={sections}
      />
    </>
  );
};

export default NumberInput;
