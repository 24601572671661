import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import "../OrgChart.css";
import { Card, Col, Form, FormGroup, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { orgServices } from "../../../APIServices/orgServices";
import Swal from "sweetalert2";

const OrgAddUserOffcanvas = (props) => {
  const [activeDesiganation, setActiveDesiganation] = useState([]);
  const [assignedUser, setAssignedUser] = useState([]);
  const [userAssigned, setUserAssigned] = useState("");
  const [parentAssigned, setParentAssigned] = useState("");
  const [designationeName, setDesignationeName] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [activeUser, setActiveUser] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [assignUserList, setAssignUserList] = useState([]);

  const getActiveDesignation = async () => {
    let data = await orgServices.getActiveDesignation();
    setActiveDesiganation(data?.data);
  }

  const getAssignedUserInOrgByParent = async () => {
    let data = await orgServices.getAssignedUserInOrgByParent(employeeId);
    setAssignUserList(data?.data);
  }

  console.log("assignUserList", assignUserList);

  useEffect(() => {
    if (props?.employeeId) {
      setEmployeeId(props.employeeId);
    }
  }, [props.employeeId]);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.parentID = employeeId;
    userData.childID = userAssigned;
    userData.designationID = designationeName;
    let data = await orgServices.addOrganisationChart(userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      setLoader(false);
      clearFormData();
    } else {
      setLoader(false);
    }
  }

  const deleteChild = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete This User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await orgServices.deleteOrg(_id);
        if (results?.status_code === 200) {
        }
      }
    });
  };

  const clearFormData = () => {
    props.handleClose();
    setAssignedUser("");
    setDesignationeName("");
  }


  const getAssignedUser = async () => {
    let data = await orgServices.getAssignedUserInOrganizationChart();
    setAssignedUser(data?.data);
  }

  const getParentUserInOrganizationChart = async () => {
    let data = await orgServices.getParentUserInOrganizationChart();
    setActiveUser(data?.data);
    setLoader(false);
  };
  useEffect(() => {
    getParentUserInOrganizationChart();
  }, []);

  useEffect(() => {
    getAssignedUserInOrgByParent();
  }, [employeeId]);

  useEffect(() => {
    getActiveDesignation();
    getAssignedUser();
  }, [])

  return (
    <div className="empDetail">
      <Card className="position-absolute rounded-0 border end-0 bottom-0">
        <button className="card-close-btn " onClick={props.handleClose}>
          <MdClose />
        </button>

        <div className="">
          <Row>
            <Card.Title className="orgCardTitle border-bottom">
              <span>Add Organization</span>
            </Card.Title>
          </Row>

          <Card.Body>
            <div>
              <FormGroup className="mb-2">
                <Form.Label>
                  <b>Assign Parent  User</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select value={employeeId} disabled onChange={(e) => setParentAssigned(e.target.value)}>
                  <option>select</option>
                  {activeUser?.map((item, index) => (
                    <option key={index} value={item?._id}>
                      {item?.fullName}
                    </option>
                  ))}
                </Form.Select>
              </FormGroup>
              <FormGroup className="mb-2">
                <Form.Label>
                  <b>Assign User</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select onChange={(e) => setUserAssigned(e.target.value)}>
                  <option>select</option>
                  {assignedUser?.filter(item => item?._id !== parentAssigned).map((item, index) => (
                    <option key={index} value={item?._id}>
                      {item?.fullName}
                    </option>
                  ))}
                </Form.Select>
              </FormGroup>
              <FormGroup className="mb-2">
                <Form.Label>
                  <b> Designation Name</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select onChange={(e) => setDesignationeName(e.target.value)}>
                  <option>select</option>
                  {activeDesiganation?.map((item, index) => (
                    <option key={index} value={item?._id?.$oid}>
                      {item?.designationName}
                    </option>
                  ))}
                </Form.Select>
              </FormGroup>
              <div className="text-end py-3">
                <Form.Group as={Col} md="12">
                  <div className="button_type">
                    <button type="submit" onClick={handleSubmit}>
                      {loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 15, height: 15 }}
                          className="me-2"
                        />
                      ) : null}
                      Submit
                    </button>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="border-top p-0">
              {employeeId ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="fw-bold" style={{ padding: 4, width: 250 }}>Name</th>
                      <th className="fw-bold text-center" style={{ padding: 4 }}>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignUserList?.map((item, index) => (
                      <tr>
                        <td>
                          {item?.fullName}
                        </td>
                        <td className="text-center">
                          <span className="text-danger">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="tooltip-add">Remove Child User</Tooltip>}
                            >
                              <i
                                class="fa-solid fa-trash"
                                onClick={() => deleteChild(item?._id)}
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </OverlayTrigger>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                ""
              )}
            </div>

          </Card.Body>
        </div>

      </Card>
    </div>
  );
};

export default OrgAddUserOffcanvas;



