import React from "react";
import { Form } from "react-bootstrap";

const AdvanceNumInputTemplate = ({
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
}) => {
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Min Value </b>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Min Value"
            value={minValue}
            onChange={(e) => setMinValue(Number(e.target.value))}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Max Value </b>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Max Value"
            value={maxValue}
            onChange={(e) => setMaxValue(Number(e.target.value))}
          />
        </Form.Group>
      </div>
    </>
  );
};

export default AdvanceNumInputTemplate;
