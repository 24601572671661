import React from 'react'
import profileImage from "../../Images/FriendForecer.jpg";
import { FaPaperclip } from "react-icons/fa6";
import { IoMdSend } from "react-icons/io";
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'

const ChatBox = () => {
    return (
        <div>
            <div className="p-2" >
                <div className="p-2 border shadow-sm rounded-3 mb-1" >
                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                        <div className="hstack mb-2">
                            <img src={profileImage} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                            <div className="message-contentes ms-2">
                                <h6 className="mb-0">Friendship Forever</h6>
                                <p class="last-seen mb-0">Last Seen : 12:00 Pm</p>
                            </div>
                        </div>
                        <small><i class="fa-solid fa-ellipsis-vertical"></i></small>
                    </div>
                    <div style={{ height: "calc(100vh - 235px)", overflowY: "auto" }}>
                        <Container fluid>

                            <div className="messageHello receiver mt-2">
                                <p>Hey Bruh!</p>
                                <span className="time">Today, 9:00pm</span>
                            </div>
                            <div className="messageHello2 receiver">
                                <p>How are you doing?</p>
                                <span className="time">Today, 9:01pm</span>
                            </div>
                            <div className="messageHello sender">
                                <p>Hello</p>
                                <span className="time">Today, 9:02pm</span>
                            </div>
                            <div className="messageHello2 sender">
                                <p>I am fine, thank you.</p>
                                <span className="time text-end w-100 px-3">Today, 9:02pm</span>

                            </div>
                            <div className="message sender">
                                <p>Been a while, trust you are doing good yourself?</p>
                                <span className="time text-end w-100 px-3">Today, 9:03pm</span>
                            </div>
                            <div className="messageHello2 receiver">
                                <p>Yea! I am fine.</p>
                                <span className="time">Today, 9:03pm</span>
                            </div>
                            <div className="messageHello2 receiver">
                                <p>Can we meet tomorrow?</p>
                                <span className="time">Today, 9:04pm</span>
                            </div>
                            <div className="message sender">
                                <p>Been a while, trust you are doing good yourself?</p>
                                <span className="time text-end w-100 px-3">Today, 9:03pm</span>
                            </div>
                            <div className="messageHello2 receiver">
                                <p>Yea! I am fine.</p>
                                <span className="time">Today, 9:03pm</span>
                            </div>


                            <div className="messageHello2 receiver">
                                <p>Can we meet tomorrow?</p>
                                <span className="time">Today, 9:04pm</span>
                            </div>
                        </Container>
                    </div>



                    <div className="message-input-container">

                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Type your message here..."
                                className="message-input-field"
                            />
                            <Button
                                variant="light"
                                className="px-2"
                                style={{
                                    fontSize: "13px",
                                    border: "1px solid #dee2e6",
                                }}
                            >
                                <FaPaperclip className="message-icon" />
                            </Button>
                        </InputGroup>


                        <div className="message-icons">

                        </div>
                        <Button className="microphone-button">
                            <IoMdSend />
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ChatBox