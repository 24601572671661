import React, { useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import Pagination from "../../Hooks/Paginations";
import { elearningServices } from "../../APIServices/elearningServices";
import { set } from "lodash";
import TrainingDataManagement from "./TrainingDataManagement/TrainingDataManagement";
import { NoDataFoundTable, SpinerDataLoader } from "../../Hooks/NoDataFoundTable";

const ELearningDataTable = ({
  elearningData,
  setUpdateDataId,
  setTrainingName,
  setDescription,
  setElearningID,
  setCompletionDays,
  getAllElearningMeta,
  setIsSectionVisible,
  handleAddNewLearning,
  elearningID,
  companyID,
  setCompanyID,
  setTrandingId,
  trandingId,
  setUpdateType,
  loading,
  questions,
  setQuestions,
  multiselectAnswer,
  setMultiselectAnswer
}) => {

  const [selectedRow, setSelectedRow] = useState("");

  const updateClientstatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Learning Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await elearningServices.updateElearningMeta(id, data);
        if (results?.status_code === 200) {
          getAllElearningMeta();
        }
      }
    });
  };

  const handleUpdateAllData = (item) => {
    setIsSectionVisible(true);
    setElearningID(item?._id?.$oid);
    setCompanyID(item?.companyID);
  }

  const deleteElearningMeta = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Learning Data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await elearningServices.deleteElearningMeta(_id);
        if (data?.status_code === 200) {
          getAllElearningMeta();
        }
      }
    });
  };

  const handleUpdateClick = (elearning) => {
    setUpdateDataId(elearning?._id?.$oid);
    setTrainingName(elearning.trainingName);
    setDescription(elearning.description);
    setCompletionDays(elearning.completionDays);
    handleAddNewLearning();
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = elearningData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(elearningData?.length / itemsPerPage);

  return (
    <>
      {!trandingId ?
        <>
          {loading ? (
            <SpinerDataLoader />
          ) : result?.length > 0 ? (
            <div className="team_table">
              <Table id="resizable-table" striped hover className="m-0 px-2 custom-table">
                <thead className="thead-sticky top-0">
                  <tr>
                    <th style={{ width: 45 }}>Sr.</th>
                    <th style={{ width: 211 }}>Training Name</th>
                    <th style={{ width: 255 }}>Description</th>
                    <th style={{ width: 155 }}>No. of completion days</th>
                    <th style={{ width: 80 }}>Status</th>
                    <th style={{ width: 159 }}>Created Date | Time</th>
                    <th style={{ width: 80 }} className="text-center">
                      View
                    </th>
                    <th style={{ width: 80, textAlign: "center" }}>Update</th>
                    <th style={{ width: 80 }} className="text-center">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result?.map((item, index) => (
                    <tr key={item._id} className={selectedRow === item?._id?.$oid ? "selected" : ""}
                      onClick={() => setSelectedRow(item?._id?.$oid)}
                      style={{ cursor: "pointer" }}
                    >
                      <td onClick={() => handleUpdateAllData(item)}>{index + 1 + startIndex}</td>
                      <td onClick={() => handleUpdateAllData(item)}>{item.trainingName}</td>
                      <td onClick={() => handleUpdateAllData(item)}>{item.description}</td>
                      <td onClick={() => handleUpdateAllData(item)}>{item.completionDays}</td>
                      <td>
                        <select
                          className={`status-select ${item.status.toLowerCase()}`}
                          value={item.status}
                          onChange={(e) =>
                            updateClientstatus(item?._id?.$oid, e.target.value)
                          }
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </td>
                      <td onClick={() => handleUpdateAllData(item)}>
                        <Moment format="DD MMM YYYY | HH:MM">{item.created_at}</Moment>
                      </td>
                      <td className="button_type text-center">
                        <button
                          onClick={() => {
                            setTrandingId(item?._id?.$oid);
                            setIsSectionVisible(false);
                            setUpdateType("")
                          }}
                          style={{
                            fontSize: 11,
                            borderRadius: 3,
                            padding: "2px 8px",
                          }}
                        >
                          View
                        </button>
                      </td>
                      <td className="button_type text-center" onClick={() => handleUpdateClick(item)}>
                        <button
                          style={{
                            fontSize: 11,
                            borderRadius: 3,
                            padding: "2px 8px",
                          }}
                        >
                          Update
                        </button>
                      </td>
                      <td
                        className=" text-center"
                      >
                        <span className="text-danger">
                          <i
                            className="fa-solid fa-trash"
                            onClick={() => deleteElearningMeta(item?._id?.$oid)}
                          ></i>
                        </span>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="position-sticky bottom-0 bg-white" >
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                  totalItems={elearningData?.length}
                />
              </div>
            </div>

          ) : (
            <NoDataFoundTable MSG={"Please Create E-Learning"} />
          )}


        </>
        :
        <TrainingDataManagement trandingId={trandingId} questions={questions} setQuestions={setQuestions} multiselectAnswer={multiselectAnswer} setMultiselectAnswer={setMultiselectAnswer} />
      }

    </>
  );
};

export default ELearningDataTable;
