import React, { useState } from "react";
import LoginImage from "../../Images/loginimage.png";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    setLoading(true);
    let userData = {
      email: email,
      password: password,
    };
    try {
      let data = await authServices.login(userData);
      setStatusMessage(data);
      setTimeout(() => {
        setStatusMessage("");
      }, 3000);
    } catch (error) {
      setStatusMessage({ status_code: 500, message: "An error occurred." });
    } finally {
      setLoading(false);
    }
    setValidated(true);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={7} className="p-0">
          <div className="loginimg">
            <img src={LoginImage} alt="loginImage" />
          </div>
        </Col>
        <Col md={5} className="loginsection_two">
          <div className="loginside p-5">
            <div className="shadow p-5 rounded">
              <div className="loginContent gap-3">
                <h5>Login</h5>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-2">
                  <div className="loginUserm h-stack justify-content-end mt-5">
                    Email
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </div>
                  <Form.Control
                    required
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your Email"
                    className="username_formcontrol mt-2"
                    size="lg"
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <div className="loginUserm h-stack justify-content-end">
                    Password
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </div>
                  <div className="position-relative">
                    <Form.Control
                      required
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      className="mt-2"
                      size="lg"
                    />
                    <div
                      className="passwordvisiability"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <i
                          className="fa-regular fa-eye-slash"
                          title="Hide Password"
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye"
                          title="Reveal Password"
                        ></i>
                      )}
                    </div>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Please enter your password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <div className="button_type">
                    <button
                      type="submit"
                      variant="outline-dark"
                      className="loginButton mt-2"
                      disabled={!email || !password || loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>

                </Form.Group>
                {statusMessage && (
                  <p
                    className="message"
                    style={
                      statusMessage?.status_code === 200
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {statusMessage?.message}
                  </p>
                )}
                <div className="d-flex justify-content-between">
                  <NavLink to={"/forgetpassword"}>
                    <b>Forget Password</b>
                  </NavLink>
                  <NavLink to={"/signup"}>
                    <b>Sign Up</b>
                  </NavLink>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;



