import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { elearningServices } from "../../APIServices/elearningServices";
import ResultRadialChart from "./ResultRadialChart";
import Swal from "sweetalert2";

const StepIndicator = ({ currentStep, triningindex }) => {
  return (
    <div className="position-relative hstack justify-content-between mt-2">
      {triningindex?.map((step, index) => (
        <div
          key={index}
          className={`step ${index === currentStep ? "active" : ""}`}
        >
          <div
            className="border rounded-circle bg-white hstack justify-content-center"
            style={{ width: 30, height: 30 }}
          >
            {step}
          </div>
        </div>
      ))}
    </div>
  );
};

const ELearningViewModule = ({ trainingIds, setTrainingIds }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [trainingQues, setTrainingQues] = useState([]);
  const [triningindex, setTrainingindex] = useState([]);
  const [progressReportdata, setProgressReportdata] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [handleResponseQuestionId, setHandleResponseQuestionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultView, setResultView] = useState(false)

  const getTrainingQuesByElearning = async () => {
    let data = await elearningServices.getTrainingQuesByElearning(trainingIds);
    setTrainingQues(data?.data?.training_ques);
    setTrainingindex(data?.data?.index);
  };

  const getUserProgressReport = async () => {
    let data = await elearningServices.getUserProgressReport(trainingIds);
    console.log("data", data);
    setProgressReportdata(data?.data);
  };

  console.log("progressReportdata", progressReportdata);
  console.log("trainingQues", trainingQues);



  useEffect(() => {
    getTrainingQuesByElearning();
    getUserProgressReport();
  }, [trainingIds]);


  const handleNext = () => {
    setCurrentStep((prev) => {
      const nextStep = Math.min(prev + 1, triningindex.length - 1);
      setSelectedAnswers((prevAnswers) => {
        const updatedAnswers = { ...prevAnswers };
        const currentQuestionIds = trainingQues[prev]?.quiz?.map(question => question.questionId) || [];

        currentQuestionIds.forEach(id => {
          delete updatedAnswers[id];
        });

        return updatedAnswers;
      });

      return nextStep;
    });
    setHandleResponseQuestionId("")
    setResponseData("");
    setButtonStatus(false)
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
    setResponseData("");
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let arrayData = Object.entries(selectedAnswers)?.map(([questionId, option]) => ({
      questionId,
      option
    }));
    let userData = {};
    userData.type = trainingQues[currentStep]?.type;
    userData.questionID = trainingQues[currentStep]?.questionID;
    if (trainingQues[currentStep]?.type === "quiz") {
      userData.answers = arrayData
    } else {
      userData.answers = true
    }
    let data = await elearningServices.addUserQuizQues(trainingIds, userData);
    setResponseData(data?.data);
    setButtonStatus(data?.data?.status)
    setHandleResponseQuestionId(data?.data?.questionID)
    setStatusMessage(data);
    if (data?.status_code === 200) {
      getTrainingQuesByElearning();
      setResultView(true);
      getUserProgressReport();
      setSelectedAnswers({});
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const deleteUserTrainingAns = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Training Answer!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await elearningServices.deleteUserTrainingAns(trainingQues[currentStep]?.questionID, trainingIds);
        if (data?.status_code === 200) {
          getTrainingQuesByElearning();
          setResponseData("");
          setLoading(false)
        }
      }
    });
  };
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleSelectOption = (questionId, option, isSingleSelect) => {
    setSelectedAnswers((prevState) => {
      if (isSingleSelect) {
        return { ...prevState, [questionId]: option };
      } else {
        const currentSelection = prevState[questionId] || [];
        if (currentSelection.includes(option)) {
          return {
            ...prevState,
            [questionId]: currentSelection.filter((opt) => opt !== option),
          };
        } else {
          return { ...prevState, [questionId]: [...currentSelection, option] };
        }
      }
    });
  };

  const isVideoLink = (description) => {
    const videoExtensions = ['mp4', 'webm', 'ogg'];
    return videoExtensions.some((ext) => description.includes(ext));
  };

  console.log("trainingQues[currentStep]?.percentage", responseData?.user_submit_ans);


  return (
    <>

      <div className="step-form-container w-75 m-auto">
        <StepIndicator triningindex={triningindex} currentStep={currentStep} />
        <div className="p-2 overflow-auto position-relative" style={{ height: "calc(100vh - 174px)" }}>
          {trainingQues?.map((item, index) => (
            <>
              {index === currentStep && item.type === "file" && (
                <Card className="shadow-sm border-1 rounded-1 bg-light mb-2">
                  <div className="p-2 px-3 border-bottom">
                    <Card.Title className="fontSize12 m-0 fw-bold">
                      {item.title}
                    </Card.Title>
                  </div>
                  {item.fileType === "pdf" ? (
                    <Card.Body className="p-2 px-3 text-center">
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <div className="position-relative">
                          <Viewer
                            fileUrl={item.uploadFile}
                            style={{ position: "relative", height: "100%" }}
                          />
                        </div>
                      </Worker>
                    </Card.Body>
                  ) : <Card.Body className="p-2 px-3 text-center">
                    {item.description}
                  </Card.Body>}
                  <div className="px-3 py-1">
                    <Card.Text className="text-muted" style={{ fontSize: 12 }}>
                      {isVideoLink(item.uploadFile) ? (
                        <video width="100%" controls>
                          <source src={item.uploadFile} type="video/mp4" />
                          {item.uploadFile}
                        </video>
                      ) : (
                        ""
                      )}
                    </Card.Text>
                  </div>
                </Card>
              )}
              {index === currentStep && item.type === "quiz" && (
                <Container fluid>
                  <Row>
                    <Col md={trainingQues[currentStep]?.percentage ? 9 : 12}>
                      <Card className="shadow-sm border rounded-1 p-3 w-75 m-auto">
                        {item?.quiz?.map((question, key) => (
                          <Form.Group key={key} className="mb-2">
                            {/* <Form.Label className="mb-1 fontSize12"
                              style={{ color: (trainingQues[currentStep]?.user_submit_ans || responseData?.user_submit_ans) ? ((responseData?.user_submit_ans ? (responseData?.user_submit_ans?.some(element => (element?.questionId === question?.questionId && element?.status))) : (trainingQues[currentStep]?.user_submit_ans?.some(element => (element?.questionId === question?.questionId && element?.status)))) ? "green" : "red") : "black" }}>
                              <b>{`Question ${key + 1}: `}</b>{question?.question} <span className="text-danger">*</span> //    Vinay Bhaiya Ka Conditions hai
                            </Form.Label> */}
                            <Form.Label
                              className="mb-1 fontSize12"
                              style={{
                                color: (() => {
                                  const userSubmit = responseData?.user_submit_ans || trainingQues[currentStep]?.user_submit_ans;
                                  if (!userSubmit || userSubmit.length === 0) return "black";
                                  const answerStatus = userSubmit.some(
                                    (element) => element?.questionId === question?.questionId && element?.status
                                  );
                                  return answerStatus ? "green" : "red";
                                })(),
                              }}
                            >
                              <b>{`Question ${key + 1}: `}</b>
                              {question?.question} <span className="text-danger">*</span>
                            </Form.Label>

                            {question?.options?.map((option, key1) => {
                              return (
                                <div key={key1} className="hstack gap-2">
                                  <Form.Check
                                    type={question?.answerType === "singleSelect" ? "radio" : "checkbox"}
                                    name={`optionGroup-${question?.questionId}`}
                                    id={`option-${key1}`}
                                    checked={question?.answerType === "singleSelect"
                                      ? (selectedAnswers[question?.questionId] === `Option ${key1 + 1}` || item.user_submit_ans.some(result => (result.questionId === question?.questionId && result.option === `Option ${key1 + 1}`)))
                                      : selectedAnswers[question?.questionId]?.includes(`Option ${key1 + 1}`)}
                                    onChange={() =>
                                      handleSelectOption(
                                        question.questionId,
                                        `Option ${key1 + 1}`,
                                        question.answerType === 'singleSelect'
                                      )
                                    }
                                  />
                                  <Form.Label
                                    className="m-0 fontSize12"
                                    htmlFor={`option-${key1}`}
                                  >
                                    {option}
                                  </Form.Label>

                                </div>
                              );
                            })}
                          </Form.Group>
                        ))}

                      </Card>
                    </Col>

                    <Col md={3}>
                      {trainingQues[currentStep]?.percentage && (
                        <>
                          {trainingQues?.map((chartitem, chartindex) => (
                            <React.Fragment key={chartindex}>
                              {chartindex === currentStep && chartitem.type === "quiz" && (
                                <>
                                  <div className="top-0 end-0 text-center" style={{ paddingRight: 0, paddingTop: 26, paddingLeft: 0 }}>
                                    <ResultRadialChart responseData={responseData?.percentage || trainingQues[currentStep]?.percentage} />
                                    <div className="button_type">
                                      {responseData?.percentage < 80 && (
                                        <button onClick={deleteUserTrainingAns}>Restart</button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="hstack justify-content-between px-2">
        <div className="button_type">
          <button
            onClick={() => setTrainingIds("")}
          >
            Back to Traning <i className="fa-solid fa-arrow-left-long ms-2"></i>
          </button>
        </div>
        <div className="justify-content-end p-2 hstack gap-2 bg-light button_type">
          {triningindex?.length && (
            <button onClick={handlePrevious} disabled={currentStep === 0}>
              <i className="fa-solid fa-arrow-left-long me-2"></i>Previous
            </button>
          )}
          {!trainingQues[currentStep]?.status &&
            <button onClick={handleSubmit}>Submit</button>
          }
          {currentStep < triningindex?.length - 1 && (
            <button
              type="submit"
              disabled={handleResponseQuestionId === trainingQues[currentStep]?._id?.$oid ? buttonStatus === "fail" : !trainingQues[currentStep]?.status}
              onClick={handleNext}
            >
              Next <i className="fa-solid fa-arrow-right-long ms-2"></i>
            </button>
          )}
        </div>

      </div>


    </>
  );
};

export default ELearningViewModule;
