import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import UseToggle from "../../Hooks/UseToggle";
import TaskDetailModal from "./TaskDetailModal";
import UpdateMilestone from "./UpdateMilestone";
import WorkFlowMileStoneModal from "./WorkFlowMileStoneModal";
import MilestonePropertiesModal from "./MilestonePropertiesModal";
import { workflowServices } from "../../APIServices/workflowServices";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Collapse, NavLink, FormControl, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../Hooks/NoDataFoundTable";
import TaskPropertiesModal from "./TaskPropertiesModal";

const WorkflowPipline = ({
  setWorkFlowId,
  workFlowId,
  roleType,
  teamsData,
  currentPath,
  userCurrrentData
}) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    toggle4,
    setToggle4,
  } = UseToggle();
  const [sections, setSections] = useState([]);
  const [userSection, setUserSections] = useState([]);
  const [taskData, setTaskData] = useState(null);
  const [openTaskId, setOpenTaskId] = useState(null);
  const [milestoneData, setMilestoneData] = useState("");
  const [milestoneProperties, setMilestoneProperties] = useState("");
  const [activePopover, setActivePopover] = useState(null);
  const [updateMilestoneID, setUpdateMilestoneID] = useState(null);
  const [taskID, setTaskID] = useState(null);
  const [commentTaskId, setCommentTaskId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOptiones, setSelectedOptiones] = useState([]);
  const [taskPropertiesData, setTaskPropertiesData] = useState("");

  const { studyId } = useParams();

  const getMilestones = async () => {
    let data = await workflowServices.getMilestones(workFlowId);
    setSections(data?.data || []);
    setLoading(false);
  };

  const getAllTaskByWorkflowForClient = async () => {
    let data = await workflowServices.getAllTaskByWorkflowForClient(
      workFlowId,
      studyId
    );
    setSections(data?.data || []);
  };

  const getAllTaskByWorkflowId = async () => {
    let data = await workflowServices.getAllTaskByWorkflowId(workFlowId); //user Study Side Workflow data  pipline 
    setUserSections(data?.data || []);
  };

  const addSection = () => {
    const newSection = {
      id: `section-${sections?.length + 1}`,
      title: "Milestone",
      tasks: [],
    };
    setSections([...sections, newSection]);
  };

  const addTaskToSection = async (sectionId, tasks) => {
    let userData = {};
    userData.taskName = `Task ${tasks?.length + 1}`;
    let data = await workflowServices.addTask(userData, sectionId);
    if (data?.status_code) {
      getMilestones();
    }
  };

  const loginType = localStorage.getItem("loginType");

  const deleteSection = async (sectionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Milestone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await workflowServices.deleteMilestone(sectionId);
        if (data?.status_code) {
          getMilestones();
        }
      }
    });
  };

  const deleteTask = async (sectionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Task!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await workflowServices.deleteTask(sectionId);
        if (data?.status_code) {
          getMilestones();
        }
      }
    });
  };
  const toggleActionButton = (id) => {
    setActivePopover(activePopover === id ? null : id);
  };

  const toggleActionTask = (id) => {
    setActivePopover(activePopover === id ? null : id);
  };

  const handleUpdate = (item) => {
    setToggle3();
    setMilestoneData(item);
    setUpdateMilestoneID(item?._id?.$oid);
  };

  const handleProperties = (item) => {
    setToggle2();
    setMilestoneProperties(item);
  };

  const taskProperties = (taskData) => {
    setTaskPropertiesData(taskData);
    setToggle4();
  };

  const onDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "section") {
      const newSections = Array.from(sections);
      const [movedSection] = newSections.splice(source.index, 1);
      newSections.splice(destination.index, 0, movedSection);

      setSections(newSections);
    } else if (type === "task") {
      const startSection = sections.find(
        (section) => section?._id?.$oid === source.droppableId
      );
      const finishSection = sections.find(
        (section) => section?._id?.$oid === destination.droppableId
      );

      if (startSection === finishSection) {
        const newTaskIds = Array.from(startSection.tasks);
        const [movedTask] = newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, movedTask);

        const newSection = {
          ...startSection,
          tasks: newTaskIds,
        };

        setSections((prevSections) =>
          prevSections.map((section) =>
            section?._id?.$oid === newSection?._id?.$oid ? newSection : section
          )
        );
      } else {
        const startTaskIds = Array.from(startSection.tasks);
        const [movedTask] = startTaskIds.splice(source.index, 1);
        const newStartSection = {
          ...startSection,
          tasks: startTaskIds,
        };

        const finishTaskIds = Array.from(finishSection.tasks);
        finishTaskIds.splice(destination.index, 0, movedTask);
        const newFinishSection = {
          ...finishSection,
          tasks: finishTaskIds,
        };

        setSections((prevSections) =>
          prevSections.map((section) =>
            section?._id?.$oid === newStartSection?._id?.$oid
              ? newStartSection
              : section?._id?.$oid === newFinishSection?._id?.$oid
                ? newFinishSection
                : section
          )
        );
      }
    }
  };

  const handleToggletwo = (task) => {
    setToggle();
    setTaskID(task?._id?.$oid);
    setCommentTaskId(task?.taskID);
    const assignedUsers = task?.assignedTo?.map((user) => ({
      label: user.label,  // Assuming the label is the user's full name
      value: user.value,  // Assuming the value is the user's unique ID
    }));

    setSelectedOptiones(assignedUsers);
  };

  useEffect(() => {
    getMilestones();
  }, [workFlowId]);

  useEffect(() => {
    getAllTaskByWorkflowForClient();
  }, [workFlowId, studyId]);

  useEffect(() => {
    getAllTaskByWorkflowId();
  }, [workFlowId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".profile")) {
        setActivePopover(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  console.log("sections", sections);


  return (
    <>
      {loading ? (
        <div style={{ height: "calc(100vh - 130px" }}>
          <NoDataFoundTable MSG={"please Select Workflow"} />
        </div>
      ) : sections?.length === 0 ? (
        <NoDataFoundTable MSG={"please Select Workflow"} />
      ) : (
        <div className="overflow-auto d-flex pt-2">
          {(roleType === "client" ? sections : userSection)?.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId={sections}
                direction="horizontal"
                type="section"
              >
                {(provided) => (
                  <div
                    className="all-sections d-flex"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {(roleType === "client" ? sections : userSection)?.map(
                      (item, index) => (
                        <Draggable
                          key={item?._id?.$oid}
                          draggableId={item?._id?.$oid}
                          index={index}
                          type="section"
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Droppable
                                droppableId={item?._id?.$oid}
                                type="task"
                              >
                                {(provided) => (
                                  <div
                                    className="piplineParent_class"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    <div className="hstack justify-content-between" >
                                      <div className="pipline_box1 hstack">
                                        <h5 className="m-0">
                                          <FormControl
                                            className="second_workflow text-truncate"
                                            type="text"
                                            value={item.milestoneName}
                                            onChange={(e) =>
                                              setSections((prevSections) =>
                                                prevSections.map((sec) =>
                                                  sec?._id?.$oid ===
                                                    item?._id?.$oid
                                                    ? {
                                                      ...sec,
                                                      title: e.target.value,
                                                    }
                                                    : sec
                                                )
                                              )
                                            }
                                          />
                                        </h5>
                                      </div>
                                      <div className="pipline_box2 hstack gap-2">
                                        {currentPath === "clientstudy" ||
                                          loginType === "study" ? (
                                          ""
                                        ) : (
                                          <NavLink
                                            onClick={() =>
                                              addTaskToSection(
                                                item?._id?.$oid,
                                                item.tasks
                                              )
                                            }
                                            title="Add Task"
                                            className="wwnavBtn"
                                          >
                                            <i className="fa-solid fa-plus p-2"></i>
                                          </NavLink>
                                        )}

                                        <div
                                          className={`profile position-relative ${activePopover === item?._id?.$oid
                                            ? "active"
                                            : ""
                                            }`}
                                        >
                                          {roleType === "client" &&
                                            currentPath !== "clientstudy" && (
                                              <div
                                                onClick={() =>
                                                  toggleActionButton(
                                                    item?._id?.$oid
                                                  )
                                                }
                                                title="Action"
                                                className={
                                                  activePopover ===
                                                    item?._id?.$oid
                                                    ? "navBtnActive"
                                                    : "wwnavBtn"
                                                }
                                                style={{
                                                  backgroundColor: activePopover === item?._id?.$oid ? "#e5ecf0" : "transparent",
                                                  borderRadius: "5px"
                                                }}
                                              >
                                                <i className="fa-solid fa-ellipsis-vertical p-2"></i>
                                              </div>
                                            )}
                                          {activePopover ===
                                            item?._id?.$oid && (
                                              <div
                                                className="profile_dd shadow"
                                                style={{ right: 0 }}
                                              >
                                                <ul className="profile_ul">
                                                  {roleType === "client" && (
                                                    <li>
                                                      <NavLink
                                                        onClick={() =>
                                                          handleUpdate(item)
                                                        }
                                                      >
                                                        <i className="fa-solid fa-pen-to-square me-2"></i>
                                                        Update Milestone
                                                      </NavLink>
                                                    </li>
                                                  )}

                                                  <li>
                                                    <div className="mb-0">
                                                      <NavLink
                                                        onClick={() =>
                                                          handleProperties(item)
                                                        }
                                                      >
                                                        <li>
                                                          <i class="fa-solid fa-circle-info me-2"></i>
                                                          Properties
                                                        </li>
                                                      </NavLink>
                                                    </div>
                                                  </li>
                                                  {roleType === "client" && (
                                                    <li>
                                                      <NavLink
                                                        className="delete-link"
                                                        style={{ color: "red" }}
                                                        onClick={() =>
                                                          deleteSection(
                                                            item?._id?.$oid
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="fa-solid fa-trash me-2"
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        ></i>
                                                        Delete Milestone
                                                      </NavLink>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="piplinesection_box2 p-2">
                                      {item?.tasks?.map((task, index) => (
                                        <Draggable
                                          key={task?._id?.$oid}
                                          draggableId={task?._id?.$oid}
                                          index={index}
                                          type="task"
                                        >
                                          {(provided) => (
                                            <div
                                              className={`addTask_button ${taskData === task?._id?.$oid
                                                ? "selected"
                                                : ""
                                                }`}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                cursor: "pointer",
                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <div
                                                className="card_ssection"
                                                onClick={() =>
                                                  setTaskData(task)
                                                }
                                              >
                                                <div className="Card_Firstelement d-flex gap-2 position-relative">
                                                  {(currentPath ===
                                                    "clientstudy" ||
                                                    loginType ===
                                                    "study") && (
                                                      <i
                                                        className="fa-regular fa-circle-check position-absolute z-1 start-0"
                                                        title="click to complete"
                                                        style={{
                                                          top: 4,
                                                          fontSize: 13,
                                                        }}
                                                      ></i>
                                                    )}
                                                  <Form.Label
                                                    onClick={() =>
                                                      handleToggletwo(task)
                                                    }
                                                    title="View Task Details"
                                                    className="cursor-pointer pb-5 w-100"
                                                    style={{ textIndent: 20 }}
                                                  >
                                                    {task.taskName}
                                                  </Form.Label>
                                                  <div
                                                    className={`profile position-relative ${activePopover ===
                                                      task?._id?.$oid
                                                      ? "active"
                                                      : ""
                                                      }`}
                                                  >
                                                    <div
                                                      onClick={() =>
                                                        toggleActionTask(
                                                          task?._id?.$oid
                                                        )
                                                      }
                                                      title="Action"
                                                      className={
                                                        activePopover ===
                                                          task?._id?.$oid
                                                          ? "navBtnActive"
                                                          : "wwnavBtn"
                                                      }
                                                      style={{
                                                        backgroundColor: activePopover === task?._id?.$oid ? "#e5ecf0" : "transparent",
                                                        borderRadius: "5px", // Optional: make it look nicer with rounded corners
                                                      }}
                                                    >
                                                      <i className="fa-solid fa-ellipsis-vertical p-2 text-muted"></i>
                                                    </div>
                                                    {activePopover ===
                                                      task?._id?.$oid && (
                                                        <div
                                                          className="profile_dd shadow"
                                                          style={{ right: 0 }}
                                                        >
                                                          <ul className="profile_ul">
                                                            <li>
                                                              <div className="mb-0">
                                                                <NavLink
                                                                  onClick={() =>
                                                                    taskProperties(task)
                                                                  }
                                                                >
                                                                  <li>
                                                                    <i class="fa-solid fa-circle-info me-2"></i>
                                                                    Properties
                                                                  </li>
                                                                </NavLink>
                                                              </div>
                                                            </li>
                                                            <li>
                                                              <NavLink
                                                                className="delete-link"
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                                onClick={() =>
                                                                  deleteTask(
                                                                    task?._id
                                                                      ?.$oid
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa-solid fa-trash me-2"
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                ></i>
                                                                Delete
                                                              </NavLink>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>

                                                <div className="card_body justify-content-between hstack gap-3">
                                                  <span className="text-muted hstack gap-2">
                                                    <Moment format=" DD MMM YYYY | HH:MM">
                                                      {task.created_at}
                                                    </Moment>
                                                  </span>

                                                  <div
                                                    className={`hstack justify-content-between sidebarmenu ${openTaskId ===
                                                      task?._id?.$oid
                                                      ? "taskiconMenuActive"
                                                      : ""
                                                      }`}
                                                  >
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                      {!currentPath === "clientstudy" && (
                                        <Button
                                          style={{
                                            fontWeight: "bold",
                                            marginTop: "10px",
                                          }}
                                          className="workflowmodalSubmit"
                                          onClick={() =>
                                            addTaskToSection(
                                              item?._id?.$oid,
                                              item.tasks
                                            )
                                          }
                                        >
                                          + Add Task
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {!roleType === "user" && userSection?.length > 0 && (
            <div className="piplinebro">
              <div className="piplineParent_class">
                <div className="hstack justify-content-between">
                  <div className="pipline_box1">
                    <h5 className="m-0 ">
                      <FormControl
                        className="second_workflow w-auto"
                        type="text"
                        placeholder="siddhant"
                        readOnly
                      />
                    </h5>
                  </div>

                  <div className="pipline_box2 hstack justify-content-end w-100">
                    <NavLink
                      onClick={setToggle1}
                      title="Add New Milestone"
                      className="navBtn"
                    >
                      <i className="fa-solid fa-plus"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="piplinesection_box2 pt-2"></div>
              </div>
            </div>
          )}

          {currentPath !== `clientstudy` && (
            <div className="piplinebro">
              <div className="piplineParent_class">
                <div className="hstack justify-content-between">
                  <div className="pipline_box1">
                    <h5 className="m-0 ">
                      <FormControl
                        className="second_workflow w-auto"
                        type="text"
                        placeholder="Add New Milestone"
                        readOnly
                      />
                    </h5>
                  </div>
                  <div className="pipline_box2 hstack justify-content-end w-100">
                    {roleType !== "user" && (
                      <NavLink
                        onClick={setToggle1}
                        title="Add New Milestone"
                        className="wwnavBtn"
                      >
                        <i className="fa-solid fa-plus p-2"></i>
                      </NavLink>
                    )}
                  </div>
                </div>
                <div className="piplinesection_box2 pt-2"></div>
              </div>
            </div>
          )}

          <WorkFlowMileStoneModal
            Show={!toggle1}
            Hide={setToggle1}
            Size={"md"}
            workFlowId={workFlowId}
            setWorkFlowId={setWorkFlowId}
            Title="Add New Milestone"
            Submit={"Add Milestone"}
            addSection={addSection}
          />

          <TaskDetailModal
            Show={!toggle}
            Hide={setToggle}
            taskData={taskData}
            getMilestones={getMilestones}
            roleType={roleType}
            sections={sections}
            currentPath={currentPath}
            teamsData={teamsData}
            taskID={taskID}
            workFlowId={workFlowId}
            commentTaskId={commentTaskId}
            selectedOptiones={selectedOptiones}
            setSelectedOptiones={setSelectedOptiones}
            userCurrrentData={userCurrrentData}

          />
          <MilestonePropertiesModal
            Show={!toggle2}
            Hide={setToggle2}
            Size={"md"}
            milestoneProperties={milestoneProperties}
          />
          <UpdateMilestone
            Show={!toggle3}
            Hide={setToggle3}
            Size={"md"}
            milestoneData={milestoneData}
            updateMilestoneID={updateMilestoneID}
            Title="Update Milestone"
            getMilestones={getMilestones}
            Submit={"Update Milestone"}
          />

          <TaskPropertiesModal
            Show={!toggle4}
            Hide={setToggle4}
            Size={"md"}
            taskPropertiesData={taskPropertiesData}
          />
        </div>
      )}
    </>
  );
};

export default WorkflowPipline;
