import React, { useEffect, useRef, useState } from 'react'
import { NoDataFoundTable, SpinerDataLoader } from '../Hooks/NoDataFoundTable';
import { result } from 'lodash';
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';
import Paginations from '../Hooks/Paginations';

const UserClientList = ({ assignedSponsorData, setSelectedRow, loading, selectedRow, onMouseDown, tableRef }) => {

    const handleRowClick = (item) => {
        setSelectedRow(item?._id);
    };

    const resizerRefs = useRef([]);

    console.log("selectedRow", selectedRow);


    useEffect(() => {
        const resizers = resizerRefs.current;
        let startX, startWidth;

        const initResize = (e, index) => {
            startX = e.clientX;
            startWidth = resizers[index].parentElement.offsetWidth;
            document.addEventListener("mousemove", doResize);
            document.addEventListener("mouseup", stopResize);
        };

        const doResize = (e) => {
            const width = startWidth + (e.clientX - startX);
            resizers.forEach((resizer, index) => {
                if (index === parseInt(resizer.dataset.index)) {
                    resizer.parentElement.style.width = `${width}px`;
                }
            });
        };

        const stopResize = () => {
            document.removeEventListener("mousemove", doResize);
            document.removeEventListener("mouseup", stopResize);
        };

        resizers.forEach((resizer, index) => {
            resizer.addEventListener("mousedown", (e) => initResize(e, index));
        });
    }, []);

    const formatStatus = (status) => {
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "active":
                return "green";
            case "inactive":
                return "red";
            default:
                return "inherit";
        }
    };

    console.log("assignedSponsorData", assignedSponsorData);


    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = assignedSponsorData?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(assignedSponsorData?.length / itemsPerPage);

    return (
        <>
            {loading ? (
                <SpinerDataLoader />
            ) : result?.length > 0 ? (
                <>
                    <div className="team_table overflow-auto"

                    >
                        <Table id="resizable-table"
                            className="custom-table"
                            ref={tableRef}
                            striped
                            border
                            hover
                        >
                            <thead
                                className="position-sticky bg-white px-2"
                                style={{ top: -1 }}
                            >
                                <tr style={{ cursor: "pointer" }}>
                                    <th
                                        style={{ width: 25 }}
                                        onMouseDown={(e) => onMouseDown(e, 0)}
                                    >
                                        Sr.
                                    </th>
                                    <th
                                        style={{ width: 110 }}
                                        onMouseDown={(e) => onMouseDown(e, 1)}
                                    >
                                        Company Name
                                    </th>
                                    <th
                                        style={{ width: 100 }}
                                        onMouseDown={(e) => onMouseDown(e, 2)}
                                    >
                                        Status
                                    </th>
                                    <th
                                        style={{ width: 125 }}
                                        onMouseDown={(e) => onMouseDown(e, 3)}
                                    >
                                        Website
                                    </th>
                                    <th style={{ width: 40, textAlign: "center" }} onMouseDown={(e) => onMouseDown(e, 5)}>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result?.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        onClick={() => handleRowClick(item)}
                                        className={selectedRow === item._id ? "selected" : ""}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <td>{startIndex + index + 1}</td>
                                        <td>{item.companyName}</td>
                                        <td>
                                            <div style={{ color: getStatusColor(item.status) }}>
                                                {formatStatus(item.status)}
                                            </div>
                                        </td>
                                        <td>{item.website}</td>
                                        <td className="button_type text-center">
                                            <button
                                                className="p-1"
                                                style={{
                                                    fontSize: 12,
                                                    borderRadius: 3,
                                                    padding: "2px 8px",
                                                }}
                                                onClick={() => handleRowClick(item)}
                                            >
                                                View Assign Project
                                            </button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className="position-sticky bottom-0 mx-1">
                        <Paginations
                            totalPage={totalPage}
                            pageNumber={pageNumber}
                            itemsPerPage={itemsPerPage}
                            totalItems={assignedSponsorData?.length}
                            setPageNumber={setPageNumber}
                            setItemsPerPage={setItemsPerPage}
                        />
                    </div>
                </>
            ) : (
                <NoDataFoundTable MSG={"Sponsor Not Found!"} />
            )}
        </>

    )
}

export default UserClientList