import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const ChangeColor = ({ getThemeColorData, themeColor }) => {
  const [color, setColor] = useState("");
  const [data, setData] = useState("");
  document.documentElement.style.setProperty(
    "--accent-200",
    `rgb(${color} / 5%)`
  );
  document.documentElement.style.setProperty("--bg-300", `rgb(${color} / 15%)`);
  document.documentElement.style.setProperty(
    "--accent-200",
    `rgb(${color} / 100%)`
  );

  const updateThemeColorData = async (defaultColor) => {
    let userData = {};
    userData.color = defaultColor || color;

    if (data?.status_code === 200) {
      getThemeColorData();
    }
  };

  useEffect(() => {
    setColor(themeColor);
  }, [getThemeColorData, themeColor]);

  return (
    <div>
      <Row>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Select Theme Color</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setColor(e.target.value)}
            aria-label="Default select example"
          >
            <option selected={color === "0 97 154"} value={"0 97 154"}>
              Theme Default
            </option>
            <option selected={color === "1 170 17"} value={"1 170 17"}>
              Green
            </option>
            <option selected={color === "160 0 235"} value={"160 0 235"}>
              Purple
            </option>
            <option selected={color === "72 72 72"} value={"72 72 72"}>
              Black & White
            </option>
          </Form.Select>
          <div className="hstack gap-3 button_type">
            <button onClick={() => updateThemeColorData("0 97 154")}>
              Reset
            </button>
            <button onClick={() => updateThemeColorData()}>Save</button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default ChangeColor;
