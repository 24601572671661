import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const AdvanceTab = ({ minValue, setMinValue, maxValue, setMaxValue }) => {
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Min Value </b>
          </Form.Label>
          <Form.Control
            type="number"
            defaultValue={minValue}
            onChange={(e) => setMinValue(e.target.value)}
            placeholder="Enter Min Value"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Max Length </b>
          </Form.Label>
          <Form.Control
            type="number"
            defaultValue={maxValue}
            onChange={(e) => setMaxValue(e.target.value)}
            placeholder="Enter Max Value"
          />
        </Form.Group>
      </div>
    </>
  );
};

export default AdvanceTab;
