import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import TimeTempelateCustomization from "./TimeTempelateCustomization";
import UseToggle from "../../../Hooks/UseToggle";
import TimePicker from "rc-time-picker";
import moment from "moment";

const Time = ({ deleteSection, section, sections, addUpdateFormById }) => {
  const { toggle, setToggle } = UseToggle();
  const [time, setTime] = useState(section?.defaultValue || moment());
  const [formData, setFormData] = useState(section);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [sections, section.id]);

  const handleTimeChange = (value) => {
    if (value && moment.isMoment(value)) {
      setTime(value);
      setFormData((prevData) => ({
        ...prevData,
        defaultValue: value,
      }));
    } else if (typeof value === "string") {
      const newValue = moment(value, formData?.format || "HH:mm");
      if (newValue.isValid()) {
        setTime(newValue);
        setFormData((prevData) => ({
          ...prevData,
          defaultValue: newValue,
        }));
      } else {
        console.error("Invalid time string:", value);
        setTime(null);
      }
    } else {
      console.error("Unexpected time value:", value);
      setTime(null);
    }
  };

  console.log("formData", formData);


  return (
    <>
      <div className="position-relative" style={{ marginBottom: 25 }}>
        <div className="templatesHeading mt-2"><span style={{ color: "#fff", fontWeight: "bold", fontSize: "11px" }}>TIME</span></div>
      </div>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between mb-2">
          <Form.Label className="mt-2 mb-0 d-flex gap-1">
            <b>
              <span
                style={{
                  fontWeight: formData?.labelStyle?.bold
                    ? formData.customize
                      ? "bold"
                      : "normal"
                    : "normal",
                  fontStyle: formData?.labelStyle?.italic
                    ? formData.customize
                      ? "italic"
                      : "normal"
                    : "normal",
                  color: formData.customize
                    ? formData?.labelStyle?.color
                    : "#000",
                }}
              >
                {formData?.label ? formData?.label : "Label"}
              </span>
              {formData?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </b>
            {formData?.help && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-header">{formData?.help}</Tooltip>
                }
              >
                <i className="fa-solid fa-circle-question text-dark"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header">Your Time Order No.</Tooltip>}
            >
              <Form.Control
                type="text"
                disabled
                value={formData?.orderNo}
                className="templateorderform_Control"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Time Customize</Tooltip>}
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={setToggle}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-pen-to-square px-1 py-1 text-success"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Delete Time</Tooltip>}
            >
              <button
                style={{ cursor: "pointer" }}
                onClick={() => deleteSection()}
                className="teams_button border rounded-1  fs-12"
              >
                <i className="fa-solid fa-trash text-danger px-1 py-1 fs-12"></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="w-100">
          <Form.Control
            type="time"
            className="w-100"
            value={formData?.defaultValue}
            disabled
          />
        </div>
      </div>



      <TimeTempelateCustomization
        show={!toggle}
        onHide={setToggle}
        Title="Time"
        id={formData?.id}
        type={formData?.type}
        addUpdateFormById={addUpdateFormById}
        sections={sections}
      />
    </>
  );
};

export default Time;
