// RecentActivityTable.js
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { clientServices } from "../../APIServices/clientServices";
import { NoDataFoundTable } from "../../Hooks/NoDataFoundTable";
import Paginations from "../../Hooks/Paginations";

const RecentActivityTable = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getUserLogs = async () => {
    setLoading(true);
    let data = await clientServices.getUserLogs();
    setUserData(data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getUserLogs();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = userData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(userData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <Spinner animation="border" />
        </div>
      ) : result?.length > 0 ? (
        <>
          <div
            className="overflow-auto m-1"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <Table
              className="table-bordered table-hover mb-3 bg-light"
              striped
              bordered
              hover
            >
              <thead className="cardTable">
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Activity </th>
                  <th>Date And Time</th>
                </tr>
              </thead>
              <tbody>
                {userData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.updateBy}</td>
                    <td>{item.activity}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.timestamp}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={userData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoDataFoundTable MSG={"No Recent Activity Data"} />
      )}
    </>
  );
};

export default RecentActivityTable;
