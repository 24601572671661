import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { teamServices } from "../../APIServices/teamServices";

const UserInvitationModal = ({ Show, Hide, Title, Submit, Size }) => {
  const [userEmail, setUserEmail] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.email = userEmail;
    let data = await teamServices.addInvitei(userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      setUserEmail("");
      setStatusCode(true);
    } else {
      setLoader(false);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
      setIsEmailValid(true);
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email");
      setIsEmailValid(false);
    }
  };

  const isDisabled = !userEmail;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill all mandatory fields
    </Tooltip>
  );

  const clearFormData = () => {
    Hide();
    setUserEmail("");
    setIsEmailValid(false);
  };
  return (
    <>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header className="modal_closeButton">
          <Modal.Title>{Title}</Modal.Title>
          <div
            onClick={() => clearFormData()}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body className="modal_bodyText">
          <Form.Group>
            <Form.Label>
              <b> User Email</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="email"
              className="rounded-1"
              placeholder="Enter Your email"
              onChange={(e) => {
                setUserEmail(e.target.value);
                validateEmail(e.target.value);
              }}
            />
            {emailError && (
              <p className="text-danger" style={{ marginTop: "5px" }}>
                {emailError}
              </p>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className=" px-2">
          <div className="button_type">
            <OverlayTrigger
              placement="top"
              overlay={isDisabled ? renderTooltip : <span></span>}
            >
              <button
                type="submit"
                onClick={handleSubmit}
                className={`px-2 ${
                  isEmailValid ? "button_type" : "button-disabled"
                }`}
                disabled={!isEmailValid}
              >
                {loader ? (
                  <Spinner
                    animation="border"
                    style={{ width: 15, height: 15 }}
                    className="me-2"
                  />
                ) : null}
                Send Email
              </button>
            </OverlayTrigger>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserInvitationModal;
