import React, { useEffect, useState } from "react";
import {
    Col,
    Form,
    FormGroup,
    Modal,
    OverlayTrigger,
    Row,
} from "react-bootstrap";
import { chatBotServices } from "../../APIServices/chatBotServices";
import Multiselect from "multiselect-react-dropdown";
import { teamServices } from "../../APIServices/teamServices";

function CreateChatRoomModal({
    Show,
    Hide,
    Size,
    name,
    setName
}) {
    const [loader, setLoader] = useState(false);
    const [members, setMembers] = useState(0);
    const [message, setMessage] = useState("");
    const [messageArray, setMessageArray] = useState([]);
    const [statusMessage, setStatusMessage] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selecteduserIds, setSelectedUserIds] = useState([]);
    const [optiones, setOptiones] = useState([]);

    const handleSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        let userData = {};
        userData.name = name;
        userData.users = selecteduserIds;
        userData.members = members;
        userData.messages = messageArray;
        let data = await chatBotServices.createRoom(userData);
        setStatusMessage(data);
        if (data?.status_code === 200) {
            setName("");
            setMessage("");
            Hide();
            setLoader(false);
        }
    };

    const fetchOptions = async () => {
        let data = await teamServices.getUsers();
        console.log("data", data);
        if (data?.status_code === 200) {
            const formattedOptions = data?.data?.map((user) => ({
                label: user.fullName,
                value: user?._id?.$oid,
            }));
            setOptiones(formattedOptions);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const handleSelect = (selectedList) => {
        setSelectedOptions(selectedList);
        updateSelectedIds(selectedList);
        setMembers(selectedList.length);
    };

    const handleRemove = (selectedList, removedItem) => {
        let NewObjectData = selectedList?.filter(
            (item) => !(item.value === removedItem?.value)
        );
        setSelectedOptions(NewObjectData);
        updateSelectedIds(NewObjectData);
        setMembers(NewObjectData.length);
    };

    const updateSelectedIds = (selectedList) => {
        const ids = selectedList.map((item) => item.value);
        setSelectedUserIds(ids);
    };

    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        setMessage(newMessage);
        setMessageArray([newMessage]);
    };


    return (
        <>
            <Modal show={Show} onHide={Hide} size={Size}>
                <Modal.Header className="modal_closeButton">
                    <Modal.Title>Create Chat Room</Modal.Title>
                    <div
                        onClick={() => Hide()}
                        className="close_modal"
                        title="Close"
                    >
                        X
                    </div>
                </Modal.Header>
                <Modal.Body className="modal_bodyText">
                    <Row>
                        <FormGroup as={Col} md={12} className="mb-2">
                            <Form.Label className="mb-2 fw-bold">
                                <b> Group Name</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Your  Name"
                                className="rounded-1"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </FormGroup>
                        <FormGroup as={Col} md={12} className="mb-2">
                            <Form.Label className="mb-2 fw-bold">
                                <b>Users</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Multiselect
                                displayValue="label"
                                onKeyPressFn={() => { }}
                                onRemove={handleRemove}
                                onSelect={handleSelect}
                                options={optiones}
                                selectedValues={selectedOptions}
                            />
                        </FormGroup>
                        <FormGroup as={Col} md={12} className="mb-2">
                            <Form.Label className="mb-2 fw-bold">
                                <b>Members</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Tempelates Name"
                                className="rounded-1"
                                value={members}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup as={Col} md={12} className="mb-2">
                            <Form.Label className="mb-2 fw-bold">
                                <b>Group Description</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Your Message"
                                className="rounded-1"
                                onChange={handleMessageChange}
                                value={message}
                            />
                        </FormGroup>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end">
                        <Form.Group as={Col} md="12">
                            <div className="button_type">

                                <button
                                    // disabled={!name || loader}
                                    variant="outline-dark"
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="modalFooterSubmit_button m-0"
                                >

                                    Create Room
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CreateChatRoomModal;
