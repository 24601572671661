import React, { useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const AdvanceTab = () => {
  const [checkType, setCheckType] = useState("");

  const handleCheckTypeChange = (e) => {
    setCheckType(e.target.value);
  };

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label>
          <b>CheckBox Restriction</b>
        </Form.Label>
        <Form.Select value={checkType} onChange={handleCheckTypeChange}>
          <option value="">Select</option>
          <option value="singlecheck">Single Check</option>
          <option value="multicheck">Multi Check</option>
          <option value="customcheck">Custom Check</option>
        </Form.Select>
      </Form.Group>
      {checkType === "customcheck" && (
        <Form.Group className="mb-2">
          <Form.Label>
            <b>Limit Custom Check</b>
          </Form.Label>
          <Form.Control type="number" placeholder="Custom Check Number" />
        </Form.Group>
      )}
    </div>
  );
};

export default AdvanceTab;
