import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithBody,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const teamServices = {
  addUsers,
  addInvitei,
  getUsers,
  changeStatus,
  deleteUser,
  updateUser,
  getActiveUsers,
  getUserById
};

//addUser
async function addUsers(userData) {
  const response = await fetch(
    `${config.API_URL}addUsers`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//addInvitation
async function addInvitei(userData) {
  const response = await fetch(
    `${config.API_URL}addInvitei`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// getUsers
async function getUsers() {
  const response = await fetch(
    `${config.API_URL}getUsers`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getUserById
async function getUserById(_id) {
  const response = await fetch(
    `${config.API_URL}getUserById/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getActiveUsers
async function getActiveUsers() {
  const response = await fetch(
    `${config.API_URL}getActiveUsers`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// changeStatus;
async function changeStatus(_id, userData) {
  const response = await fetch(
    `${config.API_URL}changeStatus/${_id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//deleteUser;
async function deleteUser(_id) {
  const response = await fetch(
    `${config.API_URL}deleteUser/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//updateUser
async function updateUser(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateUser/${_id}`,
    headersWithAuthWithBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
