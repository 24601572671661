import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import GroupChat from './GroupChat';
import SingleChat from './SingleChat';
import ChatBox from './ChatBox';
import CreateChatRoomModal from './CreateChatRoomModal';
import UseToggle from '../../Hooks/UseToggle';

const Chat = ({ teamsData }) => {
    const { toggle, setToggle } = UseToggle();
    const [name, setName] = useState("");
    return (
        <>
            <div>
                <Container fluid>
                    <Row>
                        <Col md={4}>
                            <div className="p-2">
                                <div className="p-2 border shadow-sm rounded-3 mb-1">
                                    <div className="hstack">
                                        <i class="fa-solid fa-magnifying-glass mx-2"></i>
                                        <Form.Control
                                            type="text"
                                            placeholder="Type your search here..."
                                            className="message-input-field border-0"
                                        />
                                        <button
                                            title="Add Quotations"
                                            onClick={setToggle}
                                            className="teams_button border rounded-1 fs-12"
                                        >
                                            <i className="fa-solid fa-plus text-muted mt-1"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <GroupChat />
                            <SingleChat teamsData={teamsData} />
                        </Col>
                        <Col md={8}>
                            <ChatBox />
                        </Col>
                    </Row>
                </Container>
            </div>
            <CreateChatRoomModal
                Show={!toggle}
                Hide={setToggle}
                Size={"md"}
                setName={setName}
                name={name}
                teamsData={teamsData}
            />
        </>

    )
}

export default Chat