import React, { useEffect, useState } from "react";
import { Form, Offcanvas, FormGroup, Col, Spinner } from "react-bootstrap";
import { quotationServices } from "../../../APIServices/quotationServices";

const PhaseEstimateOffcanvas = ({
  show,
  onHide,
  size,
  getAllQServices,
  getAllPhase,
  phaseid,
  setPhaseid
}) => {
  const [phaseName, setPhaseName] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.phaseName = phaseName;
    let data = await quotationServices.addPhase(userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      clearFormData();
      getAllPhase();
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleUpdate = async (e) => {
    setLoader(true);
    e.preventDefault();
    let _id = phaseid;
    let userData = {};
    userData.phaseName = phaseName;
    let data = await quotationServices.updatePhase(_id, userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      clearFormData();
      getAllPhase();
      setPhaseid("")
      setLoader(false);
    }
  }

  const clearFormData = () => {
    onHide();
    setPhaseName("");
    setPhaseid("")
  };

  return (
    <Offcanvas
      show={show}
      size={size}
      onHide={onHide}
      placement="end"
      style={{ width: "400px" }}
    >
      <Offcanvas.Header className="py-2">
        <div className="hstack justify-content-between w-100 gap-3">
          <Offcanvas.Title>
            <b>{!phaseid ? "Add " : "Update "} Phase </b>
          </Offcanvas.Title>
          <div
            onClick={clearFormData}
            className="offcanvas_Close"
            title="Close"
            style={{ cursor: "pointer" }}
          >
            X
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column p-2">
        <div className="flex-grow-1 overflow-auto">
          <FormGroup as={Col} md={12} className="mb-2">
            <Form.Label>
              <b>Phase Name :</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter your phase name"
              onChange={(e) => setPhaseName(e.target.value)}
              value={phaseName}
            />
          </FormGroup>
          <div className="text-end py-3">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <button type="submit"
                  onClick={!phaseid ? handleSubmit : handleUpdate}>
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}
                  {!phaseid ? "Add " : "Update "} Phase
                </button>
              </div>
            </Form.Group>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PhaseEstimateOffcanvas;
