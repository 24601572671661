import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

const SponcerViewProperty = ({ propertiesData }) => {
    const [id, setId] = useState("")
    console.log("propertiesData", id);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "active":
                return "green";
            case "inactive":
                return "red";
            case "pending":
                return "orange";
            default:
                return "inherit";
        }
    };

    useEffect(() => {
        if (propertiesData?._id?.$oid) {
            setId(propertiesData._id.$oid);
        }
    }, [propertiesData]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className="fw-bold">Attributes</th>
                        <th className="fw-bold">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <b>Company Name</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.companyName}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Company ID</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.companyID}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Office Phone</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.officePhoneNumber}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Status</b>
                        </td>
                        <td style={{ color: getStatusColor(propertiesData?.status) }}>
                            <span className="p-1">
                                {capitalizeFirstLetter(propertiesData?.status || '')}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Website</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.website}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Address</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.Address}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Admin</b>
                        </td>
                        <td>
                            <span className="p-1">
                                GalaxBiotech
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b> Country</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.Country}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>State</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.State}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>City</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.City}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <b>Zip Code</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.zipCode}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default SponcerViewProperty