import React, { useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const AdvanceTab = () => {
  const [radioType, setRadioType] = useState("");

  const handleRadioTypeChange = (e) => {
    setRadioType(e.target.value);
  };

  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label>
            <b>RadioButton Restriction</b>
          </Form.Label>
          <Form.Select value={radioType} onChange={handleRadioTypeChange}>
            <option value="">Select</option>
            <option value="singlecheck">Single Check</option>
            <option value="multicheck">Multi Check</option>
            <option value="customcheck">Custom Check</option>
          </Form.Select>
        </Form.Group>
        {radioType === "customcheck" && (
          <Form.Group className="mb-2">
            <Form.Label>
              <b>Limit Radio Check</b>
            </Form.Label>
            <Form.Control type="text" placeholder="Custom Check Number" />
          </Form.Group>
        )}
      </div>
    </>
  );
};

export default AdvanceTab;
