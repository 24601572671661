import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { TbCirclePlus } from "react-icons/tb";
import AddTrainingData from "./AddTrainingData";
import UseToggle from "../../../Hooks/UseToggle";
import { Card, Col, Form, Row } from "react-bootstrap";
import { elearningServices } from "../../../APIServices/elearningServices";

const TrainingDataManagement = ({ trandingId, questions, setQuestions, multiselectAnswer, setMultiselectAnswer }) => {
  const [mode, setMode] = useState("add");
  const { toggle1, setToggle1 } = UseToggle();
  const [etrainingData, setEtrainingData] = useState([]);
  const [updateTrainingDetails, setUpdateTrainingDetails] = useState([]);
  const [updateTrainingQuizDetails, setUpdateTrainingQuizDetails] = useState([]);
  const [updateTrainingId, setUpdateTrainingId] = useState("");

  const getAllTraining = async () => {
    let data = await elearningServices.getAllTraining(trandingId);
    setEtrainingData(data?.data);
  };

  const updateTrainingstatus = async (_id, status) => {
    let data = {};
    data.status = status;
    let results = await elearningServices.updateTrainingFile(_id, data);
    if (results?.status_code === 200) {
      getAllTraining()
    }
  };

  const updateTrainingquizstatus = async (_id, status) => {
    let data = {};
    data.status = status;
    let results = await elearningServices.updateTrainingquiz(_id, data);
    if (results?.status_code === 200) {
      getAllTraining()
    }
  };

  const addTraining = () => {
    setToggle1();
    setMode("add");
  };

  const upadateTraining = (item) => {
    setToggle1();
    setMode("update");
    setUpdateTrainingDetails(item)
    setUpdateTrainingId(item?._id?.$oid)

  };

  const upadateTrainingQuizData = (item) => {
    setToggle1();
    setMode("update");
    setUpdateTrainingQuizDetails(item)
  };

  useEffect(() => {
    getAllTraining();
  }, []);

  return (
    <>
      <div
        className="p-2 overflow-auto"
        style={{ height: "calc(100vh - 90px)" }}
      >
        <div className="d-flex gap-2 flex-wrap">
          {etrainingData?.map((item, index) => (
            <>
              {item.type === "file" ? (
                <Card
                  style={{ width: "18rem" }}
                  className="shadow-sm rounded-1 overflow-hidden cursor-pointer"
                  key={index}
                >
                  <Card.Body className="p-0">
                    <div className="p-2 px-3 border-bottom d-flex justify-content-between">
                      <Card.Title className="fontSize12 m-0 fw-bold">
                        {item.title}
                      </Card.Title>
                      <span className="text-muted ms-1" style={{ fontSize: 11 }}>
                        <Form.Check
                          checked={item.status === "active"}
                          type="switch"
                          id={`custom-switch-${index}`}
                          onChange={() =>
                            updateTrainingstatus(
                              item._id.$oid,
                              item.status === "active" ? "inactive" : "active"
                            )
                          }
                        />
                      </span>
                    </div>
                    <div
                      className="px-3 py-1 overflow-auto CP"
                      title="Click for Edit"
                      style={{ height: 150 }}
                      onClick={() => upadateTraining(item)}
                    >
                      <Card.Text className="text-muted" style={{ fontSize: 12 }}>
                        {item.description}
                      </Card.Text>
                    </div>
                    <div className="px-3 py-1">
                      <Row>
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            Type :
                            <span className="text-muted ms-1" style={{ fontSize: 10 }}>
                              {item.type}
                            </span>
                          </p>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            {item.type === "file" ? "No. of Pages:" : "Duration:"}
                            <span
                              className="text-muted ms-1"
                              style={{ fontSize: 10 }}
                            >
                              {item.type === "file" ? "10" : "2:30hr"}
                            </span>
                          </p>
                        </Form.Group>
                      </Row>
                      <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                        Categories :
                        <span className="text-muted ms-1" style={{ fontSize: 10 }}>
                          Knowledge Checking Quiz
                        </span>
                      </p>
                    </div>
                    <div className="border-top bg-light p-2 px-3 d-flex justify-content-between">
                      <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                        Last Update Date | Time :
                        <span
                          className="text-muted ms-1"
                          style={{ fontSize: 10 }}
                        >
                          <Moment format="DD MMM YYYY | HH:mm">
                            {item.updated_at?.$date}
                          </Moment>
                        </span>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                <Card
                  style={{ width: "18rem" }}
                  className="shadow-sm rounded-1 overflow-hidden cursor-pointer"
                  key={index}
                >
                  <Card.Body className="p-0">
                    <div className="p-2 px-3 border-bottom d-flex justify-content-between">
                      <Card.Title className="fontSize12 m-0 fw-bold">
                        {item.title}
                      </Card.Title>
                      <span className="text-muted ms-1" style={{ fontSize: 11 }}>
                        <Form.Check
                          checked={item.status === "active"}
                          type="switch"
                          id={`custom-switch-${index}`}
                          onChange={() =>
                            updateTrainingquizstatus(
                              item._id.$oid,
                              item.status === "active" ? "inactive" : "active"
                            )
                          }
                        />
                      </span>
                    </div>
                    <div
                      className="px-3 py-1 overflow-auto CP"
                      title="Click for Edit"
                      style={{ height: 150 }}
                      onClick={() => upadateTrainingQuizData(item)}
                    >
                      <Card.Text className="text-muted" style={{ fontSize: 12 }}>
                        {item.description}
                      </Card.Text>
                    </div>
                    <div className="px-3 py-1">
                      <Row className="position-sticky bottom-0">
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            Type :
                            <span className="text-muted ms-1" style={{ fontSize: 10 }}>
                              {item.type}
                            </span>
                          </p>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            No. of Ques :
                            <span className="text-muted ms-1" style={{ fontSize: 10 }}>
                              {item.questionPerQuiz}
                            </span>
                          </p>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            Ques Per Quiz :
                            <span className="text-muted ms-1" style={{ fontSize: 10 }}>
                              {item.questionPerQuiz}
                            </span>
                          </p>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                            Categories :
                            <span
                              className="text-muted ms-1"
                              style={{ fontSize: 10 }}
                            >
                              {item.category}
                            </span>
                          </p>
                        </Form.Group>
                      </Row>
                    </div>
                    <div className="border-top bg-light p-2 px-3 d-flex justify-content-between">
                      <p className="mb-1 fw-bold" style={{ fontSize: 11 }}>
                        Last Update Date | Time :
                        <span
                          className="text-muted ms-1"
                          style={{ fontSize: 10 }}
                        >
                          <Moment format="DD MMM YYYY | HH:mm">
                            {item.updated_at?.$date}
                          </Moment>
                        </span>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </>
          ))}
          <Card
            style={{ width: "18rem", height: "18rem" }}
            className="shadow-sm rounded-1 overflow-hidden bg-light CP cardHoverAdd cursor-pointer"
            title="Add New..."
            onClick={() => addTraining()}
          >
            <Card.Body className="p-0 hstack justify-content-center">
              <TbCirclePlus style={{ width: 40, height: 40 }} />
            </Card.Body>
          </Card>
        </div>

      </div>
      <AddTrainingData
        mode={mode}
        Show={!toggle1}
        Hide={setToggle1}
        trandingId={trandingId}
        questions={questions}
        setQuestions={setQuestions}
        multiselectAnswer={multiselectAnswer}
        setMultiselectAnswer={setMultiselectAnswer}
        etrainingData={etrainingData}
        getAllTraining={getAllTraining}
        updateTrainingDetails={updateTrainingDetails}
        updateTrainingId={updateTrainingId}
        updateTrainingQuizDetails={updateTrainingQuizDetails}
        Title={`${mode === "add" ? "Add" : "Update"} Training`}
      />
    </>
  );
};

export default TrainingDataManagement;
