import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  FormGroup,
  Col,
  Spinner,
} from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";
import { clientServices } from "../../APIServices/clientServices";

const UpdateClientModal = ({
  Show,
  Hide,
  Size,
  clientDetails,
  getAllSponsor,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [officephoneNumber, setOfficephoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [address, setAddress] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [loader, setLoader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data);
  };

  const updateSponsorClient = async (e) => {
    setLoader(true);
    e.preventDefault();
    let _id = clientDetails?._id?.$oid;
    let userData = {};
    userData.companyName = companyName;
    userData.shortName = shortName;
    userData.website = website;
    userData.Address = address;
    userData.officePhoneNumber = officephoneNumber;
    userData.City = city_id;
    userData.State = state_id;
    userData.Country = country_id;
    userData.zipCode = postalCode;
    let data = await clientServices.updateSponsorClient(_id, userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      Hide();
      getAllSponsor();
      setStatusCode(true);
    } else {
      setLoader(false);
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits");
    }
  };

  const clearFormData = () => {
    Hide();
    setCompanyName("");
    setShortName("");
    setWebsite("");
    setOfficephoneNumber("");
    setAddress("");
    setPostalCode("");
    setCityID("");
    setStateID("");
    setCountryID("");
  };

  useEffect(() => {
    getCountries();
    getStates();
    getCities();
    setCompanyName(clientDetails?.companyName);
    setShortName(clientDetails?.shortName);
    setWebsite(clientDetails?.website);
    setOfficephoneNumber(clientDetails?.officePhoneNumber);
    setAddress(clientDetails?.Address);
    setPostalCode(clientDetails?.zipCode);
    setCountryID(clientDetails?.Country);
  }, [clientDetails]);

  console.log("countryLists", countryLists);


  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title> Update Sponsor Client</Modal.Title>
            <div
              onClick={() => clearFormData()}
              className="close_modal"
              title="Close"
            >
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Row>
              <FormGroup as={Col} md={6} className="mb-2">
                <Form.Label>
                  <b>Company Name</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Company  Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                />
              </FormGroup>
              <FormGroup as={Col} md={6} className="mb-2">
                <Form.Label>
                  <b>Short Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Short Name"
                  onChange={(e) => setShortName(e.target.value)}
                  value={shortName}
                />
              </FormGroup>
              <FormGroup as={Col} md={6} className="mb-2">
                <Form.Label>
                  <b>Website Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Website Name"
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                />
              </FormGroup>

              <Form.Group as={Col} md={6} className="mb-2">
                <Form.Label>
                  <b> Office Phone Number</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Phone Number"
                  onChange={(e) => {
                    setOfficephoneNumber(e.target.value);
                    validatePhoneNumber(e.target.value);
                  }}
                  value={officephoneNumber}
                />
              </Form.Group>
              <FormGroup as={Col} md={12} className="mb-2">
                <Form.Label>
                  <b>Address</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter your address"
                  style={{ height: "29px" }}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </FormGroup>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label>
                  Country <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setCountryID(e.target.value);
                    getStates(e.target.value);
                  }}
                  value={country_id}
                >
                  <option>-Select-</option>
                  {countryLists?.length > 0 &&
                    countryLists.map((item, key) => (
                      <option value={item.id} key={key} title={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label>
                  State <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setStateID(e.target.value);
                    getCities(e.target.value);
                  }}
                  value={state_id}
                >
                  <option>-select-</option>
                  {stateLists.length > 0 &&
                    stateLists.map((item, key) => (
                      <option value={item.id} key={key} title={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label>
                  City <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setCityID(e.target.value)}
                  value={city_id}
                >
                  <option>-select-</option>
                  {cityLists.length > 0 &&
                    cityLists.map((item, key) => (
                      <option value={item.id} key={key}>
                        {item.name}
                      </option>
                    ))}
                  <Form.Control value={country_id} />
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label>
                  Zip Code <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Postal Code"
                  onChange={(e) => setPostalCode(e.target.value)}
                  value={postalCode}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Form.Group as={Col} md="12" className="mb-2">
                <div className="button_type">
                  <button
                    type="submit"
                    onClick={updateSponsorClient}
                    disabled={
                      (!companyName || loader,
                        !address || loader,
                        !officephoneNumber || loader,
                        !city_id || loader,
                        !state_id || loader,
                        !country_id || loader,
                        !postalCode || loader,
                        phoneError)
                    }
                  >
                    {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 15, height: 15 }}
                        className="me-2"
                      />
                    ) : null}
                    Update
                  </button>
                </div>
              </Form.Group>
            </div>
            {phoneError && (
              <Form.Group as={Col} md="12">
                <div className="d-flex justify-content-between">
                  {phoneError ? (
                    <div>
                      <p className="message m-0" style={{ color: "red" }}>
                        {phoneError}
                      </p>
                    </div>
                  ) : (
                    <p
                      className="message"
                      style={
                        statusMessage?.statusCode === 200
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {statusMessage?.customMessage}
                    </p>
                  )}
                </div>
              </Form.Group>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default UpdateClientModal;
