import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import TeamListModal from "./TeamListModal";
import UseToggle from "../../Hooks/UseToggle";

const PullUserModal = ({ Show, Submit, Hide, Size, Title }) => {
  const { toggle, setToggle } = UseToggle();
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const isDisabled = !userName || !userPassword;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill all mandatory fields
    </Tooltip>
  );

  const openListTable = () => {
    setToggle();
    Hide();
  };

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header className="modal_closeButton">
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body className="modal_bodyText">
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              <b>Username</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your UserName"
              className="rounded-1"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />
          </Form.Group>
          <FormGroup className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              <b>User Password</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <div className="position-relative">
              <Form.Control
                required
                type={showCurrentPassword ? "text" : "password"}
                className="rounded-1"
                placeholder="Enter Your Password"
                onChange={(e) => setUserPassword(e.target.value)}
                value={userPassword}
              />
              <div
                className="passwordvisiability"
                onClick={toggleCurrentPasswordVisibility}
              >
                {showCurrentPassword ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa-regular fa-eye"></i>
                )}
              </div>
            </div>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className=" px-2">
          <OverlayTrigger
            placement="top"
            overlay={isDisabled ? renderTooltip : <span></span>}
          >
            <div className="button_type">
              <button
                onClick={() => openListTable()}
                className={`px-2 ${
                  userName ? "button_type" : "button-disabled"
                }`}
                disabled={!userName || !userPassword}
              >
                {Submit}
              </button>
            </div>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>

      <TeamListModal
        Show={!toggle}
        Hide={setToggle}
        Size={"xl"}
        Title="User List"
        Submit={"Pull User"}
      />
    </div>
  );
};

export default PullUserModal;
