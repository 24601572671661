import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import { workflowServices } from "../../APIServices/workflowServices";
import Swal from "sweetalert2";
import Multiselect from "multiselect-react-dropdown";

function AddWorkFlowModal({
  Show,
  Hide,
  Size,
  getWorkflows,
  setWorkFlowId,
  workFlowId,
  name,
  setName,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [checkedState, setCheckedState] = useState([]);

  const fetchOptions = async () => {
    let data = await workflowServices.getActiveTemplate();
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((template) => ({
        label: template.name,
        value: template?._id?.$oid,
      }));
      setOptions(formattedOptions);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleCheck = (templateId) => {
    setCheckedState((prevStates) =>
      prevStates?.map((item) =>
        item.templateId === templateId
          ? { ...item, required: !item.required }
          : item
      )
    );
  };

  const templateSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    let checkedData = selectedList?.map((item) => ({
      templateId: item?.value,
      required: false,
    }));
    setCheckedState(checkedData);
  };

  const templateRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptions(NewObjectData);
  };

  const clearFormData = () => {
    setLoader(false);
    Hide();
    getWorkflows();
    setWorkFlowId("");
    setName("");
    setSelectedOptions([]);
  };

  const addWorkflow = async (e) => {
    setLoader(true);
    let userData = {};
    userData.name = name;
    userData.templates = checkedState;
    let data = await workflowServices.addWorkflow(userData);
    if (data?.status_code === 200) {
      clearFormData();
    }
  };

  const updateWorkflow = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Workflow Title!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let data = {};
        data.name = name;
        data.templates = checkedState;
        let results = await workflowServices.updateWorkflow(workFlowId, data);
        if (results?.status_code === 200) {
          clearFormData();

        }
      }
    });
  };

  return (
    <>
      <Modal show={Show} onHide={Hide} size={Size}>
        <Modal.Header className="modal_closeButton">
          <Modal.Title>{!workFlowId ? "Add" : "Update"} WorkFlow</Modal.Title>
          <div
            onClick={() => clearFormData()}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body className="modal_bodyText">
          <Form.Label>
            <b>Workflow Name</b> <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Workflow Name"
            className="mb-2"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <Form.Group className="mb-2">
            <Form.Label>
              <b>Templates</b>
            </Form.Label>

            <Multiselect
              displayValue="label"
              onKeyPressFn={() => { }}
              onRemove={templateRemove}
              onSelect={templateSelect}
              options={options}
              selectedValues={selectedOptions}
            />
          </Form.Group>
          {selectedOptions?.length > 0 && (
            <Form.Group className="mt-2">
              <Form.Label>
                <b>Check Required Templates</b>
              </Form.Label>
              {selectedOptions?.map((item) => (
                <Form.Check
                  key={item.value}
                  type="checkbox"
                  value={item.value}
                  label={item.label}
                  onChange={() => handleCheck(item.value)}
                />
              ))}
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <button
                  disabled={!name || loader}
                  type="submit"
                  onClick={!workFlowId ? addWorkflow : updateWorkflow}
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : (
                    <i
                      className={`me-2 fa ${!workFlowId ? "fa-square-plus" : "fa-pen-to-square"
                        }`}
                    ></i>
                  )}
                  {!workFlowId ? "Add" : "Update"} WorkFlow
                </button>
              </div>
            </Form.Group>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddWorkFlowModal;
