import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const ResultRadialChart = ({ responseData}) => {
  
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: '16px',
            color: 'red',
          },
          value: {
            fontSize: '22px',
            color: '#103c5e',
          },
        },
        track: {
          background: '#f2f2f2',
        },
      },
    },
    fill: {
      colors: ['#103c5e'],
    },
    labels: [responseData?.status || 'Result'],
  });

  const [series, setSeries] = useState([0]);

  useEffect(() => {
    if (responseData !== undefined) {
      setSeries([responseData]);

      if (responseData >= 80) {
        setOptions((prevOptions) => ({
          ...prevOptions,
          fill: {
            colors: ['#28a745'],
          },
          plotOptions: {
            ...prevOptions.plotOptions,
            radialBar: {
              ...prevOptions.plotOptions.radialBar,
              dataLabels: {
                ...prevOptions.plotOptions.radialBar.dataLabels,
                name: {
                  ...prevOptions.plotOptions.radialBar.dataLabels.name,
                  color: '#28a745',
                },
                value: {
                  ...prevOptions.plotOptions.radialBar.dataLabels.value,
                  color: '#28a745',
                },
              },
            },
          },
        }));
      } else {
        setOptions((prevOptions) => ({
          ...prevOptions,
          fill: {
            colors: ['#dc3545'],
          },
          plotOptions: {
            ...prevOptions.plotOptions,
            radialBar: {
              ...prevOptions.plotOptions.radialBar,
              dataLabels: {
                ...prevOptions.plotOptions.radialBar.dataLabels,
                name: {
                  ...prevOptions.plotOptions.radialBar.dataLabels.name,
                  color: '#dc3545',
                },
                value: {
                  ...prevOptions.plotOptions.radialBar.dataLabels.value,
                  color: '#dc3545',
                },
              },
            },
          },
        }));
      }
    }
  }, [responseData]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="radialBar" height={350} />
    </div>
  );
};

export default ResultRadialChart;




