import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Team from "../Pages/Team/Team";
import Study from "../StudyDashBoard/Study";
import { useParams } from "react-router-dom";
import Analytics from "../Pages/Analytics/Analytics";
import ElearningData from "./ELearning/ElearningData";
import Profile from "../Pages/Settings/Profile/Profile";
import LogData from "../Pages/Settings/Log Data/LogData";
import FormBuilder from "../Pages/Tempelets/FormBuilder";
import { userServices } from "../APIServices/userServices";
import { teamServices } from "../APIServices/teamServices";
import PhaseSidebar from "../Pages/Quotations/PhaseSidebar";
import PhaseEstimate from "../Pages/Quotations/PhaseEstimate";
import Apperance from "../Pages/Settings/Apperance/Apperance";
import FaildData from "../Pages/Settings/Faild Data/FaildData";
import WorkflowSidebar from "../Pages/WorkFlow/WorkflowSidebar";
import ELearningViewModule from "./ELearning/ELearningViewModule";
import { workflowServices } from "../APIServices/workflowServices";
import { quotationServices } from "../APIServices/quotationServices";
import RecentActivityTable from "../Pages/Analytics/RecentActivityTable";
import ELearningManagement from "./ELearningManagement/ELearningManagement";
import PageRectriction403 from "../Pages/PageRestricton/PageRectriction403";
import TrainingDataManagement from "./ELearningManagement/TrainingDataManagement/TrainingDataManagement";
import DeginationTable from "../Pages/OrganizationChart/Component/DeginationTable";
import OrgChart from "../Pages/OrganizationChart/OrgChart";
import { elearningServices } from "../APIServices/elearningServices";
import Invoice from "../Pages/Tax Invoice/Invoice";
import { v4 as uuidv4 } from 'uuid';
import Sponsor from "../Pages/Sponsor/Sponsor";
import UserSpouncer from "../ProjectManagementUsers/UserSpouncer";
import TaxInvoiceData from "../Pages/Tax Invoice/TaxInvoiceData";
import Chat from "./ChatBot/Chat";

const Panel = ({
  tableRef,
  roleType,
  tableRef1,
  tableRef2,
  onMouseDown,
  onMouseDown1,
  onMouseDown2,
  currentUserData,
  userCurrrentData,
  currentUserId,
}) => {
  const [loading, setLoading] = useState(true);
  const [teamsData, setTeamsData] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [phaseData, setPhaseData] = useState([]);
  const [workFlowId, setWorkFlowId] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [workflowList, setWorkflowList] = useState([]);
  const [qutationData, setQutationData] = useState([]);
  const [openlearning, setOpenlearning] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userworkflowid, setUserworkflowid] = useState(null);
  const [userSideTab, setUserSideTab] = useState(null);
  const [userworkflowList, setUserWorkflowList] = useState([]);
  const [openAnalyticsClient, setOpenAnalyticsClient] = useState("");
  const [isWorkflowCollapsed, setIsWorkflowCollapsed] = useState(false);
  const [isCollapsedWorkFlow, setIsCollapsedWorkFlow] = useState(false);
  const [clientStudyworkFlowId, setClientStudyworkFlowId] = useState("");
  const [isCollapseQuotations, setIsCollapseQuotations] = useState(false);
  const [isCollapseTempelates, setIsCollapseTempelates] = useState(false);
  const [isTempelatesCollapsed, setIsTempelatesCollapsed] = useState(false);
  const [userTrainingQues, setUserTrainingQues] = useState([]);
  const [trainingIds, setTrainingIds] = useState("");
  const [multiselectAnswer, setMultiselectAnswer] = useState([]);


  const questionId = uuidv4();

  const toggleCollpased = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleWorkFlowCollpased = () => {
    setIsWorkflowCollapsed(!isWorkflowCollapsed);
  };

  const [questions, setQuestions] = useState([
    { questionId, question: "", options: ["", "", "", ""], answerType: "", multiselectAnswer },
  ]);

  const toggleTempelatesCollpased = () => {
    setIsCollapseTempelates(!isCollapseTempelates);
  };


  const toggleQuotationsCollpased = () => {
    setIsCollapseQuotations(!isCollapseQuotations);
  };

  const getAllPhase = async () => {
    let data = await quotationServices.getAllPhase(); // Phase option Listing in Spouncer side
    setPhaseData(data?.data);
    setLoading(false);
  };

  const getUsers = async () => {
    let data = await teamServices.getUsers();
    setTeamsData(data?.data);
    setLoading(false);
  };

  const getWorkflows = async () => {
    setListLoader(true);
    let data = await workflowServices.getWorkflows();
    setWorkflowList(data.data);
    setListLoader(false);
  };

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const currentPath = splitUrl[3];

  const loginType = localStorage.getItem("loginType");

  const { studyId } = useParams();

  const getWorkflowByStudy = async () => {
    let data = await workflowServices.getWorkflowByStudy(studyId); // client Study workflow listing
    setQutationData(data?.data);
  };


  const getUserTrainingQues = async () => {
    setLoading(true);
    let data = await elearningServices.getTrainingForUser(studyId ? studyId : "undefined");
    setUserTrainingQues(data?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (userSideTab === "elearning" || currentPath === "e-learning") {
      getUserTrainingQues();
    }
  }, [userSideTab]);

  const getAllWorkflowForUser = async () => {
    setListLoader(true);
    let data = await userServices.getAllWorkflowForUser(studyId); // All UserWorkflow LIsting
    setUserWorkflowList(data?.data);
    setListLoader(false);
  };

  useEffect(() => {
    getWorkflowByStudy();
  }, [studyId]);

  useEffect(() => {
    getUsers();
    getWorkflows();
  }, []);

  return (
    <>
      <Header userCurrrentData={userCurrrentData} roleType={roleType} />
      <div className="d-flex ">
        <div
          style={{ width: isCollapsed ? "3%" : "15%" }}
          className={isCollapsed ? "smoothCollapse" : "smoothCollapse"}
        >
          <Sidebar
            getAllWorkflowForUser={getAllWorkflowForUser}
            userSideTab={userSideTab}
            setUserSideTab={setUserSideTab}
            userworkflowList={userworkflowList}
            toggleCollpased={toggleCollpased}
            isCollapsed={isCollapsed}
            isCollapsedWorkFlow={isCollapsedWorkFlow}
            toggleWorkFlowCollpased={toggleWorkFlowCollpased}
            toggleTempelatesCollpased={toggleTempelatesCollpased}
            toggleQuotationsCollpased={toggleQuotationsCollpased}
            isWorkflowCollapsed={isWorkflowCollapsed}
            isCollapseTempelates={isCollapseTempelates}
            isCollapseQuotations={isCollapseQuotations}
            setIsTempelatesCollapsed={setIsTempelatesCollapsed}
            isTempelatesCollapsed={isTempelatesCollapsed}
            roleType={roleType}
            setProjectList={setProjectList}
            setProjectId={setProjectId}
            projectId={projectId}
            workflowList={workflowList}
            setWorkflowList={setWorkflowList}
            qutationData={qutationData}
            setOpenlearning={setOpenlearning}
            openlearning={openlearning}
            setOpenAnalyticsClient={setOpenAnalyticsClient}
            openAnalyticsClient={openAnalyticsClient}
            setWorkFlowId={setWorkFlowId}
            workFlowId={workFlowId}
          />
        </div>
        <div
          style={{ width: isCollapsed ? "97%" : "85%" }}
          className={isCollapsed ? "smoothCollapse" : "smoothCollapse"}
        >
          {currentPath === "workflow" && roleType === "client" ? (
            <WorkflowSidebar
              isWorkflowCollapsed={isWorkflowCollapsed}
              roleType={roleType}
              workflowList={workflowList}
              getWorkflows={getWorkflows}
              setWorkflowList={setWorkflowList}
              setWorkFlowId={setWorkFlowId}
              workFlowId={workFlowId}
              loginType={loginType}
              currentUserId={currentUserId}
            />
          ) : currentPath === "team" && roleType === "client" ? (
            <Team
              tableRef={tableRef}
              onMouseDown={onMouseDown}
              teamsData={teamsData}
              loading={loading}
              getUsers={getUsers}
            />
          ) : currentPath === "organizationChart" ? (
            <OrgChart />
          ) : currentPath === "template" && roleType === "client" ? (
            <FormBuilder isCollapseTempelates={isCollapseTempelates} />
          ) : currentPath === "Sponsor" && roleType === "client" ? (
            <Sponsor
              tableRef={tableRef}
              tableRef1={tableRef1}
              tableRef2={tableRef2}
              onMouseDown={onMouseDown}
              onMouseDown1={onMouseDown1}
              onMouseDown2={onMouseDown2}
            />
          ) : currentPath === "profile" ? (
            <Profile
              userCurrrentData={userCurrrentData}
              currentUserData={currentUserData}
              roleType={roleType}
            />
          ) : currentPath === "apperance" ? (
            <Apperance />
          ) : currentPath === "failddata" ? (
            <FaildData onMouseDown={onMouseDown} tableRef={tableRef} />
          ) : currentPath === "logdata" ? (
            <LogData onMouseDown={onMouseDown} tableRef={tableRef} />
          ) : (currentPath === "analytics" && (roleType === "user" || roleType === "client")) ? (
            <Analytics />
          ) : currentPath === `studylist` ? (
            <Study projectId={projectId} />
          ) : ((currentPath === "study" && loginType === "study" && !userSideTab && !openlearning) ||
            (currentPath === "clientstudy" && !openlearning)) ? (
            <WorkflowSidebar
              setWorkFlowId={setWorkFlowId}
              workFlowId={workFlowId}
              tableRef={tableRef}
              clientStudyworkFlowId={clientStudyworkFlowId}
              roleType={roleType}
              teamsData={teamsData}
              userworkflowid={userworkflowid}
              currentPath={currentPath}
              getWorkflows={getWorkflows}
              studyId={studyId}
              currentUserId={currentUserId}
              userCurrrentData={userCurrrentData}

              setClientStudyworkFlowId={setClientStudyworkFlowId}
            />
          ) : currentPath === "phasemetadata" ? (
            <PhaseEstimate phaseData={phaseData} getAllPhase={getAllPhase} loading={loading} />
          ) : currentPath === "phase" ? (
            <PhaseSidebar isCollapseQuotations={isCollapseQuotations} />
          ) : ((userSideTab === "elearning" || currentPath === "e-learning") && roleType === "user") ? (
            <ElearningData userTrainingQues={userTrainingQues} setTrainingIds={setTrainingIds} trainingIds={trainingIds} loading={loading} />
          ) : currentPath === "e-learning-management" ? (
            <ELearningManagement questions={questions} setQuestions={setQuestions} multiselectAnswer={multiselectAnswer} setMultiselectAnswer={setMultiselectAnswer} />
          ) : currentPath === "e-learning-module" ? (
            <ELearningViewModule trainingIds={trainingIds} />
          ) : currentPath === "training-data" ? (
            <TrainingDataManagement />
          ) : currentPath === "recent-activity" ? (
            <RecentActivityTable />
          ) : currentPath === "designation" ? (
            <DeginationTable />
          ) : currentPath === "invoices" ? (
            <Invoice />
          ) : currentPath === "invoiceData" ? (
            <TaxInvoiceData />
          ) : currentPath === "userSpounser" ? (
            <UserSpouncer tableRef={tableRef} onMouseDown={onMouseDown} />
          ) : openlearning === "eLearningMgmt" ? (
            <ELearningManagement questions={questions} setQuestions={setQuestions} multiselectAnswer={multiselectAnswer} setMultiselectAnswer={setMultiselectAnswer} />
          ) : openlearning === "openanalyticsClient" ? (
            <Analytics />
          ) : currentPath === "chatbot" ? (
            <Chat teamsData={teamsData} />
          ) : (
            <PageRectriction403 />
          )}
        </div>
      </div>
    </>
  );
};

export default Panel;