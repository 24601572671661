import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import { Login } from "./Pages";
import Panel from "./Component/Panel";
import Footer from "./Component/Footer";
import SignUp from "./Pages/Auth/SignUp";
import { Suspense, useEffect, useRef, useState } from "react";
import ForgetPassword from "./Pages/Auth/ResetPassword";
import ChangePassword from "./Pages/Auth/ChangePassword";
import { authServices } from "./APIServices/authServices";
import { Permission } from "./ProtectRoute/PermissionRoutes";
import PageRectriction404 from "./Pages/PageRestricton/PageRectriction404";
import Loading from "./Pages/PageRestricton/Loading";
import Invoice from "./Pages/Tax Invoice/Invoice";
import RegularUserForm from "./Pages/UserRegularForm/RegularUserForm";

function App() {
  const currentPath = window.location.pathname.split("/")[1];
  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);
  const {
    LoginPermission,
    DashboardPermission,
    // UserPermission,
    // ClientPermission,
  } = Permission;
  const [twoFA, setTwoFA] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [email, setEmail] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [authType, setAuthType] = useState("");
  const [roleType, setRoleType] = useState("");
  const [userCurrrentData, setUserCurrrentData] = useState("");
  let currentUserData = async () => {
    let result = await authServices.currentUserData();
    let data = result?.data;
    setEmail(data?.email);
    setTwoFA(data?.twoFA);
    setPasswordUpdate(data?.isUpdatePassword);
    setAuthType(data?.authType);
    setUserCurrrentData(data);
    setRoleType(data?.role);
    setCurrentUserId(data?._id?.$oid);
  };

  useEffect(() => {
    currentUserData();
  }, []);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        if (tableRef.current) {
          const th = tableRef.current.querySelector(
            `th:nth-child(${resizingColumn + 1})`
          );
          if (th) {
            th.style.width = `${width}px`;
          }
          tableRef.current.classList.add("resizing");
        }
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex, ref) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    if (ref.current) {
      ref.current.querySelectorAll("th").forEach((column, index) => {
        if (index !== columnIndex) {
          column.style.width = `${column.offsetWidth}px`;
        }
      });
    }
  };
  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes>
            {[
              "home",
              "analytics",
              "team",
              "workflow",
              "profile",
              "apperance",
              "failddata",
              "logdata",
              "recent-activity",
              "organizationChart",
              "template",
              "Sponsor",
              "phasemetadata",
              "phase",
              "designation",
              "e-learning",
              "elearning",
              "invoices",
              "userformRegular",
              "e-learning-module/:trainingIds",
              "training-data/:trainingID",
              "e-learning-management",
              "studylist/:projectId",
              "study/:studyId",
              `clientstudy/:studyId`,
              `invoice/:sponcerId`,
              `form/:tempId`,
              "userWorkflow",
              "chatbot",
              "userSpounser",
              "invoiceData",
            ].map((path) => (
              <Route
                // path=""
                element={
                  <DashboardPermission
                    twoFA={twoFA}
                    authType={authType}
                    email={email}
                    passwordUpdate={passwordUpdate}
                    currentUserData={currentUserData}
                  />
                }
              >
                <Route
                  key={path}
                  exact
                  path={`/${path}`}
                  element={
                    <Panel
                      tableRef={
                        path === "team" ||
                          path === "client" ||
                          path === "failddata" ||
                          path === "logdata"
                          ? tableRef
                          : null
                      }
                      onMouseDown={
                        path === "team" ||
                          path === "client" ||
                          path === "failddata" ||
                          path === "logdata"
                          ? (e, columnIndex) =>
                            onMouseDown(e, columnIndex, tableRef)
                          : null
                      }
                      userCurrrentData={userCurrrentData}
                      currentUserData={currentUserData}
                      roleType={roleType}
                      currentUserId={currentUserId}
                    />
                  }
                />
              </Route>
            ))}
            {/* <Route path="/pagedenied" element={<PageRectriction404 />} /> */}
            <Route path="/" element={<LoginPermission />}>
              <Route exact path="/" element={<Login />} />
            </Route>
            <Route path="/login" element={<LoginPermission />}>
              <Route path="/login" element={<Login />} />
            </Route>

            <Route path="/signup" element={<LoginPermission />}>
              <Route path="/signup" element={<SignUp />} />
            </Route>


            <Route path="/invoice/:_id" element={<Invoice />}>
              <Route path="/invoice/:_id" element={<Invoice />} />
            </Route>

            <Route path="/form/:_id" element={<RegularUserForm />}>
              <Route path="/form/:_id" element={<RegularUserForm />} />
            </Route>

            <Route path="/forgetpassword" element={<LoginPermission />}>
              <Route path="/forgetpassword" element={<ForgetPassword />} />
            </Route>

            <Route path="/changepassword" element={<LoginPermission />}>
              <Route path="/changepassword" element={<ChangePassword />} />
            </Route>
            {/* <Route path="/" element={<UserPermission />}>
            <Route exact path="/" element={<Login />} />
          </Route> */}
            {/* <Route path="/" element={<ClientPermission />}>
            <Route exact path="/" element={<Login />} />
          </Route> */}

            <Route path="*" element={<PageRectriction404 />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
      {["", "signup", "forgetpassword", "changepassword"].includes(
        currentPath
      ) ? (
        ""
      ) : (
        <Footer />
      )}
    </>
  );
}

export default App;
