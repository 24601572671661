import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const tempelatesServices = {
  addtemplate,
  updateTemplate,
  getAllTemplates,
  deleteTemplate,
  searchTemplate,
  getActiveTemplate,
  addUpdateFormById,
  getFormByTemplateId,
  getDataEntryByTemplate,
  addDataEntry // userSide 
};

//add tempelates.
async function addtemplate(userData) {
  const response = await fetch(
    `${config.API_URL}addtemplate`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// addDataEntry
async function addDataEntry(userData) {
  const response = await fetch(
    `${config.API_URL}addDataEntry`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//update Tempelates.
async function updateTemplate(id, userData) {
  const response = await fetch(
    `${config.API_URL}updateTemplate/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getActiveTemplate
async function getActiveTemplate() {
  const response = await fetch(
    `${config.API_URL}getActiveTemplate`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getAllTemplates
async function getAllTemplates() {
  const response = await fetch(
    `${config.API_URL}getAllTemplates`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//delete Template
async function deleteTemplate(Id) {
  const response = await fetch(
    `${config.API_URL}deleteTemplate/${Id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//searchTemplate
async function searchTemplate(name) {
  const response = await fetch(
    `${config.API_URL}searchTemplate?name=${name}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//addUpdateFormById
async function addUpdateFormById(userData) {
  const response = await fetch(
    `${config.API_URL}addUpdateFormById`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getFormByTemplateId
async function getFormByTemplateId(Id) {
  const response = await fetch(
    `${config.API_URL}getFormByTemplateId/${Id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// usersideDataTempleate
async function getDataEntryByTemplate(Id) {
  const response = await fetch(
    `${config.API_URL}getDataEntryByTemplate/${Id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
