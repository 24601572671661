import React, { useEffect, useState } from "react";
import { Form, Offcanvas, FormGroup, Col, Spinner } from "react-bootstrap";
import { orgServices } from "../../../APIServices/orgServices";

const AddDesignationOffcanvas = ({ show, onHide, size, getDesignation, designationId, desinationName, setDesinationName, setDesignationId }) => {
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.designationName = desinationName;
    let data = await orgServices.addDesignation(userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      clearFormData();
      getDesignation();
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  console.log("desinationName", desinationName);


  const handleUpdate = async (e) => {
    setLoader(true);
    e.preventDefault();
    let _id = designationId;
    let userData = {};
    userData.designationName = desinationName;
    let data = await orgServices.updateDesignation(_id, userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      clearFormData();
      getDesignation();
      setDesignationId("")
      setLoader(false);
    }
  }

  const clearFormData = () => {
    onHide();
    setDesinationName("");
    setDesignationId("")
  };

  return (
    <Offcanvas
      show={show}
      size={size}
      onHide={onHide}
      placement="end"
      style={{ width: "400px" }}
    >
      <Offcanvas.Header className="py-2">
        <div className="hstack justify-content-between w-100 gap-3">
          <Offcanvas.Title>
            <b>{!designationId ? "Add" : "Update"} Degination</b>
          </Offcanvas.Title>
          <div
            onClick={clearFormData}
            className="offcanvas_Close"
            title="Close"
            style={{ cursor: "pointer" }}
          >
            X
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column p-2">
        <div className="flex-grow-1 overflow-auto">
          <FormGroup as={Col} md={12} className="mb-2">
            <Form.Label>
              <b>Designation Name :</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter your Designation name"
              onChange={(e) => setDesinationName(e.target.value)}
              value={desinationName}
            />
          </FormGroup>
          <div className="text-end py-3">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <button
                  type="submit"
                  onClick={!designationId ? handleSubmit : handleUpdate}
                  disabled={!desinationName}
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}
                  {!designationId ? "Add" : "Update"} Designation
                </button>
              </div>
            </Form.Group>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddDesignationOffcanvas;
