import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { clientServices } from "../../APIServices/clientServices";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Paginations from "../../Hooks/Paginations";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../Hooks/NoDataFoundTable";
import SplitPane from "react-split-pane";

const ProjectList = ({ selectedRow, projectId, setProjectId }) => {
  const [projectData, setProjectData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(false);
  const [updateProjectId, setUpdateProjectId] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const isDisabled = !projectName;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill all mandatory fields
    </Tooltip>
  );

  console.log("projectData", projectData);


  const addProject = async () => {
    let userData = {};
    setLoader(true);
    userData.projectName = projectName;
    let data = await clientServices.addProject(userData, selectedRow);
    if (data?.status_code === 200) {
      clearFormData();
      getAllProject();
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const clearFormData = () => {
    setProjectName("");
    setUpdateProjectId(""); // Clear update project ID
  };

  const getAllProject = async () => {
    setLoading(true);
    let data = await clientServices.getAllProject(selectedRow);
    setProjectData(data?.data);
    setLoading(false);
  };

  const updateProjectstatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Project Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await clientServices.updateProject(id, data);
        if (results?.status_code === 200) {
          getAllProject();
        }
      }
    });
  };

  const updateProject = async () => {
    let _id = updateProjectId;
    let userData = {};
    userData.projectName = projectName;
    setLoader(true);
    let data = await clientServices.updateProject(_id, userData); // Pass userData for update
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      getAllProject();
      setStatusCode(true);
      clearFormData(); // Clear form data after successful update
    }
  };

  const deleteProject = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Project!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await clientServices.deleteProject(_id);
        if (data?.status_code === 200) {
          getAllProject();
        }
      }
    });
  };

  const handleRowClick = (id) => {
    setProjectId(id);
  };

  const handleUpdateClick = (project) => {
    setUpdateProjectId(project?._id?.$oid);
    setProjectName(project.projectName); // Set project name in the form control
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = projectData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(projectData?.length / itemsPerPage);

  useEffect(() => {
    getAllProject();
  }, [selectedRow]);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={"25%"}
        style={{ height: "calc(100vh - 320px)" }}
      >
        <div className="p-2">
          <FormGroup className="mb-2">
            <Form.Label>
              <b>Project Name</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="rounded-1"
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
              value={projectName}
              placeholder="Enter Your Project Name"
            />
          </FormGroup>
          <div className="text-end">
            <Form.Group as={Col} md="12">
              <div className=" button_type">
                <OverlayTrigger
                  placement="top"
                  overlay={isDisabled ? renderTooltip : <span></span>}
                >
                  <button
                    onClick={!updateProjectId ? addProject : updateProject}
                    disabled={!projectName || loader}
                  >
                    {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 15, height: 15 }}
                        className="me-2"
                      />
                    ) : null}
                    {!updateProjectId ? "Add" : "Update"} Project
                  </button>
                </OverlayTrigger>
              </div>
            </Form.Group>
          </div>
        </div>
        {loading ? (
          <SpinerDataLoader />
        ) : result?.length > 0 ? (
          <div className="table-container overflow-auto m-2">
            <Table
              id="resizable-table"
              className="custom-table"
              striped
              border
              hover
            >
              <thead>
                <tr style={{ cursor: "pointer" }}>
                  <th style={{ width: 68 }} className="text-center">
                    Sr.
                  </th>
                  <th style={{ width: 173 }}>Project Name</th>
                  <th style={{ width: 150 }}>Status</th>
                  <th style={{ width: 173 }}>Create Date</th>
                  <th style={{ width: 173 }}>Modification Date</th>
                  <th style={{ width: 106, textAlign: "center" }}>View Study</th>
                  <th style={{ width: 106, textAlign: "center" }}>Update</th>
                  <th style={{ width: 55, textAlign: "center" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={item.id}

                    className={projectId === item.projectID ? "selected" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="text-center">{startIndex + index + 1}</td>
                    <td>{item.projectName}</td>
                    <td style={{ padding: 0 }}>
                      <select
                        className={`status-select ${item.status.toLowerCase()}`}
                        value={item.status}
                        onChange={(e) =>
                          updateProjectstatus(item?._id?.$oid, e.target.value)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "0",
                          margin: "0",
                          border: "none",
                          boxSizing: "border-box",
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td>
                      <Moment format=" DD MMM YYYY | HH:MM">
                        {item.created_at}
                      </Moment>
                    </td>

                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.updated_at}
                      </Moment>
                    </td>
                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => handleRowClick(item.projectID)}
                      >
                        View Study
                      </button>
                    </td>
                    <td className="button_type text-center">
                      <button
                        style={{
                          fontSize: 11,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                        onClick={() => handleUpdateClick(item)}
                      >
                        Update
                      </button>
                    </td>

                    <td className="text-center">
                      <span className="text-danger">
                        <i
                          className="fa-solid fa-trash"
                          onClick={() => deleteProject(item?._id?.$oid)}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 mx-1">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={projectData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        ) : (
          <NoDataFoundTable MSG={"Project Not Found!"} />
        )}

      </SplitPane>
    </>
  );
};

export default ProjectList;
