import React from "react";
import { Form, Modal, Table } from "react-bootstrap";

function TeamListModal({ Show, Hide, Size, Title }) {
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>{Title}</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover variant="light" className="m-0">
              <thead>
                <tr>
                  <th style={{ width: 40 }}>
                    <Form.Check aria-label="option 1" />
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Gender</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>Aakash222</td>
                  <td>aakash@gmail.com</td>
                  <td>1234567890</td>
                  <td>Male</td>
                </tr>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>Umesh</td>
                  <td>umesh@gmail.com</td>
                  <td>1234567890</td>
                  <td>Male</td>
                </tr>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>Sandeep</td>
                  <td>sandeep@gmail.com</td>
                  <td>1234567890</td>
                  <td>Male</td>
                </tr>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>harshit</td>
                  <td>harshit@gmail.com</td>
                  <td>1234567890</td>
                  <td>Male</td>
                </tr>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>Sahil</td>
                  <td>sahil@gmail.com</td>
                  <td>9784563210</td>
                  <td>Male</td>
                </tr>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>Rakesh</td>
                  <td>rakesh@gmail.com</td>
                  <td>9874563210</td>
                  <td>Male</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end"></div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default TeamListModal;
